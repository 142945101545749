import Aos from "aos";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../Header/Navbar.css";

const Navbarr = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 150) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div style={{ position: "sticky", top: "0px", zIndex: "99999" }}>
      <Navbar expand="lg" className={color ? "navvv" : "header-bg"}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src="../Img/horizontal-logo.png"
              alt=""
              style={{ height: "auto", width: "200px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto jhjh">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/aboutus">About us</Nav.Link>
              <NavDropdown title="Meditation" id="basic-nav-dropdown">
                <NavDropdown.Item href="/maditation1">
                  Mindfullness Meditation for Children
                </NavDropdown.Item>
                <NavDropdown.Item href="/maditationsoul">
                  Meditation for the Soul
                </NavDropdown.Item>
                <NavDropdown.Item href="/chakras">
                  Balance Your Chakras
                </NavDropdown.Item>
                <NavDropdown.Item href="/helloholistic">
                  Hello Holistic Happiness
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/musicaltherapy"
                  style={{ borderBottom: "none" }}
                >
                  Musical Therapy
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/blog">Blog / Podcast</Nav.Link>
              <Nav.Link href="/Booksnew">Books</Nav.Link>
              <Nav.Link href="/contactus">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#F58243" }}>
              Download the app Now!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div
                className="row"
                style={{ padding: "3rem", backgroundColor: "#F58243" }}
              >
                <div
                  className="col-md-6"
                  style={{ backgroundColor: "#F58243" }}
                >
                  <img
                    src="/Img/Download.png"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ backgroundColor: "#F58243" }}
                >
                  <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                  <br />
                  <h6 style={{ color: "white" }}>
                    Your FITNESS is Our Concern.
                  </h6>
                  <br />
                  <p style={{ color: "white" }}>
                    Download the Yoga App Today and Embrace a Healthier You!"
                  </p>
                </div>
              </div>
              <div className="row " style={{ backgroundColor: "#F58243" }}>
                <div className="col-md-12 mb-3 text-center">
                  <div className="hhhhk_000">
                    <button>
                      <a
                        href="https://play.google.com/store/games?device=windows&pli=1"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {" "}
                        <span>
                          <FaDownload style={{ fontSize: "30px" }} />
                        </span>
                        Download Android | ios
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
};

export default Navbarr;
