import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";

function Userlist() {
  const [data, setdata] = useState([]);

  const [show, setShow] = useState(false);
  const [userdata, setuserdata] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setuserdata(data);
  };

  const [show1, setShow1] = useState(false);
  const [userdata1, setuserdata1] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setuserdata1(data);
  };

  useEffect(() => {
    getalluser();
  }, []);

  const blockuser = () => {
    axios
      .get("https://relentlessyou.live/api/user/blockuser/" + userdata?._id)
      .then(function (response) {
        console.log(response.data.success);
        if (response.status === 200) {
          alert("You have blocked user successfully");
          window.location.reload();
        } else {
          alert("Some internal error please try after some time");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const unblockuser = () => {
    axios
      .get("https://relentlessyou.live/api/user/unblockuser/" + userdata1?._id)
      .then(function (response) {
        console.log(response.data.success);
        if (response.status === 200) {
          alert("You have Un-blocked user successfully");
          window.location.reload();
        } else {
          alert("Some internal error please try after some time");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getalluser = () => {
    axios
      .get("https://relentlessyou.live/api/user/getalluser")
      .then(function (response) {
        console.log(response.data.user);
        setdata(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD-MM-YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "profilepic",
      text: "Profile",
      formatter: (value, row) => (
        <img
          src={"https://relentlessyou.live/Customer/" + row.profilepic}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://relentlessyou.live/Customer/" + row.profilepic)
          }
        />
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      formatter: (value, row) => <p>{row?.mobile ? row?.mobile : "-"}</p>,
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div>
          {row?.blockstatus === false ? (
            <button
              style={{
                border: "none",
                backgroundColor: "#f37261",
                color: "white",
                borderRadius: "3px",
              }}
              onClick={() => handleShow(row)}
            >
              Block
            </button>
          ) : (
            <button
              style={{
                border: "none",
                backgroundColor: "black",
                color: "white",
                borderRadius: "3px",
              }}
              onClick={() => handleShow1(row)}
            >
              Un-Block
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        User-List
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>

              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to block : <b>{userdata?.name}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={blockuser}>
            Block
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <p style={{ textAlign: "center" }}>
            Are you shure you want to un-block : <b>{userdata1?.name}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={unblockuser}>
            Un-Block
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Userlist;
