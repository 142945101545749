import React, { useEffect, useRef, useState } from "react";
import "../Maditation/styles/Happy.css";
import Mobileapp from "../Home/mobileapp";
import Slider from "react-slick";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";

const Heloholistic = () => {
  const [Audio, setAudio] = useState([]);
  const [show, setShow] = useState(false);
  const currentAudioRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getallAudio();
  }, []);

  const getallAudio = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getAudio")
      .then(function (response) {
        console.log(response.data);
        setAudio(
          response.data.AudioList?.filter((item) => item.Type === "Free")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePlay = (audioElement) => {
    // Pause the currently playing audio if there is one
    if (currentAudioRef.current && currentAudioRef.current !== audioElement) {
      currentAudioRef.current.pause();
    }

    // Set the new audio element as the current one
    currentAudioRef.current = audioElement;
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: "url('../Img/65.jpg')",
            // backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "350px",
            position: "relative",
            // backgroundAttachment: "fixed",
            // marginTop: "-86px",
          }}
          className="wwhhh"
        >
          <div className="container kjhkhhh pt-5">
            <div className="row">
              <div className="col-md-12  ojjjpoiiii text-center gygygy">
                <p>Hello Holistic Happiness</p>
                <span>
                  <a href="/">Home/</a>
                  <a href="">Meditation/</a>
                  <a href="">Hello Holistic Happiness</a>
                </span>
              </div>
            </div>
          </div>
          <div className="bs-slider"></div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-5">
              <img
                // src="/Img/60.png"
                src="/Image/img8.jpg"
                alt=""
                style={{ width: "100%", height: "550px" }}
              />
            </div>
            <div className="col-md-7">
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                It has been said that, “Everything in your life is a reflection
                of a choice you once made. If you want different results, make
                different choices” Relentless-you.com is delighted to present
                the ‘Hello’ Series of Meditations especially created to invite
                specific positive emotions into your life. The , objective here
                is simple, really. If you really want something, you need to
                give it permission to enter your life. You need to open your
                arms to accept this new emotion. The ‘Hello’ Series does just
                that. We have lovingly created these meditations, a combination
                of gentle hypnotic commands, meaningful trance inducing
                deepeners, and especially curated music therapy to awaken your
                senses, to reprogram the inner editor of your subconscious mind,
                to invite Good Luck, and positive vibrations into your life. We
                also deemed it relevant to include a pure affirmations pack,
                both spoken and silent, along with this series for when you are
                on the go and don’t have the luxury of time to complete a guided
                meditation. Additionally, we have given you affirmations
                recorded at the subliminal level to add that extra punch as
                well. As always, we strongly urge you to make meditation a
                routine, a sacred process, that you begin to strongly identify
                with. Do listen to the meditations and affirmations though
                headphones to fully appreciate its life altering effects and
                welcome Happiness into your life!
              </p>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <div>
            <p className="biuggbui">Audios</p>
            <div className="iuyiuyf"></div>
          </div>
          {/* <div className="row mt-4">
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/84.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/85.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/86.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/87.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
        </div> */}

          <Slider {...settings}>
            {Audio?.map((aud) => (
              <div
                className="col-md-3"
                style={{ margin: "10px", marginTop: "0px" }}
              >
                <div style={{ position: "relative" }} className="iuwgywg">
                  <h5>{aud?.Audioname}</h5>
                  <img
                    src={"https://relentlessyou.live/Audio/" + aud.AudioImage}
                    alt=""
                    style={{ width: "95%", height: "300px" }}
                  />
                  <div className="jhhvytcfy">
                    <audio
                      src={"https://relentlessyou.live/Audio/" + aud.Audiofile}
                      controls
                      type="audio/mpeg"
                      style={{ width: "265px" }}
                      onPlay={(e) => handlePlay(e.target)}
                    ></audio>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <button
            variant="primary"
            onClick={handleShow}
            style={{
              textDecoration: "none",
              background: "none",
              border: "none",
              color: "#F57E41",
              fontSize: "20px",
              fontStyle: "bold",
              cursor: "pointer",
              right: "10px",
              position: "absolute",
            }}
          >
            View All <IoMdArrowForward />
          </button>
        </div>
        <div className="mb-3">
          <Mobileapp />
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#F58243" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#F58243" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Close
           </Button>
           <Button variant="primary" onClick={handleClose}>
             Save Changes
           </Button>
         </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Heloholistic;
