import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";

function Videocategory() {
  const formdata = new FormData();
  const Input = styled("input")({
    display: "none",
  });

  const [data, setdata] = useState([]);

  const [Videocategoryimg, setVideocategoryimg] = useState("");
  const [name, setname] = useState("");
  const [bgcolor, setbgcolor] = useState("");

  console.log("Videocategoryimg", Videocategoryimg);
  const [Videocategoryimg1, setVideocategoryimg1] = useState("");
  const [name1, setname1] = useState("");
  const [bgcolor1, setbgcolor1] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    seteditdata(data);
  };

  const [show3, setShow3] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setdeletedata(data);
  };

  useEffect(() => {
    getallvideocategory();
  }, []);

  const addvideocategory = async () => {
    if (!name || !Videocategoryimg || !bgcolor) {
      alert("Please fill all the fields");
    } else {
      formdata.append("name", name);
      formdata.append("bgcolor", bgcolor);
      formdata.append("Videocategoryimg", Videocategoryimg);
      try {
        const config = {
          url: "/addVideocategory",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
            // getallBanners();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log("error", error);
        alert(error.response.data.error);
      }
    }
  };

  const getallvideocategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getVideocategory")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.VideocategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editVideocategory = async () => {
    formdata.append("name", name1 ? name1 : editdata?.name);
    formdata.append("bgcolor", bgcolor1 ? bgcolor1 : editdata?.bgcolor);
    formdata.append(
      "Videocategoryimg",
      Videocategoryimg1 ? Videocategoryimg1 : editdata?.Videocategoryimg
    );
    try {
      const config = {
        url: "/editVideocategory/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getallvideocategory();
          handleClose1();
          alert("Data updated successfully");
          // window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getallvideocategory();
      alert(error.response.data.error);
    }
  };

  const deletevideocategory = async () => {
    try {
      const config = {
        url: "/deleteVideocategory/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose3();
          getallvideocategory();
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Videocategoryimg",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={
            "https://relentlessyou.live/Videocategory/" + row.Videocategoryimg
          }
          width={100}
          height={80}
          onClick={() =>
            window.open(
              "https://relentlessyou.live/Videocategory/" + row.Videocategoryimg
            )
          }
        />
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "bgcolor",
      text: "Background-Color",
      formatter: (value, row) => <p>{row?.bgcolor ? row?.bgcolor : "-"}</p>,
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Video-Category
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button>
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Video-Category</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Background-Color <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                {/* <input
                  placeholder="Background-Color"
                  onChange={(e) => setbgcolor(e.target.value)}
                /> */}
                <select
                  onChange={(e) => setbgcolor(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>Select</option>
                  <option value="#8ACDD7">#8ACDD7</option>
                  <option value="#F4DFB6">#F4DFB6</option>
                  <option value="#FFF5C2">#FFF5C2</option>
                  <option value="#FFC0D9">#FFC0D9</option>
                  <option value="#FFECD6">#FFECD6</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Image <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setVideocategoryimg(e.target.files[0])}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addvideocategory}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Video-Category</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.name}
                  onChange={(e) => setname1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Background-Color</label>
              </Col>
              <Col md={6}>
                {/* <input
                  placeholder={editdata?.bgcolor}
                  onChange={(e) => setbgcolor1(e.target.value)}
                /> */}
                <select
                  onChange={(e) => setbgcolor1(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>{editdata?.bgcolor}</option>
                  <option value="#8ACDD7">#8ACDD7</option>
                  <option value="#F4DFB6">#F4DFB6</option>
                  <option value="#FFF5C2">#FFF5C2</option>
                  <option value="#FFC0D9">#FFC0D9</option>
                  <option value="#FFECD6">#FFECD6</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Image</label>
              </Col>
              <Col md={6}>
                <label htmlFor="image5">
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setVideocategoryimg1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        Videocategoryimg1
                          ? URL.createObjectURL(Videocategoryimg1)
                          : "https://relentlessyou.live/Videocategory/" +
                            editdata?.Videocategoryimg
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editVideocategory}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={deletevideocategory}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Videocategory;
