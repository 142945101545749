import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";

function Addvideo() {
  const formdata = new FormData();
  const Input = styled("input")({
    display: "none",
  });

  const [data, setdata] = useState([]);
  const [Videocategory, setVideocategory] = useState([]);

  const [catname, setcatname] = useState("");
  const [videoname, setvideoname] = useState("");
  const [VideoImage, setVideoImage] = useState("");
  const [Videofile, setVideofile] = useState("");
  const [Speakername, setSpeakername] = useState("");
  const [Type, setType] = useState("");

  const [catname1, setcatname1] = useState("");
  const [videoname1, setvideoname1] = useState("");
  const [VideoImage1, setVideoImage1] = useState("");
  const [Videofile1, setVideofile1] = useState("");
  const [Speakername1, setSpeakername1] = useState("");
  const [Type1, setType1] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    seteditdata(data);
  };

  const [show2, setShow2] = useState(false);
  const [likes, setlikes] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setlikes(data);
  };

  const [show3, setShow3] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setdeletedata(data);
  };

  useEffect(() => {
    getallvideo();
    getallvideocategory();
  }, []);

  const getallvideocategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getVideocategory")
      .then(function (response) {
        console.log(response.data);
        setVideocategory(response.data.VideocategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addVideo = async () => {
    if (
      !catname ||
      !videoname ||
      !Videofile ||
      !VideoImage ||
      !Speakername ||
      !Type
    ) {
      alert("Please fill all the fields");
    } else {
      formdata.append("catname", catname);
      formdata.append("videoname", videoname);
      formdata.append("Speakername", Speakername);
      formdata.append("Type", Type);
      formdata.append("Videofile", Videofile);
      formdata.append("VideoImage", VideoImage);
      try {
        const config = {
          url: "/addVideo",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
            // getallBanners();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert(error.response.data.error);
        alert(error.response.data.error);
      }
    }
  };

  const getallvideo = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getVideo")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.VideoList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editVideo = async () => {
    formdata.append("catname", catname1 ? catname1 : editdata?.catname);
    formdata.append("videoname", videoname1 ? videoname1 : editdata?.videoname);
    formdata.append(
      "Speakername",
      Speakername1 ? Speakername1 : editdata?.Speakername
    );
    formdata.append("Type", Type1 ? Type1 : editdata?.Type);
    formdata.append("Videofile", Videofile1 ? Videofile1 : editdata?.Videofile);
    formdata.append(
      "VideoImage",
      VideoImage1 ? VideoImage1 : editdata?.VideoImage
    );
    try {
      const config = {
        url: "/editVideo/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getallvideo();
          handleClose1();
          alert("Data updated successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getallvideo();
      alert(error.response.data.error);
    }
  };

  const deleteVideo = async () => {
    try {
      const config = {
        url: "/deleteVideo/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose3();
          getallvideo();
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "VideoImage",
      text: "Video Image",
      formatter: (value, row) => (
        <img
          src={"https://relentlessyou.live/Video/" + row.VideoImage}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://relentlessyou.live/Video/" + row.VideoImage)
          }
        />
      ),
    },
    {
      dataField: "Videofile",
      text: "Video",
      formatter: (value, row) => (
        <video
          width="100"
          height="100"
          controls
          autoPlay={false}
          onClick={() =>
            window.open("https://relentlessyou.live/Video/" + row.Videofile)
          }
        >
          <source
            src={"https://relentlessyou.live/Video/" + row.Videofile}
            type="video/mp4,video/webm,"
            onClick={() =>
              window.open("https://relentlessyou.live/Video/" + row.Videofile)
            }
          />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      dataField: "catname",
      text: "Category Name",
      formatter: (value, row) => <p>{row?.catname ? row?.catname : "-"}</p>,
    },
    {
      dataField: "videoname",
      text: "Video Name",
      formatter: (value, row) => <p>{row?.videoname ? row?.videoname : "-"}</p>,
    },
    {
      dataField: "Speakername",
      text: "Speaker Name",
      formatter: (value, row) => (
        <p>{row?.Speakername ? row?.Speakername : "-"}</p>
      ),
    },
    {
      dataField: "Type",
      text: "Type",
      formatter: (value, row) => <p>{row?.Type ? row?.Type : "-"}</p>,
    },
    {
      dataField: "",
      text: "Likes",
      formatter: (value, row) => (
        <>
          <p>{row?.likes?.length}</p>
          <button
            onClick={() => handleShow2(row)}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "#f37261" }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Video
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button>
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Video</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>
                  Category Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setcatname(e.target.value)}
                >
                  <option>Select</option>
                  {Videocategory?.map((item) => (
                    <option value={item?.name}>{item?.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Video Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Video Name"
                  onChange={(e) => setvideoname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Speaker Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder=" Speaker Name"
                  onChange={(e) => setSpeakername(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Type <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setType(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>Select</option>
                  <option value="Free">Free</option>
                  <option value="Charges">Charges</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Video Image <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setVideoImage(e.target.files[0])}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Video <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setVideofile(e.target.files[0])}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addVideo}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Video</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>Category Name</label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setcatname1(e.target.value)}
                >
                  <option>{editdata.catname}</option>
                  {Videocategory?.map((item) => (
                    <option value={item?.name}>{item?.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Video Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.videoname}
                  onChange={(e) => setvideoname1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Speaker Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.Speakername}
                  onChange={(e) => setSpeakername1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Type</label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setType1(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>{editdata?.Type}</option>
                  <option value="Free">Free</option>
                  <option value="Charges">Charges</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Video Image</label>
              </Col>
              <Col md={6}>
                <label htmlFor="image5">
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setVideoImage1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        VideoImage1
                          ? URL.createObjectURL(VideoImage1)
                          : "https://relentlessyou.live/Video/" +
                            editdata?.VideoImage
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Video</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setVideofile1(e.target.files[0])}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editVideo}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Likes - {likes?.videoname}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            {likes?.userDetails?.map((lik) => (
              <>
                <div
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    display: "flex",
                    gap: "10px",
                    margin: "10px",
                  }}
                >
                  <img
                    src={
                      "https://relentlessyou.live/Customer/" + lik.profilepic
                    }
                    width={50}
                    height={50}
                    style={{ borderRadius: "50%" }}
                    onClick={() =>
                      window.open(
                        "https://relentlessyou.live/Customer/" + lik.profilepic
                      )
                    }
                  />
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {lik?.name}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {lik?.email}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteVideo}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Addvideo;
