import React, { useState } from "react";
import Maditationfaqq from "./Maditationfaqq";
import { qa } from "./Maditationfaqdata";

const Maditationfaq = () => {
  return (
    <div>
      {qa.map((obj) => {
        let { id, question, ans } = obj;
        return (
          <>
            <Maditationfaqq id={id} question={question} ans={ans} />
          </>
        );
      })}
    </div>
  );
};

export default Maditationfaq;
