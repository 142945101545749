import axios from "axios";
import React, { useEffect, useState } from "react";

function Dashboard() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const [user, setuser] = useState([]);
  const [Audio, setAudio] = useState([]);
  const [video, setvideo] = useState([]);
  const [Blogs, setBlogs] = useState([]);
  const [Snippet, setSnippet] = useState([]);
  const [subscribe, setsubscribe] = useState([]);
  const [Books, setBooks] = useState([]);
  const [Kidsaudio, setKidsaudio] = useState([]);
  const [Kidsvideo, setKidsvideo] = useState([]);
  const [Podcast, setPodcast] = useState([]);
  const [Musictherapy, setMusictherapy] = useState([]);

  useEffect(() => {
    getalluser();
    getallAudio();
    getallvideo();
    getBlogs();
    getSnippet();
    getUsersubscribe();
    getBooks();
    getKidsaudio();
    getKidsvideo();
    getPodcast();
    getMusictherapy();
  }, []);

  const getalluser = () => {
    axios
      .get("https://relentlessyou.live/api/user/getalluser")
      .then(function (response) {
        console.log(response.data.user);
        setuser(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallAudio = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getAudio")
      .then(function (response) {
        console.log(response.data);
        setAudio(response.data.AudioList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallvideo = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getVideo")
      .then(function (response) {
        console.log(response.data);
        setvideo(response.data.VideoList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBlogs = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBlogs")
      .then(function (response) {
        console.log(response.data);
        setBlogs(response.data.BlogsList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSnippet = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getSnippet")
      .then(function (response) {
        console.log(response.data);
        setSnippet(response.data.SnippetList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUsersubscribe = () => {
    axios
      .get("https://relentlessyou.live/api/user/getUsersubscribe")
      .then(function (response) {
        console.log(response.data.UsersubscribeList);
        setsubscribe(response.data.UsersubscribeList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBooks = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBooks")
      .then(function (response) {
        console.log(response.data);
        setBooks(response.data.BooksList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getKidsaudio = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getKidsaudio")
      .then(function (response) {
        console.log(response.data);
        setKidsaudio(response.data.KidsaudioList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getKidsvideo = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getKidsvideo")
      .then(function (response) {
        console.log(response.data);
        setKidsvideo(response.data.KidsvideoList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPodcast = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getPodcastcategory")
      .then(function (response) {
        console.log(response.data);
        setPodcast(response.data.PodcastcategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMusictherapy = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getMusictherapy")
      .then(function (response) {
        console.log(response.data);
        setMusictherapy(response.data.MusictherapyList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Dashboard
      </div>
      <div style={{ display: "flex" }}>
        {admin?.user ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Userlist")}
          >
            <img
              src="https://as2.ftcdn.net/v2/jpg/03/56/19/39/1000_F_356193934_mgDm1sAtDB6hIhlMbynOvCK5C3QIghtI.jpg"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Users
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {user?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.Book ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Books")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/10433/10433048.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Books
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Books?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.audio ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Addaudio")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/945/945174.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Audio
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Audio?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.video ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Addvideo")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/2504/2504965.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Video
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {video?.length}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div style={{ display: "flex" }}>
        {admin?.kids ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Kidsaudio")}
          >
            <img
              src="https://image.shutterstock.com/image-vector/cute-unicorn-singing-microphone-cartoon-260nw-2274159681.jpg"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Kids Audio
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Kidsaudio?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.kids ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Kidsvideo")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/9356/9356639.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Kids Video
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Kidsvideo?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.podcast ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Adpodcast")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/3771/3771390.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Podcast
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Podcast?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.musictherapy ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Musictherapistmusic")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/3293/3293810.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Music Therapy
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Musictherapy?.length}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div style={{ display: "flex" }}>
        {admin?.blog ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Blogpage")}
          >
            <img
              // src="https://cdn-icons-png.flaticon.com/128/5705/5705619.png"
              src="https://cdn-icons-png.flaticon.com/128/9623/9623631.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Blog
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Blogs?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.snippets ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Snippet")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/10708/10708749.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Snippets
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {Snippet?.length}
            </p>
          </div>
        ) : (
          ""
        )}
        {admin?.subscription ? (
          <div
            style={{
              backgroundColor: "#f37261",
              width: "200px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "8px",
              lineHeight: 0,
            }}
            onClick={() => window.location.assign("/admin/Subscriberlist")}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/5780/5780737.png"
              style={{
                width: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "50%",
              }}
            />
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Subscriber-List
            </p>
            <p style={{ color: "white", textAlign: "center" }}>
              {subscribe?.length}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Dashboard;
