import React, { useEffect } from "react";
import "../Chakras/ChakrasTimeline.css";
import Aos from "aos";

const ChakrasTimeline = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div class="timeline">
        <div class="containner left">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/69.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Root Chakra Muladhara</p>
            <p>
              We have 7 main chakras in our body; the root chakra is the first
              and the most important chakra, also the first we need to balance.
            </p>
          </div>
        </div>
        <div class="containner right">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/71.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Sacral Chakra Svadhisthana</p>
            <p>
              It is located just below the navel, right in the center of your
              lower body. It is connected to our feelings of self-worth and
              creativity.
            </p>
          </div>
        </div>
        <div class="containner left">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/72.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Solar Plexus Chakra Manipura</p>
            <p>
              The Solar Plexus Chakra is our third chakra, provides a source of
              personal power and relates to self-esteem, warrior energy.
            </p>
          </div>
        </div>
        <div class="containner right">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="2500"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/73.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Heart Chakra Anahata</p>
            <p>
              Our Fourth chakra is associated with love, compassion, and
              affection. The Color green is related to this energy center and
              it’s also call Anahata.
            </p>
          </div>
        </div>
        <div class="containner left">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/74.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Throat Chakra Vishuddha</p>
            <p>
              It is associated with the colour sky blue and it’s also called
              Vishuddha; The Name comes from the sanskrit, shuddhi, which means
              pure.
            </p>
          </div>
        </div>
        <div class="containner right">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="3500"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/75.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Third Eye Chakra Ajna</p>
            <p>
              This chakra is represented by a deep blur color and is also called
              Ajna, Which means “perceive,” “command” or ‘beyond wisdom” in
              Sanskrit.
            </p>
          </div>
        </div>
        <div class="containner left">
          <div
            class="content text-center"
            data-aos="fade-up"
            data-aos-duration="4000"
            data-aos-easing="ease-in-out"
          >
            <span className="">
              <img src="../Img/76.png" alt="" style={{ width: "100px" }} />
            </span>
            <p className="biuggbui fs-4">The Crown Chakra Sahasrara</p>
            <p>
              Crown chakra is responsible for the transcendence of your
              limitations and allows access t clarity and enlightened wisdom.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChakrasTimeline;
