import React from "react";
import { FaComputer } from "react-icons/fa";

function UserBlog() {
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        User Blog
      </div>
    </div>
  );
}

export default UserBlog;
