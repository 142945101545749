import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";

function Blogpage() {
  const Input = styled("input")({
    display: "none",
  });

  const [data, setdata] = useState([]);

  const [BlogsVideo, setBlogsVideo] = useState("");
  const [BlogsPdf, setBlogsPdf] = useState();
  const [BlogsAudio, setBlogsAudio] = useState("");
  const [BlogsImage, setBlogsImage] = useState("");
  const [BlogsText, setBlogsText] = useState("");
  const [BlogsHeader, setBlogsHeader] = useState("");
  const [BlogsTime, setBlogsTime] = useState("");

  const [BlogsVideo1, setBlogsVideo1] = useState("");
  const [BlogsPdf1, setBlogsPdf1] = useState();
  const [BlogsAudio1, setBlogsAudio1] = useState("");
  const [BlogsImage1, setBlogsImage1] = useState("");
  const [BlogsText1, setBlogsText1] = useState("");
  const [BlogsHeader1, setBlogsHeader1] = useState("");
  const [BlogsTime1, setBlogsTime1] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    seteditdata(data);
  };

  const [show2, setShow2] = useState(false);
  const [likes, setlikes] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setlikes(data);
  };

  const [show3, setShow3] = useState(false);
  const [pdfdata, setpdfdata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setpdfdata(data);
  };

  const [show4, setShow4] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setShow4(true);
    setdeletedata(data);
  };

  useEffect(() => {
    getBlogs();
  }, []);
  console.log("BlogsTime", BlogsHeader, BlogsTime, BlogsImage);
  const formdata = new FormData();
  const addBlogs = async () => {
    if (!BlogsHeader || !BlogsTime || !BlogsImage) {
      alert("Please enter blog header, Blog Time and Blog Image");
    } else {
      formdata.append("BlogsTime", BlogsTime);
      formdata.append("BlogsHeader", BlogsHeader);
      formdata.append("BlogsText", BlogsText);
      formdata.append("BlogsVideo", BlogsVideo);
      formdata.append("BlogsAudio", BlogsAudio);
      formdata.append("BlogsImage", BlogsImage);
      formdata.append("BlogsPdf", BlogsPdf);
      try {
        const config = {
          url: "/addBlogs",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "Content-Type": "multipart/form-data" },
          data: {
            BlogsTime: BlogsTime,
            BlogsHeader: BlogsHeader,
            BlogsText: BlogsText,
            BlogsVideo: BlogsVideo,
            BlogsImage: BlogsImage,
            BlogsAudio: BlogsAudio,
            BlogsPdf: BlogsPdf,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
            // getallBanners();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    }
  };

  const getBlogs = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBlogs")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.BlogsList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editBlogs = async () => {
    formdata.append("BlogsTime", BlogsTime1 ? BlogsTime1 : editdata?.blogT);
    formdata.append(
      "BlogsHeader",
      BlogsHeader1 ? BlogsHeader1 : editdata?.BlogsHeader
    );
    formdata.append("BlogsText", BlogsText1 ? BlogsText1 : editdata?.BlogsText);
    formdata.append(
      "BlogsVideo",
      BlogsVideo1 ? BlogsVideo1 : editdata?.BlogsVideo
    );
    formdata.append(
      "BlogsAudio",
      BlogsAudio1 ? BlogsAudio1 : editdata?.BlogsAudio
    );
    formdata.append(
      "BlogsImage",
      BlogsImage1 ? BlogsImage1 : editdata?.BlogsImage
    );
    formdata.append("BlogsPdf", BlogsPdf1 ? BlogsPdf1 : editdata?.BlogsPdf);

    try {
      const config = {
        url: "/editBlogs/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          BlogsTime: BlogsTime1 ? BlogsTime1 : editdata?.blogT,
          BlogsHeader: BlogsHeader1 ? BlogsHeader1 : editdata?.BlogsHeader,
          BlogsText: BlogsText1 ? BlogsText1 : editdata?.BlogsText,
          BlogsVideo: BlogsVideo1 ? BlogsVideo1 : editdata?.BlogsVideo,
          BlogsImage: BlogsImage1 ? BlogsImage1 : editdata?.BlogsImage,
          BlogsAudio: BlogsAudio1 ? BlogsAudio1 : editdata?.BlogsAudio,
          BlogsPdf: BlogsPdf1 ? BlogsPdf1 : editdata?.BlogsPdf,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getBlogs();
          handleClose1();
          alert("Data updated successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getBlogs();
      alert(error.response.data.error);
    }
  };

  const deleteBlogs = async () => {
    try {
      const config = {
        url: "/deleteBlogs/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose3();
          getBlogs();
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "blogT",
      text: "Time",
      formatter: (value, row) => <p>{row?.blogT ? parse(row?.blogT) : "-"}</p>,
    },
    {
      dataField: "BlogsHeader",
      text: "Header",
      formatter: (value, row) => (
        <p>{row?.BlogsHeader ? parse(row?.BlogsHeader) : "-"}</p>
      ),
    },
    {
      dataField: "BlogsText",
      text: "Text",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.BlogsText ? parse(row?.BlogsText) : "-"}
        </p>
      ),
    },
    {
      dataField: "BlogsImage",
      text: "Image",
      formatter: (value, row) =>
        row?.BlogsImage ? (
          <img
            src={"https://relentlessyou.live/Blogs/" + row.BlogsImage}
            width={100}
            height={80}
            onClick={() =>
              window.open("https://relentlessyou.live/Blogs/" + row.BlogsImage)
            }
          />
        ) : (
          "-"
        ),
    },
    {
      dataField: "BlogsAudio",
      text: "Audio",
      formatter: (value, row) =>
        row?.BlogsAudio ? (
          <audio
            width="100"
            height="100"
            controls
            autoPlay={false}
            onClick={() =>
              window.open("https://relentlessyou.live/Blogs/" + row.BlogsAudio)
            }
          >
            <source
              src={"https://relentlessyou.live/Blogs/" + row.BlogsAudio}
              type="audio/mp3"
              onClick={() =>
                window.open(
                  "https://relentlessyou.live/Blogs/" + row.BlogsAudio
                )
              }
            />
            Your browser does not support the video tag.
          </audio>
        ) : (
          "-"
        ),
    },
    {
      dataField: "BlogsVideo",
      text: "Video",
      formatter: (value, row) =>
        row?.BlogsVideo ? (
          <video
            width="100"
            height="100"
            controls
            autoPlay={false}
            onClick={() =>
              window.open("https://relentlessyou.live/Blogs/" + row.BlogsVideo)
            }
          >
            <source
              src={"https://relentlessyou.live/Blogs/" + row.BlogsVideo}
              type="video/mp4,video/webm,"
              onClick={() =>
                window.open(
                  "https://relentlessyou.live/Blogs/" + row.BlogsVideo
                )
              }
            />
          </video>
        ) : (
          "-"
        ),
    },
    {
      dataField: "BlogsPdf",
      text: "PDF",
      formatter: (value, row) =>
        row?.BlogsPdf ? (
          <>
            <img
              src={"https://cdn-icons-png.flaticon.com/128/202/202298.png"}
              alt="avathar"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
              onClick={() => handleShow3(row)}
            />
          </>
        ) : (
          "-"
        ),
    },
    {
      dataField: "",
      text: "Likes",
      formatter: (value, row) => (
        <>
          <p>{row?.likes?.length}</p>
          <button
            onClick={() => handleShow2(row)}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "#f37261" }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow4(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  console.log("BlogsPdf", BlogsPdf);
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Blogs
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              {/* <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton> */}
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button>
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "1000px" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Blogs</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col md={4}>
                <label>
                  Blog Time <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setBlogsTime(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>Select</option>
                  <option value="Morning">Morning</option>
                  <option value="Afternoon">Afternoon</option>
                  <option value="Evening">Evening</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>
                  Blog Header <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  placeholder="Header"
                  onChange={(e) => setBlogsHeader(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Blog Text</label>
              </Col>
              <Col md={6}>
                <textarea
                  style={{ width: "100%" }}
                  placeholder="Text"
                  onChange={(e) => setBlogsText(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>Blog Image</label>
              </Col>

              <Col md={6}>
                <label
                  htmlFor="image5"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setBlogsImage(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        BlogsImage
                          ? URL.createObjectURL(BlogsImage)
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
                <label style={{ textAlign: "center", display: "block" }}>
                  Image<span style={{ color: "red" }}>*</span>
                </label>
              </Col>
            </Row>

            <p>Upload any One (Audio/Video/PDF)</p>
            <Row className="mt-3">
              {BlogsVideo || BlogsPdf ? (
                ""
              ) : (
                <Col md={3}>
                  <label
                    htmlFor="audioInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="audio/*"
                      id="audioInput"
                      type="file"
                      onChange={(e) => setBlogsAudio(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsAudio
                            ? "https://cdn-icons-png.flaticon.com/128/4340/4340355.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    Audio
                  </label>
                </Col>
              )}
              {BlogsAudio || BlogsPdf ? (
                ""
              ) : (
                <Col md={3}>
                  <label
                    htmlFor="videoInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="video/*"
                      id="videoInput"
                      type="file"
                      onChange={(e) => setBlogsVideo(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsVideo
                            ? "https://cdn-icons-png.flaticon.com/128/2377/2377793.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    Video
                  </label>
                </Col>
              )}
              {BlogsAudio || BlogsVideo ? (
                ""
              ) : (
                <Col md={3}>
                  <label
                    htmlFor="pdf"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept=".pdf"
                      id="pdf"
                      type="file"
                      onChange={(e) => setBlogsPdf(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsPdf
                            ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    PDF
                  </label>
                </Col>
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addBlogs}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col md={4}>
                <label>Blog Time</label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setBlogsTime1(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>{editdata?.blogT}</option>
                  <option value="Morning">Morning</option>
                  <option value="Afternoon">Afternoon</option>
                  <option value="Evening">Evening</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Blog Header</label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  placeholder={editdata?.BlogsHeader}
                  onChange={(e) => setBlogsHeader1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Blog Text</label>
              </Col>
              <Col md={6}>
                <textarea
                  style={{ width: "100%" }}
                  placeholder={editdata?.BlogsText}
                  onChange={(e) => setBlogsText1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <label
                  htmlFor="image5"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setBlogsImage1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        BlogsImage1
                          ? URL.createObjectURL(BlogsImage1)
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
                <label style={{ textAlign: "center", display: "block" }}>
                  Image
                </label>
              </Col>
              {editdata?.BlogsAudio ? (
                <Col md={3}>
                  <label
                    htmlFor="audioInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="audio/*"
                      id="audioInput"
                      type="file"
                      onChange={(e) => setBlogsAudio1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsAudio1
                            ? "https://cdn-icons-png.flaticon.com/128/4340/4340355.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    Audio
                  </label>
                </Col>
              ) : (
                ""
              )}
              {editdata?.BlogsVideo ? (
                <Col md={3}>
                  <label
                    htmlFor="videoInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="video/*"
                      id="videoInput"
                      type="file"
                      onChange={(e) => setBlogsVideo1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsVideo1
                            ? "https://cdn-icons-png.flaticon.com/128/2377/2377793.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    Video
                  </label>
                </Col>
              ) : (
                ""
              )}
              {editdata?.BlogsPdf ? (
                <Col md={3}>
                  <label
                    htmlFor="pdf"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept=".pdf"
                      id="pdf"
                      type="file"
                      onChange={(e) => setBlogsPdf1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          BlogsPdf1
                            ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <label style={{ textAlign: "center", display: "block" }}>
                    PDF
                  </label>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editBlogs}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Likes - {likes?.BlogsHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            {likes?.userDetails?.map((lik) => (
              <>
                <div
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    display: "flex",
                    gap: "10px",
                    margin: "10px",
                  }}
                >
                  <img
                    src={
                      "https://relentlessyou.live/Customer/" + lik.profilepic
                    }
                    width={50}
                    height={50}
                    style={{ borderRadius: "50%" }}
                    onClick={() =>
                      window.open(
                        "https://relentlessyou.live/Customer/" + lik.profilepic
                      )
                    }
                  />
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {lik?.name}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {lik?.email}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{pdfdata?.BlogsHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Blogs/" + pdfdata?.BlogsPdf}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteBlogs}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Blogpage;
