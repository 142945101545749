import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import * as XLSX from "xlsx";

function Subscriberlist() {
  const [newdata, setnewdata] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedData, setselectedData] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setselectedData(data);
  };

  useEffect(() => {
    getUsersubscribe();
  }, []);

  const getUsersubscribe = () => {
    axios
      .get("https://relentlessyou.live/api/user/getUsersubscribe")
      .then(function (response) {
        console.log(response.data.UsersubscribeList);
        setnewdata(response.data.UsersubscribeList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const data = [];

  for (let i = 0; i < newdata.length; i++) {
    var xyz;
    const dateParts = (newdata[i]?.expiredate || "").split("/");
    const expireDate = new Date(
      `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    );
    const currentDate = new Date();

    if (isNaN(expireDate.getTime())) {
      xyz = false;
    }

    if (expireDate < currentDate) {
      // console.log('The subscription has expired.');
      xyz = false; // Exclude expired subscriptions
    } else {
      // console.log('The subscription is still valid.');
      xyz = true; // Include valid subscriptions
    }

    data.push({
      SubscriptionID: newdata[i]?.SubscriptionID,
      Expire_date: newdata[i]?.expiredate,
      Amount: newdata[i]?.subscribeplans[0]?.Amount,
      Duration: newdata[i]?.subscribeplans[0]?.Duration,
      Name: newdata[i]?.subscribeplans[0]?.Name,
      Type: newdata[i]?.subscribeplans[0]?.Type,
      User_Name: newdata[i]?.user[0]?.name,
      User_Email: newdata[i]?.user[0]?.email,
      User_Contact_No: newdata[i]?.user[0]?.mobile,

      AudioVideo: newdata[i]?.subscribeplans[0]?.AudioVideo,

      Blogs: newdata[i]?.subscribeplans[0]?.Blogs,

      KidsSection: newdata[i]?.subscribeplans[0]?.KidsSection,

      MostAwaitedAudio: newdata[i]?.subscribeplans[0]?.MostAwaitedAudio,

      MostAwaitedVideo: newdata[i]?.subscribeplans[0]?.MostAwaitedVideo,

      MostPopularAudio: newdata[i]?.subscribeplans[0]?.MostPopularAudio,

      MostPopularVideo: newdata[i]?.subscribeplans[0]?.MostPopularVideo,

      MusicTherapy: newdata[i]?.subscribeplans[0]?.MusicTherapy,

      Podcast: newdata[i]?.subscribeplans[0]?.Podcast,

      Snippets: newdata[i]?.subscribeplans[0]?.Snippets,

      Validity: xyz ? "Valid" : "Expired",
    });
  }

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exported_data.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log("newdata", newdata);

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Name",
      text: "Sunbscription Category",
      formatter: (value, row) => <p>{row?.Name ? row?.Name : "-"}</p>,
    },
    {
      dataField: "Type",
      text: "Sunbscription Type",
      formatter: (value, row) => <p>{row?.Type ? row?.Type : "-"}</p>,
    },
    {
      dataField: "User_Name",
      text: "Subscriber Name",
      formatter: (value, row) => <p>{row?.User_Name ? row?.User_Name : "-"}</p>,
    },
    {
      dataField: "User_Contact_No",
      text: "Subscriber Mobile No.",
      formatter: (value, row) => (
        <p>{row?.User_Contact_No ? row?.User_Contact_No : "-"}</p>
      ),
    },
    {
      dataField: "User_Email",
      text: "Subscriber Email ID",
      formatter: (value, row) => (
        <p>{row?.User_Email ? row?.User_Email : "-"}</p>
      ),
    },
    {
      dataField: "Amount",
      text: "Amount",
      formatter: (value, row) => <p>{row?.Amount ? row?.Amount : "-"}</p>,
    },
    {
      dataField: "Duration",
      text: "Duration",
      formatter: (value, row) => <p>{row?.Duration ? row?.Duration : "-"}</p>,
    },
    {
      dataField: "Expire_date",
      text: "Expire Date",
      formatter: (value, row) => (
        <p>
          {row?.Expire_date
            ? moment(row?.Expire_date).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      dataField: "Validity",
      text: "Validity",
      formatter: (value, row) => (
        <p
          style={{
            backgroundColor: row?.Validity === "Valid" ? "Green" : "Red",
            color: "white",
            textAlign: "center",
          }}
        >
          {row?.Validity ? row?.Validity : "-"}
        </p>
      ),
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div>
          <button
            onClick={() => handleShow(row)}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "#f37261" }}
            ></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Subscriber list
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              {/* <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton> */}
              <button
                style={{
                  marginLeft: "30px",
                  border: "none",
                  color: "white",
                  padding: "6px",
                  borderRadius: "5px",
                  backgroundColor: "#f37261",
                }}
                onClick={() => downloadExcel()}
              >
                Download Excel
              </button>

              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "900px" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="a"
                  checked={selectedData?.MostPopularAudio}
                />
                &nbsp;
                <label for="a">Most-Popular-Audio</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="b"
                  checked={selectedData?.MostPopularVideo}
                />
                &nbsp;
                <label for="b">Most-Popular-Video</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="c"
                  checked={selectedData?.MostAwaitedAudio}
                />
                &nbsp;
                <label for="c">Most-Awaited-Audio</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="d"
                  checked={selectedData?.MostAwaitedVideo}
                />
                &nbsp;
                <label for="d">Most-Awaited-Video</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="e"
                  checked={selectedData?.KidsSection}
                />
                &nbsp;
                <label for="e">Kids-Section</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="f"
                  checked={selectedData?.MusicTherapy}
                />
                &nbsp;
                <label for="f">Music-Therapy</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="g"
                  checked={selectedData?.AudioVideo}
                />
                &nbsp;
                <label for="g">Audio/Video</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="h"
                  checked={selectedData?.Snippets}
                />
                &nbsp;
                <label for="h">Snippets</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input type="checkbox" id="g" checked={selectedData?.Blogs} />
                &nbsp;
                <label for="g">Blogs</label>
              </Col>
              <Col md={6}>
                <input type="checkbox" id="i" checked={selectedData?.Podcast} />
                &nbsp;
                <label for="i">Podcast</label>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary">Close</Button> */}
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Subscriberlist;
