import Aos from "aos";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { GoArrowRight } from "react-icons/go";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";

const Services = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/30.jpg')",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "500px",
          position: "relative",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh">
          <div className="row">
            <div className="col-md-12 text-center ojjjpoiiii">
              <p data-aos="fade-down" data-aos-duration="2000">
                Service
              </p>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div className="container my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-3 kjhjhoi">
              <p>Our Services</p>
              <div className="iuyiuy"></div>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="col-md-7 iuio"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <p>Feel Your Body, Find Your Mind, And Be At Ease</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 iouopiu">
              <p>
                I love an amazing yoga quote! They are profound in a comforting
                and inspiring way. Each one feels poetic and they have the
                ability to turn any day around.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/2.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Relaxation Yoga</Card.Title>
                <Card.Text>I love an amazing yoga quote! .</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/3.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Meditation</Card.Title>
                <Card.Text>That’s the beauty of yoga.</Card.Text>
                {/* <Button
                                    variant="primary"
                                    style={{
                                        background: "none",
                                        border: "none",
                                        color: "#F6AA59",
                                        fontSize: "20px",
                                    }}
                                >
                                    More Info
                                </Button> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/4.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Nutrition</Card.Title>
                <Card.Text>
                  I got my first tattoo a few years ago. Naturally, it is a yoga
                  saying. ‘sthira sukham’ means ‘steady and joyful’.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/2.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Relaxation Yoga</Card.Title>
                <Card.Text>
                  The rhythm of the body, the melody of the mind.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/3.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Meditation</Card.Title>
                <Card.Text>
                  The yoga pose you avoid the most you need the most.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/4.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Nutrition</Card.Title>
                <Card.Text>
                  Yoga is a mirror to look at ourselves from within.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 oiuoiu hhhhk_0000">
            <Card
              style={{ width: "16rem", borderRadius: "unset", height: "500px" }}
            >
              <Card.Img
                variant="top"
                src="../Img/5.jpg"
                style={{ height: "350px", borderRadius: "unset" }}
              />
              <Card.Body className="text-center">
                <Card.Title>Hatha Yoga</Card.Title>
                <Card.Text>Yoga is not a work-out, it is a work-in.</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#F6F6F6" }} className="pb-5">
        <div className="container pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 kjhjhoi">
                <p>Choose Your Plan</p>
                <div className="iuyiuy"></div>
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-md-7 iuio"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <p>Yoga Is Like A Journey To The Center Of Self</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 iouopiu">
                <p>
                  {" "}
                  Yoga does not just change the way we see things, it transforms
                  the person who sees.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">STANDARD</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 3000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">PREMIUM</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 6000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">PLANTIUM</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 3000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#E78D1B" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#E78D1B" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#E78D1B" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#E78D1B" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Services;
