import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaPlay,
  FaStar,
  FaWhatsapp,
} from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";
import "../Aboutus/Aboutus.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import Mobileapp from "../Home/mobileapp";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoGoogleplus } from "react-icons/io";

const Aboutus = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
          // backgroundAttachment: "fixed",
          // marginTop: "-86px",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh pt-5">
          <div className="row me-0">
            <div className="col-md-12  ojjjpoiiii text-center">
              <p>About Us</p>
              <span>
                <a href="/">Home /</a>
                <a href="">About Us</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      {/* About us */}
      <div>
        <div
          style={{
            backgroundColor: "#F7F7F7",
            padding: "16px 20px 16px 20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          <div className="hvetfv">
            <div style={{ position: "absolute", top: "-75px", left: "0px" }}>
              <img src="../Img/39.png" alt="" style={{ width: "120px" }} />
            </div>
            <div style={{ position: "absolute", top: "-75px", right: "0px" }}>
              <img src="../Img/40.png" alt="" style={{ width: "120px" }} />
            </div>
            <div
              style={{ position: "absolute", top: "50.5rem", left: "0px" }}
              className="hftydtyd"
            >
              <img src="../Img/43.png" alt="" style={{ width: "130px" }} />
            </div>
            <div
              style={{ position: "absolute", top: "50.5rem", right: "0px" }}
              className="hftydtyd"
            >
              <img src="../Img/45.png" alt="" style={{ width: "130px" }} />
            </div>
          </div>
          <div className="container">
            <div className="row me-0">
              <div className="col-md-12 text-center mb-4">
                <p className="biuggbui">About Us</p>
                <div className="iuyiuyf"></div>
              </div>

              <div className="col-md-6 jeheuih m-auto">
                <p
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  data-aos-easing="ease-in-out"
                >
                  Welcome to Relentless You
                </p>
                <p
                  data-aos="fade-right"
                  data-aos-duration="2500"
                  data-aos-easing="ease-in-out"
                >
                  "My mission is to empower. Empower the professional, empower
                  the student, the housewife, the child – to relentlessly power
                  on, no matter how grave the circumstances. It’s so important
                  for people to understand that they define their success. Not
                  their circumstances"
                </p>
                <br />
                <p
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  data-aos-easing="ease-in-out"
                >
                  When you become aware of your connections with the important
                  people in your life you start to see what makes all
                  connections and interactions unique. To be able to witness
                  life through the eyes of a daughter, a mother, a wife, the
                  Life Coach, the trainer, the hypnotherapist, truly gives a
                  grand perspective on paths we may take for granted. The
                  learning is relentless.
                </p>
              </div>
              <div className="col-md-6 iuygyg text-center">
                <div
                  className="yvrd"
                  data-aos="zoom-out"
                  data-aos-duration="3000"
                  data-aos-easing="ease-in-out"
                >
                  <img src="/Img/64.png" alt="" style={{ width: "80%" }} />
                  <div>
                    <span
                      style={{ cursor: "pointer" }}
                      class="play-btn"
                      onClick={() => handleShow1()}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row me-0 mt-5">
              <div
                className="col-md-6"
                data-aos="zoom-out"
                data-aos-duration="3000"
                data-aos-easing="ease-in-out"
              >
                <img
                  src="../Img/68.jpg"
                  alt=""
                  style={{ width: "100%", height: "415px" }}
                />
              </div>
              <div className="col-md-6 jeheuih ">
                <p></p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-easing="ease-in-out"
                >
                  I do believe that far too often; we search through our
                  darkness trying to find a light. The key to prolonged mental,
                  physical and spiritual healing is to be able to connect with
                  souls who have a way of giving us the light to bring us out of
                  our darkness. Sometimes, the most extraordinary answers lie in
                  the most ordinary things. It’s simply about expanding one’s
                  consciousness.
                </p>
                <p></p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-easing="ease-in-out"
                >
                  A woman who started her own professional life fairly reticent
                  and shy about putting herself out there in the Public
                  Speaking, Behzad recognizes the need to grow from the inside
                  out, the need to fix one’s self in resilience and the pursuit
                  of relentlessness, to believe at the most subconscious level
                  that there’s nothing out there that is unachievable to the one
                  who BELIEVES.
                </p>
                <div
                  className="hhhhk_000 huuiugg mt-2"
                  // data-aos="fade-left"
                  // data-aos-duration="500"
                >
                  <button onClick={() => navigate("/aboutme")}>
                    Know More{" "}
                    <span>
                      <MdOutlineArrowRightAlt />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About me */}

      {/* Our Specialties */}
      <div style={{ backgroundColor: "#f6f6f6", marginTop: "30px" }}>
        <div className="container p-5 iugiuy" style={{ width: "900px" }}>
          <div className="row me-0">
            <div className="col-md-12  text-center">
              <p className="biuggbui">Our Specialities</p>
              <div className="iuyiuyf"></div>
            </div>
            <div className="col-md-12 text-center oijijo">
              <p>We Are Your Safety Net</p>
              <p>
                We at Relentless-you.com work as a safety net for you to rely
                on, on those difficult days. No matter who you are or where you
                are in your life, we have something to offer that will help you
                strive on relentlessly.
              </p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-3 mt-3">
                  <div className="row me-0">
                    <div className="col-md-3 hyguya">
                      <Card
                        style={{
                          width: "15rem",
                          textAlign: "center",
                          height: "145px",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src="../Img/15.png"
                          style={{
                            margin: "auto",
                            width: "20%",
                            padding: "10px",
                            marginTop: "25px",
                          }}
                        />
                        <Card.Body style={{ padding: "unset" }}>
                          <Card.Title>Guided Meditations</Card.Title>
                        </Card.Body>
                      </Card>
                      <Card
                        style={{
                          width: "15rem",
                          textAlign: "center",
                          height: "145px",
                          marginTop: "30px",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src="../Img/18.png"
                          style={{
                            margin: "auto",
                            width: "20%",
                            padding: "10px",
                            marginTop: "25px",
                          }}
                        />
                        <Card.Body style={{ padding: "unset" }}>
                          <Card.Title>Affirmations</Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div
                    style={{
                      backgroundImage: "url('/Img/14.png')",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "453px",
                    }}
                    className="gygfyf"
                  >
                    <div
                      className="hhhhk_000 ahahdjhjh"
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="../Image/img3.jpg"
                        // src="../Img/17.png"
                        alt=""
                        style={{
                          width: "250px",
                          height: "250px",
                          alignSelf: "center",
                          marginTop: "40px",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-3 hyguya">
                  <div className="row me-0">
                    <div
                      className="col-md-3 hihgifd"
                      style={{ marginLeft: "-70px" }}
                    >
                      <Card
                        style={{
                          width: "15rem",
                          textAlign: "center",
                          height: "145px",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src="../Img/19.png"
                          style={{
                            margin: "auto",
                            width: "20%",
                            padding: "10px",
                            marginTop: "25px",
                          }}
                        />
                        <Card.Body style={{ padding: "unset" }}>
                          <Card.Title>Music Therapy</Card.Title>
                        </Card.Body>
                      </Card>
                      <Card
                        style={{
                          width: "15rem",
                          textAlign: "center",
                          height: "145px",
                          marginTop: "30px",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src="../Img/20'.png"
                          style={{
                            margin: "auto",
                            width: "20%",
                            padding: "10px",
                            marginTop: "25px",
                          }}
                        />
                        <Card.Body
                          style={{
                            padding: "unset",
                          }}
                        >
                          <Card.Title>Blogs & Podcasts</Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Mobileapp />
      </div>
      <div>
        <div className="container my-3">
          <div className="row me-0 text-center">
            <div className="col-md-12 ">
              <p className="biuggbui fs-3">Our Trainers</p>
              <div className="iuyiuyf"></div>
            </div>
          </div>
        </div>
        <div className="container my-4">
          <div className="row me-0">
            <div style={{ alignSelf: "center" }}>
              <div>
                <div className="containerrrrr">
                  <Card style={{ width: "17rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Image/img4.jpg"
                      style={{ height: "225px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title className="fs-4">Behzad Randeria</Card.Title>
                      <Card.Text className="fs-5">
                        PCC-ICF, Hypnotherapist, NLP Practitioner, Master Spirt
                        Life Coach
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <p>
                      <span>
                        {" "}
                        <FaFacebookF
                          style={{
                            fontSize: "30px",
                            border: "1px solid white",
                            padding: "5px ",
                            borderRadius: "50%",
                            color: "black",
                            backgroundColor: "white",
                          }}
                        />
                      </span>
                      <span>
                        {" "}
                        <FaXTwitter
                          style={{
                            fontSize: "30px",
                            border: "1px solid white",
                            padding: "5px ",
                            borderRadius: "50%",
                            color: "black",
                            backgroundColor: "white",
                          }}
                        />
                      </span>
                      <span>
                        {" "}
                        <FaInstagram
                          style={{
                            fontSize: "30px",
                            border: "1px solid white",
                            padding: "5px ",
                            borderRadius: "50%",
                            color: "black",
                            backgroundColor: "white",
                          }}
                        />
                      </span>
                      <span>
                        {" "}
                        <IoLogoGoogleplus
                          style={{
                            fontSize: "30px",
                            border: "1px solid white",
                            padding: "5px ",
                            borderRadius: "50%",
                            color: "black",
                            backgroundColor: "white",
                          }}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        style={{
          textAlign: "end",
          fontWeight: "bold",
          paddingRight: "10px",
        }}
      >
        This Application is Managed by{" "}
        <span style={{ color: "#f37261" }}> "Behzad Histas Randeria"</span>
      </p>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#F58243" }}>
              Download the app Now!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div
                className="row"
                style={{ padding: "3rem", backgroundColor: "#F58243" }}
              >
                <div
                  className="col-md-6"
                  style={{ backgroundColor: "#F58243" }}
                >
                  <img
                    src="/Img/Download.png"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ backgroundColor: "#F58243" }}
                >
                  <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                  <br />
                  <h6 style={{ color: "white" }}>
                    Your FITNESS is Our Concern.
                  </h6>
                  <br />
                  <p style={{ color: "white" }}>
                    Download the Yoga App Today and Embrace a Healthier You!"
                  </p>
                </div>
              </div>
              <div className="row me-0" style={{ backgroundColor: "#F58243" }}>
                <div className="col-md-12 mb-3 text-center">
                  <div className="hhhhk_000">
                    <button>
                      <a
                        href="https://play.google.com/store/games?device=windows&pli=1"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {" "}
                        <span>
                          <FaDownload style={{ fontSize: "30px" }} />
                        </span>
                        Download Android | ios
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Modal
          size="lg"
          show={show1}
          onHide={handleClose1}
          style={{ marginTop: "69px" }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <video
                    src="/Img/video_1.mp4"
                    autoPlay
                    controls
                    muted
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "fill",
                      border: "none",
                    }}
                  ></video>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Aboutus;
