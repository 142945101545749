import React from "react";
import "../Maditation/styles/Happy.css";

const MusicalTherapy = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
          // backgroundAttachment: "fixed",
          // marginTop: "-86px",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh pt-5">
          <div className="row">
            <div className="col-md-12  ojjjpoiiii text-center">
              <p>Musical Therapy</p>
              <span>
                <a href="/">Home/</a>
                <a href="">Meditation/</a>
                <a href="">Musical Therapy</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div
        style={{
          backgroundColor: "#F7F7F7",
          padding: "16px 20px 16px 20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div className="hvetfv">
          <div style={{ position: "absolute", top: "-75px", left: "0px" }}>
            <img src="../Img/39.png" alt="" style={{ width: "120px" }} />
          </div>
          <div style={{ position: "absolute", top: "-75px", right: "0px" }}>
            <img src="../Img/40.png" alt="" style={{ width: "120px" }} />
          </div>
          <div
            style={{ position: "absolute", top: "42rem", left: "0px" }}
            className="guyf6"
          >
            <img src="../Img/43.png" alt="" style={{ width: "130px" }} />
          </div>
          <div
            style={{ position: "absolute", top: "45rem", right: "0px" }}
            className="guyf6"
          >
            <img src="../Img/45.png" alt="" style={{ width: "130px" }} />
          </div>
        </div>
        <div className="container mb-5">
          <div className="row mt-5">
            <div className="col-md-4">
              <img
                // src="../Img/88.png"
                src="../Image/img9.png"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </div>
            <div className="col-md-8 m-auto">
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "justify",
                }}
                className="fs-5"
              >
                It has been said that there is a frequency that is around us at
                all times. Vibrations. You’ve heard the term loosely thrown
                around, good vibes, bad vibes. It is what we sense when we see
                or know things we can’t explain. The ethereal vibrations. These
                frequencies are real, they manifest in many ways, they help our
                entire being depending on the tuning at the time. From a fond
                memory from our past, to shockwave therapy to repair muscles in
                our bodies. We are connected to the beat, to the frequency, to
                the rhythm of this universe. One might say the heartbeat of it
                all.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mt-5">
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "justify",
                }}
                className="fs-5"
              >
                The joy of music is also a factor that allows it to yield such
                high success rates. When you compound the many benefits to a
                good song both in your soul and in your heart as well as
                vibrationally and rhythmically, you really see just how
                impactful a good Music Therapy session can be, no matter what
                you have chosen to use this method for.
              </p>
              {/* <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "justify",
                }}
                className="fs-5"
              >
                The joy of music is also a factor that allows it to yield such
                high success rates. When you compound the many benefits to a
                good song both in your soul and in your heart as well as
                vibrationally and rhythmically, you really see just how
                impactful a good Music Therapy session can be, no matter what
                you have chosen to use this method for.
              </p> */}
            </div>
            <div className="col-md-4">
              <img
                // src="../Img/90.png"
                src="../Image/img10.png"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicalTherapy;
