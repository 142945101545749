import React from "react";
import "../Maditation/styles/Maditation1.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";

const Maditation1 = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Image/img5.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh">
          <div className="row">
            <div className="col-md-12 ojjjpoiiii text-center">
              <p>Meditations For the Children</p>
              <span>
                <a href="/">Home/</a>
                <a href="">Meditation/</a>
                <a href="">Meditations For the Children</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div style={{ backgroundColor: "#F5F7F9", marginBottom: "20px" }}>
        <div className="container pt-5 py-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="biuggbui fs-1">
                Mindfulness Meditation For Children
              </p>
              <p className="biuggbui fs-4">
                A child’s complete guide to a stress-free life.
              </p>
              <div className="iuyiuyf"></div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-8 jeheuih m-auto">
              <p></p>
              <p>
                Children are a whole lot more resilient than we are as adults.
                You must have noticed that. Life has yet to happen for them.
                They are still open books waiting for the pages to be filled in.
                Think about this for a second. If you had the chance to do your
                own childhood over again, how differently would you wish your
                pages to have been filled in? If you would keep the book of your
                childhood exactly as it is, as it happened, I am happy for you:
                you are one of the lucky ones. Most of us were not. Sometimes
                our pages got stuck together. But here is the fundamental truth
                and similarity for all of us as guardians. We have the chance
                and privilege to help write our children’s books differently,
                more mindfully! This is a real privilege.
              </p>
            </div>
            <div className="col-md-4 m-auto">
              <img
                // src="/Img/59.png"
                src="/Image/img6.jpg"
                alt=""
                style={{ width: "80%", height: "20%" }}
                className="rstrdrcgh"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F7F7F7",
          padding: "16px 20px 16px 20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div className="hvetfvv">
          <div style={{ position: "absolute", top: "-90px", left: "0px" }}>
            <img src="../Img/39.png" alt="" style={{ width: "130px" }} />
          </div>
          <div style={{ position: "absolute", top: "-90px", right: "0px" }}>
            <img src="../Img/40.png" alt="" style={{ width: "130px" }} />
          </div>
          <div
            style={{ position: "absolute", top: "25rem", left: "0px" }}
            className="rdtrdrd"
          >
            <img src="../Img/43.png" alt="" style={{ width: "130px" }} />
          </div>
          <div
            style={{ position: "absolute", top: "27.5rem", right: "0px" }}
            className="rdtrdrd"
          >
            <img src="../Img/45.png" alt="" style={{ width: "130px" }} />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/Img/60.png"
                alt=""
                style={{ width: "100%", height: "550px" }}
              />
            </div>
            <div className="col-md-6 jeheuih m-auto">
              <p></p>
              <p>
                The best guidance we can give children in their emotionally
                formative years consists in allowing them to find more rounded
                perspectives in self-awareness, with a little more control over
                their thoughts and a little perceptiveness for certain triggers.
              </p>
              <br />
              <p>
                I have been asked in my coaching sessions: “What’s the best age
                to begin this training with children?” I answer, “When you can
                effectively convey a certain activity of mindfulness in a way
                that will suit their understanding, or they instinctively grasp
                certain activities, then, they are old enough to imbibe
                Mindfulness as a daily practice.” I have found this can begin
                for a child as young as three!
              </p>
              {/* <div
                className="hhhhk_000 huuiugg mt-2"
                // data-aos="fade-left"
                // data-aos-duration="500"
              >
                <button>
                  Know More{" "}
                  <span>
                    <MdOutlineArrowRightAlt />
                  </span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maditation1;
