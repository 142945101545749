import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";

function Subadmincreate() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [SecurityKey, setSecurityKey] = useState("");

  const [user, setuser] = useState(false);
  const [Book, setBook] = useState(false);
  const [video, setvideo] = useState(false);
  const [audio, setaudio] = useState(false);
  const [kids, setkids] = useState(false);
  const [Mpopular, setMpopular] = useState(false);
  const [Mawaited, setMawaited] = useState(false);
  const [podcast, setpodcast] = useState(false);
  const [musictherapy, setmusictherapy] = useState(false);
  const [snippets, setsnippets] = useState(false);
  const [blog, setblog] = useState(false);
  const [subscription, setsubscription] = useState(false);
  const [accounts, setaccounts] = useState(false);
  const [contactus, setcontactus] = useState(false);

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, one special character and must be 8 digit!"
      );
      return false;
    }
  }

  const createsubadmin = async (e) => {
    if (!email || !password || !name) {
      alert("Please Fill All The Field");
    } else if (
      user ||
      Book ||
      video ||
      audio ||
      kids ||
      Mpopular ||
      Mawaited ||
      podcast ||
      musictherapy ||
      snippets ||
      blog ||
      subscription ||
      accounts ||
      contactus
    ) {
      try {
        if (
          validatename(name) &&
          ValidateEmail(email) &&
          CheckPassword(password)
        ) {
          const config = {
            url: "/createsubadmin",
            method: "post",
            baseURL: "https://relentlessyou.live/api/admin",
            headers: { "content-type": "application/json" },
            data: {
              name: name,
              email: email,
              password: password,
              user: user,
              Book: Book,
              video: video,
              audio: audio,
              kids: kids,
              Mpopular: Mpopular,
              Mawaited: Mawaited,
              podcast: podcast,
              musictherapy: musictherapy,
              snippets: snippets,
              blog: blog,
              subscription: subscription,
              accounts: accounts,
              contactus: contactus,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            console.log(res.data.success);
            alert("Subadmin created Successfully");
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    } else {
      alert("Plase select asscess details");
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Create-Subadmin
      </div>
      <div
        style={{
          overflow: "hidden",
          overflowY: "auto",
          margin: "20px",
          border: "1px solid gray",
          paddingTop: "50px",
          paddingBottom: "50px",
          alignItems: "center",
          display: "grid",
          width: "75%",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "10px",
        }}
      >
        <div style={{ width: "35%", marginLeft: "auto", marginRight: "auto" }}>
          <Row>
            <Col md={6}>
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={6}>
              <input
                placeholder="Name"
                onChange={(e) => setname(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <label>
                Email ID <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={6}>
              <input
                placeholder="Email ID"
                onChange={(e) => setemail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <label>
                Password <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={6}>
              <div style={{ display: "flex" }}>
                <input
                  type={SecurityKey ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => setpassword(e.target.value)}
                />
                {SecurityKey ? (
                  <button
                    onClick={() => setSecurityKey(!SecurityKey)}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#f37261" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={() => setSecurityKey(!SecurityKey)}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#f37261" }}
                    ></i>
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}>
          <Row className="mt-5">
            <Col md={4}>
              <div>
                <input type="checkbox" onChange={() => setuser(!user)} />
                &nbsp;
                <label>User</label>
              </div>

              <div>
                <input type="checkbox" onChange={() => setvideo(!video)} />
                &nbsp;
                <label>Video List</label>
              </div>
              <div>
                <input type="checkbox" onChange={() => setaudio(!audio)} />
                &nbsp;
                <label>Audio List</label>
              </div>
              <div>
                <input type="checkbox" onChange={() => setkids(!kids)} />
                &nbsp;
                <label>Kids Section</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setcontactus(!contactus)}
                />
                &nbsp;
                <label>Contact-Us</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input type="checkbox" onChange={() => setBook(!Book)} />
                &nbsp;
                <label>Books</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setMpopular(!Mpopular)}
                />
                &nbsp;
                <label>Most Popular</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setMawaited(!Mawaited)}
                />
                &nbsp;
                <label>Most Awaited</label>
              </div>
              <div>
                <input type="checkbox" onChange={() => setpodcast(!podcast)} />
                &nbsp;
                <label>Podcast List</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setmusictherapy(!musictherapy)}
                />
                &nbsp;
                <label>Music Therapy</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setsnippets(!snippets)}
                />
                &nbsp;
                <label>Snippets</label>
              </div>
              <div>
                <input type="checkbox" onChange={() => setblog(!blog)} />
                &nbsp;
                <label>Blogs</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setsubscription(!subscription)}
                />
                &nbsp;
                <label>Subscription</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  onChange={() => setaccounts(!accounts)}
                />
                &nbsp;
                <label>Account History</label>
              </div>
            </Col>
          </Row>
        </div>
        <Button
          style={{
            width: "20%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "8%",
          }}
          onClick={createsubadmin}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default Subadmincreate;
