import React from "react";
import "../Home/Home.css";

const Mobileapp = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/56.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "500px",
          backgroundAttachment: "fixed",
          position: "relative",
        }}
        className="fcrtxrt"
      >
        <div
          className="container"
          style={{ position: "relative", zIndex: "999" }}
        >
          <div className="row">
            <div className="col-md-6 bugufufu">
              <h1>DOWNLOAD OUR APP FOR FREE</h1>
              <p>
                Revitalize and transform your life with our App- your pocket
                sized guide to transforming yourself into a happier, calmer,
                abundant you. From daily affirmations to kick start your day, to
                guided meditations specially curated by renowned music
                therapists, our app offers a plethora of meditations, podcasts,
                and blogs to help you weather the storms of life, relentlessly.
              </p>
              <div>
                <img
                  src="../Img/playstore.png"
                  alt=""
                  style={{ width: "40%" }}
                  onClick={() =>
                    window.location.assign(
                      "https://play.google.com/store/games?device=windows&pli=1"
                    )
                  }
                />
                <img
                  src="../Img/app stoor.png"
                  alt=""
                  style={{ width: "40%" }}
                  onClick={() =>
                    window.location.assign(
                      "https://www.apple.com/in/app-store/"
                    )
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/Image/img2.png"
                alt=""
                style={{ width: "100%", height: "500px" }}
              />
            </div>
          </div>
        </div>
        <div className="bs-sliderr"></div>
      </div>
    </div>
  );
};

export default Mobileapp;
