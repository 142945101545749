import axios from "axios";
import React, { useEffect, useState } from "react";

function Changepassword() {
  const [loginPassword, setloginPassword] = useState("");
  const [password, setpassword] = useState("");
  const data = JSON.parse(sessionStorage.getItem("data"));

  console.log("data", data);

  const resetpassword = async (e) => {
    if (!password) {
      alert("Please Enter new password");
    } else {
      try {
        const config = {
          url: "/ChangePassword",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            password: password,
            AdminId: data._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data.success);
          alert("Password changed successfully");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("data");
          window.location.assign("/admin");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      <div className="maindiv">
        <div className="firstdiv">
          <img src="/Image/logo.png" />
        </div>
        <div className="seconddiv">
          <p className="forgotpassword-header">Change Password</p>
          <p style={{ color: "#f37261", textAlign: "center" }}>
            Enter new password want you want
          </p>
          <div className="mt-5" style={{ marginLeft: "10%" }}>
            <label className="login-label">Enter new password*</label>
            <br></br>
            <div className="cust-login1 mb-3">
              <input
                className="login-input"
                type={loginPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
              />
              {loginPassword ? (
                <button
                  onClick={() => setloginPassword(!loginPassword)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginLeft: "-36px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                    style={{ color: "#f37261" }}
                  ></i>
                </button>
              ) : (
                <button
                  onClick={() => setloginPassword(!loginPassword)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginLeft: "-35px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: "#f37261" }}
                  ></i>
                </button>
              )}
            </div>
          </div>
          <button className="login-btn" onClick={resetpassword}>
            Reset
          </button>
        </div>
      </div>
    </>
  );
}

export default Changepassword;
