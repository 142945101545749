import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { IoHome } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import StarRatings from "react-star-ratings";
import axios from "axios";
import { Link } from "react-router-dom";

const Booksection = () => {
  const [Bookscat, setBookscat] = useState([]);
  const [Books, setBooks] = useState([]);
  const [Allbooks, setAllbooks] = useState([]);
  const [cat, setcat] = useState("");

  const getBookscategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBookscategory")
      .then(function (response) {
        setBookscat(response.data.BookscategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBooks = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBooks")
      .then(function (response) {
        setAllbooks(response.data.BooksList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getBookscategory();
    getBooks();
  }, []);

  useEffect(() => {
    if (cat) {
      setBooks(Allbooks?.filter((fil) => fil?.catname === cat));
    }
  }, [cat]);

  console.log("Allbooks", Allbooks, Books, cat);
  return (
    <div>
      <div className="row">
        <Col md={2} style={{ borderRight: "1px solid gray" }}>
          <p style={{ margin: "10px", fontWeight: "bold", fontSize: "18px" }}>
            Category
          </p>
          <ul>
            {Bookscat?.map((cate) => (
              <li
                onClick={() => setcat(cate?.name)}
                style={{
                  color: cat == cate?.name ? "rgb(243, 114, 97)" : "black",
                }}
              >
                {cate?.name}
              </li>
            ))}
          </ul>
        </Col>
        <Col md={9}>
          <Row>
            {cat
              ? Books?.map((item) => {
                  return (
                    <Col md={3}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/booksdetails"
                        state={{ item: item }}
                      >
                        <Card
                          style={{
                            margin: "2px",
                            height: "375px",
                            overflow: "hidden",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src={`https://relentlessyou.live/Books/${item?.Booksimg}`}
                            style={{
                              padding: "10px",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ whiteSpace: "nowrap" }}>
                              {item?.name}
                            </Card.Title>
                            <Card.Text>
                              <div>
                                <p style={{ margin: "0px" }}>
                                  by {item?.authorname}
                                </p>
                                <StarRatings
                                  rating={
                                    item?.reviews?.length > 0
                                      ? item?.reviews?.reduce(
                                          (a, rat) => a + Number(rat.rating),
                                          0
                                        )
                                      : 0
                                  }
                                  starRatedColor="gold"
                                  // changeRating={3}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="20px"
                                  starSpacing="0px"
                                />
                                <p>
                                  ₹{" "}
                                  {(
                                    item?.amount -
                                    (item?.amount * item?.discount) / 100
                                  )?.toFixed(2)}{" "}
                                  M.R.P:{" "}
                                  <span
                                    style={{
                                      textDecorationLine: "line-through",
                                      fontSize: "12px",
                                    }}
                                  >
                                    ₹{item?.amount}
                                  </span>{" "}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    ({item?.discount}%)
                                  </span>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                })
              : Allbooks?.map((item) => {
                  return (
                    <Col md={3}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/booksdetails"
                        state={{ item: item }}
                      >
                        <Card
                          style={{
                            margin: "2px",
                            height: "375px",
                            overflow: "hidden",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src={`https://relentlessyou.live/Books/${item?.Booksimg}`}
                            style={{
                              padding: "10px",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ whiteSpace: "nowrap" }}>
                              {item?.name}
                            </Card.Title>
                            <Card.Text>
                              <div>
                                <p style={{ margin: "0px" }}>
                                  by {item?.authorname}
                                </p>
                                <StarRatings
                                  rating={
                                    item?.reviews?.length > 0
                                      ? item?.reviews?.reduce(
                                          (a, rat) => a + Number(rat.rating),
                                          0
                                        )
                                      : 0
                                  }
                                  starRatedColor="gold"
                                  // changeRating={3}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="20px"
                                  starSpacing="0px"
                                />
                                <p>
                                  ₹{" "}
                                  {(
                                    item?.amount -
                                    (item?.amount * item?.discount) / 100
                                  )?.toFixed(2)}{" "}
                                  M.R.P:{" "}
                                  <span
                                    style={{
                                      textDecorationLine: "line-through",
                                      fontSize: "12px",
                                    }}
                                  >
                                    ₹{item?.amount}
                                  </span>{" "}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    ({item?.discount}%)
                                  </span>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
          </Row>
        </Col>
      </div>
    </div>
  );
};

export default Booksection;
