import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Aos from "aos";
import { FaFacebookF, FaInstagram, FaStar, FaWhatsapp } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import {
  IoIosGitBranch,
  IoMdArrowForward,
  IoMdHappy,
  IoMdPerson,
} from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";
import "../Home/Home.css";
import { MdOutlineAccessTime, MdOutlineArrowRightAlt } from "react-icons/md";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import Mobileapp from "./mobileapp";
import axios from "axios";
import Slider from "react-slick";
import moment from "moment";

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 1,
      },
    },
  };
  const option = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [user, setuser] = useState([]);
  const [Blogs, setBlogs] = useState([]);

  useEffect(() => {
    getalluser();
    getBlogs();
  }, []);

  const getalluser = () => {
    axios
      .get("https://relentlessyou.live/api/user/getalluser")
      .then(function (response) {
        console.log(response.data.user);
        setuser(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBlogs = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBlogs")
      .then(function (response) {
        // console.log(response.data);
        setBlogs(response.data.BlogsList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f37261",
          padding: "15px 26px 15px 26px",
          // marginTop: "-86px",
        }}
      >
        <div className="container">
          <div className="row pt-5 me-0">
            <div
              className="col-md-6 jnhuiujuih_000 mt-5"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <p style={{ color: "white" }}>Relentless You</p>
              <p>Life and</p>
              <p>Executive Coach</p>
              <p>
                “Look for the Light- It’s there, You will find it once you look
                beyond your limited horizon”
              </p>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-once="false"
            >
              <div style={{ position: "relative" }}>
                <img
                  src="../Img/bg_1.png"
                  alt=""
                  style={{ width: "90%", height: "auto" }}
                  className="jujgutfu_0000"
                />
                <div
                  style={{ position: "absolute", top: "10rem", left: "46px" }}
                  className="cdrdrtd"
                >
                  <img
                    src="../Image/img1.jpg"
                    alt=""
                    style={{
                      width: "80%",
                      borderRadius: "50%",
                      height: "350px",
                    }}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      {/* About us */}
      <div>
        <div
          style={{
            backgroundColor: "#F7F7F7",
            padding: "16px 20px 16px 20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          <div className="hvetfv">
            <div style={{ position: "absolute", top: "-85px", left: "0px" }}>
              <img src="../Img/39.png" alt="" />
            </div>
            <div style={{ position: "absolute", top: "-85px", right: "0px" }}>
              <img src="../Img/40.png" alt="" />
            </div>
            <div
              style={{ position: "absolute", top: "30.5rem", left: "0px" }}
              className="ggctrdr"
            >
              <img src="../Img/43.png" alt="" />
            </div>
            <div
              style={{ position: "absolute", top: "30.5rem", right: "0px" }}
              className="ggctrdr"
            >
              <img src="../Img/45.png" alt="" />
            </div>
          </div>
          <div className="container">
            <div className="row me-0">
              <div className="col-md-12 text-center">
                <p className="biuggbui">About Us</p>
                <div className="iuyiuyf"></div>
              </div>

              <div
                className="col-md-6 jeheuih m-auto"
                data-aos="fade-right"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out"
              >
                <p>Welcome to Relentless You</p>
                <p>
                  "My mission is to empower. Empower the professional, empower
                  the student, the housewife, the child – to relentlessly power
                  on, no matter how grave the circumstances. It’s so important
                  for people to understand that they define their success. Not
                  their circumstances"
                </p>
                <br />
                <p>
                  When you become aware of your connections with the important
                  people in your life you start to see what makes all
                  connections and interactions unique. To be able to witness
                  life through the eyes of a daughter, a mother, a wife, the
                  Life Coach, the trainer, the hypnotherapist, truly gives a
                  grand perspective on paths we may take for granted. The
                  learning is relentless.
                </p>
                <div className="hhhhk_000 huuiugg mt-2">
                  <button onClick={() => window.location.assign("aboutus")}>
                    Know More{" "}
                    <span>
                      <MdOutlineArrowRightAlt />
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="col-md-6"
                data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out"
              >
                <img
                  src="/Img/44 (1).png"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row me-0">
          <div className="col-md-12 mb-2">
            <p className="biuggbui">Our Meditations</p>
            <div className="iuyiuyf"></div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url('../Img/56.png')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "550px",
          position: "relative",
        }}
      >
        <div className="container p-4">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
          <div
            className="row me-0"
            style={{ zIndex: "9999", position: "relative" }}
          >
            <OwlCarousel className="owl-theme" loop margin={10} nav {...option}>
              <div>
                <div className="containerrrr">
                  <Card style={{ width: "21rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Img/47.png"
                      style={{ height: "275px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title>Balance Your Chakras</Card.Title>
                      <Card.Text>
                        Chakras are various focal points used in a variety of
                        ancient meditation practices.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <a href="/chakras">
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          border: "1px solid white",
                          backgroundColor: "#fff",
                          padding: "10px",
                          borderRadius: "50%",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="containerrrr">
                  <Card style={{ width: "21rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Img/48.png"
                      style={{ height: "275px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title>Meditations For the Soul</Card.Title>
                      <Card.Text>
                        Traditionally, meditation has been associated with
                        eastern mysticism .
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <a href="/maditationsoul">
                      {" "}
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          border: "1px solid white",
                          backgroundColor: "#fff",
                          padding: "10px",
                          borderRadius: "50%",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="containerrrr">
                  <Card style={{ width: "21rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Img/49.png"
                      style={{ height: "275px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title>Meditations For the Children</Card.Title>
                      <Card.Text>
                        Children, on the other hand, are a whole lot more
                        resilient than us adults.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <a href="/maditation1" style={{ textDecoration: "none" }}>
                      {" "}
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          border: "1px solid white",
                          backgroundColor: "#fff",
                          padding: "10px",
                          borderRadius: "50%",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="containerrrr">
                  <Card style={{ width: "21rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Img/50.png"
                      style={{ height: "275px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title>Music Therapy</Card.Title>
                      <Card.Text>
                        It has been said that there is a frequency that is
                        around us at all times. Vibrations.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <a href="/musicaltherapy">
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          border: "1px solid white",
                          backgroundColor: "#fff",
                          padding: "10px",
                          borderRadius: "50%",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="containerrrr">
                  <Card style={{ width: "21rem" }}>
                    <Card.Img
                      variant="top"
                      src="../Img/51.png"
                      style={{ height: "275px" }}
                    />
                    <Card.Body
                      className="text-center"
                      // style={{ zIndex: "99999" }}
                    >
                      <Card.Title>Hello Holistics Happyness</Card.Title>
                      <Card.Text>
                        It has been said that, “Everything in your life is a
                        reflection of a choice.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className="caption">
                    <a href="/helloholistic">
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          border: "1px solid white",
                          backgroundColor: "#fff",
                          padding: "10px",
                          borderRadius: "50%",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>

      {/* Counts */}
      <div style={{ backgroundColor: "#F47763" }} className="mt-3">
        <div className="container py-4">
          <div className="box">
            <div className="row me-0">
              <div className="col-md-6 box-1 text-center">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-12 hhhhk_0000">
                      <IoMdHappy />
                    </div>
                    <div className="col-md-12">
                      <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                      >
                        <p
                          style={{
                            // fontFamily: "Cinzel Decorative",
                            fontSize: "40px",
                            fontWeight: "500",
                            color: "#fff",
                          }}
                        >
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={user?.length}
                              duration={2}
                              delay={0}
                            />
                          )}
                          +
                        </p>
                      </ScrollTrigger>
                      {/* <p
                        style={{
                          // fontFamily: "Cinzel Decorative",
                          fontSize: "40px",
                          fontWeight: "500",
                          color: "#fff",
                        }}
                      >
                        +
                      </p> */}
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          marginTop: "-20px",
                        }}
                      >
                        Happy Customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 box-1 text-center">
                <div className="container ">
                  <div className="row me-0">
                    <div className="col-md-12 hhhhk_0000">
                      <MdOutlineAccessTime />
                    </div>
                    <div className="col-md-12">
                      <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                      >
                        <p
                          style={{
                            // fontFamily: "Cinzel Decorative",
                            fontSize: "40px",
                            fontWeight: "500",
                            color: "#fff",
                          }}
                        >
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={10}
                              duration={2}
                              delay={0}
                            />
                          )}
                          +
                        </p>
                      </ScrollTrigger>
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "-20px",
                          color: "#fff",
                        }}
                      >
                        Years Experience
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 box-1 text-center">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-12 hhhhk_0000">
                      <IoMdPerson />
                    </div>
                    <div className="col-md-12">
                      <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                      >
                        <p
                          style={{
                            // fontFamily: "Cinzel Decorative",
                            fontSize: "40px",
                            fontWeight: "500",
                            color: "#fff",
                          }}
                        >
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={26}
                              duration={2}
                              delay={0}
                            />
                          )}
                          +
                        </p>
                      </ScrollTrigger>
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "-20px",
                          color: "#fff",
                        }}
                      >
                        Expert Trainers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center box-2 ">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-12 hhhhk_0000">
                      <IoIosGitBranch />
                    </div>
                    <div className="col-md-12">
                      <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                      >
                        <p
                          style={{
                            // fontFamily: "Cinzel Decorative",
                            fontSize: "40px",
                            fontWeight: "500",
                            color: "#fff",
                          }}
                        >
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={15}
                              duration={2}
                              delay={0}
                            />
                          )}
                          +
                        </p>
                      </ScrollTrigger>
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "-20px",
                          color: "#fff",
                        }}
                      >
                        Total Branch
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Blog Post */}
      <div className="container my-3">
        <div className="row me-0 text-center">
          <div className="col-md-12 ">
            <p className="biuggbui">Blogs</p>
            <div className="iuyiuyf"></div>
          </div>
          <div className="col-md-12 text-center oijijo">
            <p>Our News Feed</p>
            <p>
              Meditation is a way for nourishing and blossoming the divinity
              within you.
            </p>
          </div>
        </div>
      </div>

      <Slider {...settings}>
        {Blogs?.map((blog, index) => (
          <div
            className="col-md-3"
            data-aos="fade-up"
            data-aos-duration="4000"
            data-aos-easing="ease-in-out"
            // key={index}
          >
            <div className=" oiuoiu hhhhk_00000 kjbiugui1">
              <Card style={{ width: "17rem", height: "480px" }}>
                <Card.Img variant="top" />
                <div className="guyyytrts landing-image-text-overlay">
                  <a href="" className="landing-image-text-overlay-link">
                    <img
                      src={
                        "https://relentlessyou.live/Blogs/" + blog?.BlogsImage
                      }
                      alt=""
                      style={{ height: "250px", width: "100%" }}
                    />
                  </a>
                </div>
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "1.2rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "clip",
                    }}
                  >
                    {blog?.BlogsHeader}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "1.1rem" }}>
                    <p>
                      <strong>Posted on :</strong>{" "}
                      {moment(blog?.createdAt).format("MMM Do YY")}
                    </p>
                    <p className="truncate">{blog?.BlogsText}</p>
                  </Card.Text>
                  <button
                    variant="primary"
                    onClick={handleShow}
                    style={{
                      textDecoration: "none",
                      background: "none",
                      border: "none",
                      color: "#F57E41",
                      fontSize: "20px",
                      fontStyle: "bold",
                      cursor: "pointer",
                      bottom: "10px",
                      position: "fixed",
                    }}
                  >
                    Learn More <IoMdArrowForward />
                  </button>
                </Card.Body>
              </Card>
            </div>
          </div>
        ))}
      </Slider>
      <div className="col-md-12 text-center mt-5 mb-5">
        <div className="hhhhk_000">
          <button
            onClick={() => {
              navigate("/blog");
            }}
          >
            View All Blogs{" "}
            <span>
              <GoArrowRight />
            </span>
          </button>
        </div>
      </div>

      <Mobileapp />
      <div
        style={{
          backgroundColor: "#F7F7F7",
          padding: "16px 20px 16px 20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div className="hvetfv">
          <div style={{ position: "absolute", top: "-75px", left: "0px" }}>
            <img src="../Img/39.png" alt="" style={{ width: "120px" }} />
          </div>
          <div style={{ position: "absolute", top: "-75px", right: "0px" }}>
            <img src="../Img/40.png" alt="" style={{ width: "120px" }} />
          </div>
        </div>
        <div className="container mb-5">
          <div className="row me-0 mt-5">
            <div className="col-md-4">
              <img
                // src="../Img/88.png"
                src="../Image/img13.png"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </div>
            <div className="col-md-8 m-auto" style={{ display: "grid" }}>
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 30,
                }}
              >
                Mindfulness And Your Child -{" "}
                <span
                  style={{
                    lineHeight: "1.6",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 30,
                    color: "#f37261",
                  }}
                >
                  A Parent's Guide
                </span>
              </p>
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "center",
                }}
                className="fs-5"
              >
                A self-help book for Parents that especially works with self
                Awareness in the most nascent years of a child's development
                keeping relentless improvement in mind.
              </p>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  border: "none",
                  padding: "10px 30px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "150px",
                }}
                // onClick={handleShow}
                onClick={() => window.location.assign("/Booksnew")}
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      {/* <div
        style={{ backgroundColor: "#FFEEEB", padding: "56px 25px 56px 25px" }}
      >
        <div className="container my-3">
          <div className="row text-center">
            <div className="col-md-6 kjhjhoi">
              <p>Happy Customers</p>
              <div className="iuyiuyf"></div>

              <div className="oijijo">
                <p>What Our Clients Says</p>
                <p className="fs-6">
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                  Ut Enim Ad Minim Veniam.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container mb-3">
                <div className="container">
                  <div className="row kjbiugui">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      nav
                      {...options}
                    >
                      <div>
                        <Card
                          style={{
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            height: "275px",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src="../Img/36.png"
                            style={{
                              width: "20%",
                              borderRadius: "50%",
                              margin: "auto",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ color: "#f37261" }}>
                              Terrence Baker
                            </Card.Title>
                            <Card.Text>
                              <small>Trainee</small>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, do ut labore magna aliqua.
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <Card
                          style={{
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            height: "275px",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src="../Img/36.png"
                            style={{
                              width: "20%",
                              borderRadius: "50%",
                              margin: "auto",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ color: "#f37261" }}>
                              Terrence Baker
                            </Card.Title>
                            <Card.Text>
                              <small>Trainee</small>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, do ut labore magna aliqua.
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <Card
                          style={{
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            height: "275px",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src="../Img/36.png"
                            style={{
                              width: "20%",
                              borderRadius: "50%",
                              margin: "auto",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ color: "#f37261" }}>
                              Terrence Baker
                            </Card.Title>
                            <Card.Text>
                              <small>Trainee</small>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, do ut labore magna aliqua.
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <Card
                          style={{
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            height: "275px",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src="../Img/36.png"
                            style={{
                              width: "20%",
                              borderRadius: "50%",
                              margin: "auto",
                            }}
                          />
                          <Card.Body>
                            <Card.Title style={{ color: "#f37261" }}>
                              Terrence Baker
                            </Card.Title>
                            <Card.Text>
                              <small>Trainee</small>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, do ut labore magna aliqua.
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p>What Our Clients Say</p>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Asperiores eligendi accusamus quasi in laudantium dolorum libero
              blanditiis quidem voluptatibus.
            </p>
          </div>
        </div>
      </div> */}

      {/* Plans
      <div style={{ backgroundColor: "#F6F6F6" }} className="pb-5">
        <div className="container pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 kjhjhoi">
                <p>Choose Your Plan</p>
                <div className="iuyiuy"></div>
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-md-7 iuio"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <p>Yoga Is Like A Journey To The Center Of Self</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 iouopiu">
                <p>
                  {" "}
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                  Ut Enim Ad Minim Veniam.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">STANDARD</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 3000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button onClick={() => handleShow()}>
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">PREMIUM</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 6000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button onClick={() => handleShow()}>
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 oiuoiu hhhhk_0000 oihqoih">
              <Card style={{ width: "22rem", borderRadius: "unset" }}>
                <Card.Body className="text-center">
                  <Card.Title className="text-center">PLANTIUM</Card.Title>
                  <Card.Text>
                    <ul className="list">
                      <li>₹ 3000</li>
                      <li>Per Month</li>
                      <li>Limited Training Hours</li>
                      <li>No Cancel At Any Time</li>
                      <li>No Hidden Fees</li>
                      <li>No Ancillaries Access</li>
                    </ul>
                    <div className="hhhhk_000">
                      <button onClick={() => handleShow()}>
                        Get Started{" "}
                        <span>
                          <GoArrowRight />
                        </span>
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div> */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#F58243" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#F58243" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Home;
