import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";

function Verifyotp() {
  const [optshow, setoptshow] = useState(false);
  const [OTP, setOTP] = useState("");

  const email = JSON.parse(sessionStorage.getItem("email"));

  const Otpverify = async (e) => {
    if (!OTP) {
      alert("Please Enter OTP sent to your registered Email ID");
    } else {
      try {
        const config = {
          url: "/VerifyOtp",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
            otp: OTP,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data.success);
          alert("OTP verified successfully");
          sessionStorage.setItem("data", JSON.stringify(res.data.success));
          window.location.assign("/Changepassword");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const sendOtp = async (e) => {
    try {
      const config = {
        url: "/sendOtp",
        method: "post",
        baseURL: "https://relentlessyou.live/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data.success);
        alert("OTP sent to your registered email Id");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  console.log("email", email, OTP);
  return (
    <>
      <div className="maindiv">
        <div className="firstdiv">
          <img src="/Image/logo.png" />
        </div>
        <div className="seconddiv">
          <p className="forgotpassword-header">Verifyotp</p>
          <p style={{ color: "#f37261", textAlign: "center" }}>
            Enter the OTP sent your Email ID to change the password
          </p>
          <div className="mt-5">
            <div style={{ display: "flex" }}>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="text"
                disabled={false}
                secure={optshow ? false : true}
              />
              {optshow ? (
                <button
                  onClick={() => setoptshow(!optshow)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    // marginLeft: "-27px",
                    // paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </button>
              ) : (
                <button
                  onClick={() => setoptshow(!optshow)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    // marginLeft: "-27px",
                    // paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </button>
              )}
            </div>
            <ResendOTP className="mt-3" onResendClick={sendOtp} />
          </div>
          <button className="login-btn" onClick={Otpverify}>
            Verify
          </button>
        </div>
      </div>
    </>
  );
}

export default Verifyotp;
