import axios from "axios";
import React, { useEffect, useState } from "react";

function Forgotpassword() {
  const [email, setemail] = useState("");

  const sendOtp = async (e) => {
    if (!email) {
      alert("Please Enter registered Email ID");
    } else {
      try {
        const config = {
          url: "/sendOtp",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data.success);
          alert("OTP sent to your registered email Id");
          sessionStorage.setItem("email", JSON.stringify(res.data.success));
          window.location.assign("/Verifyotp");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      <div className="maindiv">
        <div className="firstdiv">
          <img src="/Image/logo.png" />
        </div>
        <div className="seconddiv">
          <p className="forgotpassword-header">Forgotpassword</p>
          <p style={{ color: "#f37261", textAlign: "center" }}>
            Enter your Email ID to get OTP
          </p>
          <div className="mt-5" style={{ marginLeft: "10%" }}>
            <label className="login-label">Email ID *</label>
            <br></br>
            <input
              className="login-input"
              placeholder="Email ID"
              type="text"
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          <button className="login-btn" onClick={sendOtp}>
            Send
          </button>
        </div>
      </div>
    </>
  );
}

export default Forgotpassword;
