import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const AdminLogout = () => {
    sessionStorage.removeItem("admin");
    window.location.assign("/admin");
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f37261",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ backgroundColor: "white" }}>
          <img
            src="/Image/logo.png"
            style={{ width: "250px", height: "84px" }}
          />
        </div>
        <div style={{ backgroundColor: "#fffeea", padding: "10px 20px" }}>
          <button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{
              color: "#3b6f7c",
              backgroundColor: "#fffeea",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {admin?.name}
          </button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem style={{ fontSize: "14px" }}>My Profile</MenuItem> */}
            <MenuItem style={{ fontSize: "14px" }} onClick={AdminLogout}>
              Log out
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}

export default Header;
