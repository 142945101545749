import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/bh.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "500px",
          position: "relative",
        }}
      >
        <div className="container kjhkhhh">
          <div className="row">
            <div className="col-md-12 text-center ojjjpoiiii">
              <p data-aos="fade-down" data-aos-duration="2000">
                FAQ
              </p>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>

      <div className="container my-4">
        <div className="container-fluid">
          <div className="row mt-5 text-center">
            <div className="col-md-2">
              <img src="/Img/blog-left.png" alt="" style={{ width: "30%" }} />
            </div>
            <div className="col-md-8 kjhjhoiii">
              <p>FAQ</p>
              <div className="iuyiuyyyy text-center"></div>
            </div>
            <div className="col-md-2">
              <img
                src="/Img/blog-right.png"
                alt=""
                style={{ width: "10%", marginLeft: "3.5rem" }}
              />
              <br />
              <img
                src="/Img/blog-right.png"
                alt=""
                style={{ width: "24%", marginTop: "0.5rem" }}
              />
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-md-12 iuio"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <p>Now It’s Your Turn</p>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-md-12 iouopiu"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <p>
                {" "}
                Our Managed Hosting Guarantees Unmatched Performance,
                Reliability And Choice With 24/7 Support.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0 mt-5">
                <Accordion.Header>
                  How you love an amazing yoga quote?
                </Accordion.Header>
                <Accordion.Body>
                  I love an amazing yoga quote! They are profound in a
                  comforting and inspiring way. Each one feels poetic and they
                  have the ability to turn any day around..
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How the beauty of yoga?</Accordion.Header>
                <Accordion.Body>
                  That’s the beauty of yoga. The lessons and moments you
                  experience on your mat echo into your day. This compilation of
                  the best yoga quotes is for you to find inspiration in your
                  yoga practice and to carry that inspiration into your life.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How Naturally, it is a yoga saying?
                </Accordion.Header>
                <Accordion.Body>
                  I got my first tattoo a few years ago. Naturally, it is a yoga
                  saying. ‘sthira sukham’ means ‘steady and joyful’. The words
                  etched onto me remind me to find this balance, not only in my
                  practice but in my life too..
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
