import React, { useState } from "react";
import "../Contactuss/Contactuss.css";
import { IoIosHome } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import axios from "axios";

const Contactuss = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [message, setmessage] = useState("");

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const addContactus = async () => {
    if (!name || !email || !mobile || !message) {
      alert("Please fill all the fields");
    } else {
      try {
        if (validatename(name) && ValidateEmail(email) && phonenumber(mobile)) {
          const config = {
            url: "/user/addContactus",
            method: "post",
            baseURL: "https://relentlessyou.live/api",
            // headers: {'content-type': 'application/json'},
            data: {
              userID: "",
              message: message,
              userName: name,
              UserEmail: email,
              UserContactNumber: mobile,
            },
          };
          let res = await axios(config);
          // console.log('data', config);
          if (res.status === 200) {
            alert("Message sent successfull, will getback to you ASAP");
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  console.log("szcs", name, email, mobile, message);

  return (
    <div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 kjhjhoi">
            <p>Get In Touch</p>
            <div className="iuyiuy"></div>
            <p
              style={{
                color: "Black",
                marginTop: "30px",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              Stay Connected, Stay Informed
            </p>
          </div>
          <div className="row kknkjhtgg mt-4">
            <div className="col-md-6">
              <p>
                <span>
                  <MdEmail
                    style={{
                      fontSize: "35px",
                      padding: "5px",
                      borderRadius: "50%",
                      backgroundColor: "#f37261",
                      color: "white",
                      marginRight: "15px",
                    }}
                  />
                </span>
                <span className="fs-5">info@relentless-you.com</span>
              </p>
              {/* <p>
                <span>
                  <FaPhoneAlt
                    style={{
                      fontSize: "35px",
                      padding: "5px",
                      borderRadius: "50%",
                      backgroundColor: "#f37261",
                      color: "white",
                      marginRight: "15px",
                    }}
                  />
                </span>
                <span className="fs-5">+91-0000000000</span>
              </p> */}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 ">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Your Name"
                    className="vi_0"
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name=""
                    id=""
                    placeholder="Enter Your Email"
                    className="vi_0"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <input
                  type="tele"
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Your Phone Number"
                  className="vi_0"
                  onChange={(e) => setmobile(e.target.value)}
                />
              </div>
              <div className="col-md-12 mt-3">
                <textarea
                  name=""
                  className="vi_0"
                  id=""
                  cols="30"
                  rows="10"
                  style={{ height: "100px" }}
                  placeholder="Enter Your Address"
                  onChange={(e) => setmessage(e.target.value)}
                ></textarea>
              </div>
              <div className=" text-center mt-3">
                <div className="hhhhk_000">
                  <button onClick={addContactus}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5464675032335!2d-0.12209412427053895!3d51.503189711011956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2sin!4v1701776763358!5m2!1sen!2sin"
              style={{ width: "100%", height: "450px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Contactuss;
