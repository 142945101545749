import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { display } from "@mui/system";

const Maditationfaqq = (props) => {
  let { id, question, ans } = props;
  let [state, setState] = useState(true);

  let handleClick = () => {
    setState(!state);
  };
  return (
    <div>
      <p
        onClick={handleClick}
        style={{
          cursor: "pointer",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "10px 16px ",
          backgroundColor: "#f37261",
        }}
      >
        <span className="fs-4">{question}</span>
        <span>
          {state ? (
            <KeyboardArrowDownIcon style={{ color: " #fff" }} />
          ) : (
            <KeyboardArrowUpIcon style={{ color: " #fff" }} />
          )}
        </span>
      </p>
      <div
        style={{
          backgroundColor: "#000",
          padding: "0px 16px",
          color: "#fff",
          position: "relative",
          zIndex: "9999",
        }}
      >
        {state ? " " : <p className="fs-5">{ans}</p>}
      </div>
    </div>
  );
};

export default Maditationfaqq;
