import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";

function Books() {
  const formdata = new FormData();
  const Input = styled("input")({
    display: "none",
  });

  const [data, setdata] = useState([]);
  const [Bookscategory, setBookscategory] = useState([]);

  const [Booksimg, setBooksimg] = useState("");
  const [samplepdf, setsamplepdf] = useState("");
  const [ebook, setebook] = useState("");
  const [name, setname] = useState("");
  const [catname, setcatname] = useState("");
  const [amount, setamount] = useState();
  const [discount, setdiscount] = useState();
  const [authorname, setauthorname] = useState("");
  const [discription, setdiscription] = useState("");
  const [aboutauthor, setaboutauthor] = useState("");
  const [distributorlink, setdistributorlink] = useState("");

  const handleEditordiscription = (event, editor) => {
    const data = editor.getData();
    setdiscription(data);
  };

  const handleEditoraboutauthor = (event, editor) => {
    const data = editor.getData();
    setaboutauthor(data);
  };

  const [Booksimg1, setBooksimg1] = useState("");
  const [samplepdf1, setsamplepdf1] = useState("");
  const [ebook1, setebook1] = useState("");
  const [name1, setname1] = useState("");
  const [catname1, setcatname1] = useState("");
  const [amount1, setamount1] = useState();
  const [discount1, setdiscount1] = useState();
  const [authorname1, setauthorname1] = useState("");
  const [discription1, setdiscription1] = useState("");
  const [aboutauthor1, setaboutauthor1] = useState("");
  const [distributorlink1, setdistributorlink1] = useState("");

  const handleEditordiscription1 = (event, editor) => {
    const data = editor?.getData();
    setdiscription1(data);
  };

  const handleEditoraboutauthor1 = (event, editor) => {
    const data = editor?.getData();
    setaboutauthor1(data);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    seteditdata(data);
    setShow1(true);
  };

  const [show3, setShow3] = useState(false);
  const [pdfdata, setpdfdata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setpdfdata(data);
  };

  const [show4, setShow4] = useState(false);
  const [epdfdata, setepdfdata] = useState({});
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setShow4(true);
    setepdfdata(data);
  };

  const [show5, setShow5] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (data) => {
    setShow5(true);
    setdeletedata(data);
  };

  useEffect(() => {
    getBooks();
    getBookscategory();
  }, []);

  const getBookscategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBookscategory")
      .then(function (response) {
        console.log(response.data);
        setBookscategory(response.data.BookscategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addBooks = async () => {
    if (
      !catname ||
      !name ||
      !amount ||
      !discount ||
      !authorname ||
      !discription ||
      !aboutauthor ||
      !distributorlink ||
      !Booksimg ||
      !samplepdf ||
      !ebook
    ) {
      alert("Please fill all the fields");
    } else {
      formdata.append("catname", catname);
      formdata.append("name", name);
      formdata.append("amount", amount);
      formdata.append("discount", discount);
      formdata.append("authorname", authorname);
      formdata.append("discription", discription);
      formdata.append("aboutauthor", aboutauthor);
      formdata.append("distributorlink", distributorlink);
      formdata.append("Booksimg", Booksimg);
      formdata.append("samplepdf", samplepdf);
      formdata.append("ebook", ebook);
      try {
        const config = {
          url: "/addBooks",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    }
  };

  const getBooks = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBooks")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.BooksList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editBooks = async () => {
    formdata.append("catname", catname1 ? catname1 : editdata?.catname);
    formdata.append("name", name1 ? name1 : editdata?.name);
    formdata.append("amount", amount1 ? amount1 : editdata?.amount);
    formdata.append("discount", discount1 ? discount1 : editdata?.discount);
    formdata.append(
      "authorname",
      authorname1 ? authorname1 : editdata?.authorname
    );
    formdata.append(
      "discription",
      discription1 ? discription1 : editdata?.discription
    );
    formdata.append(
      "aboutauthor",
      aboutauthor1 ? aboutauthor1 : editdata?.aboutauthor
    );
    formdata.append(
      "distributorlink",
      distributorlink1 ? distributorlink1 : editdata?.distributorlink
    );
    formdata.append("Booksimg", Booksimg1 ? Booksimg1 : editdata?.Booksimg);
    formdata.append("samplepdf", samplepdf1 ? samplepdf1 : editdata?.samplepdf);
    formdata.append("ebook", ebook1 ? ebook1 : editdata?.ebook);
    try {
      const config = {
        url: "/editBooks/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getBooks();
          handleClose1();
          alert("Data updated successfully");
          // window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getBooks();
      alert(error.response.data.error);
    }
  };

  const deleteBooks = async () => {
    try {
      const config = {
        url: "/deleteBooks/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose5();
          getBooks();
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const newdata = [];

  for (let i = 0; i < data.length; i++) {
    newdata.push({
      Slno: i + 1,
      _id: data[i]?._id,
      Booksimg: data[i]?.Booksimg,
      catname: data[i]?.catname,
      name: data[i]?.name,
      authorname: data[i]?.authorname,
      reviews:
        data[i]?.reviews?.length > 0
          ? data[i]?.reviews?.reduce((a, rev) => a + Number(rev.rating), 0)
          : "-",
      amount: data[i]?.amount,
      discount: data[i]?.discount,
      distributorlink: data[i]?.distributorlink,
      // discription: data[i]?.discription ? parse(data[i]?.discription) : "-",
      // aboutauthor: data[i]?.aboutauthor ? parse(data[i]?.aboutauthor) : "-",
      discription: data[i]?.discription,
      aboutauthor: data[i]?.aboutauthor,
      samplepdf: data[i]?.samplepdf,
      ebook: data[i]?.ebook,
      createdAt: moment(data[i]?.createdAt).format("DD/MM/YYYY"),
    });
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "Slno",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{row?.Slno}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (value, row) => <p>{row?.createdAt ? row?.createdAt : "-"}</p>,
    },
    {
      dataField: "Booksimg",
      text: "Image",
      formatter: (value, row) =>
        row?.Booksimg ? (
          <img
            src={"https://relentlessyou.live/Books/" + row.Booksimg}
            width={100}
            height={80}
            onClick={() =>
              window.open("https://relentlessyou.live/Books/" + row.Booksimg)
            }
          />
        ) : (
          "-"
        ),
    },

    {
      dataField: "catname",
      text: "Category Name",
      formatter: (value, row) => <p>{row?.catname ? row?.catname : "-"}</p>,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "authorname",
      text: "Author name",
      formatter: (value, row) => (
        <p>{row?.authorname ? row?.authorname : "-"}</p>
      ),
    },
    {
      dataField: "reviews",
      text: "Ratings",
      formatter: (value, row) => <p>{row?.reviews ? row?.reviews : "-"}</p>,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (value, row) => <p>{row?.amount ? row?.amount : "-"}</p>,
    },
    {
      dataField: "discount",
      text: "Discount %",
      formatter: (value, row) => <p>{row?.discount ? row?.discount : "-"}</p>,
    },
    {
      dataField: "distributorlink",
      text: "Distributor link",
      formatter: (value, row) => (
        <p
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            width: "150px",
            height: "100px",
          }}
        >
          {row?.distributorlink ? row?.distributorlink : "-"}
        </p>
      ),
    },
    {
      dataField: "discription",
      text: "Description",
      formatter: (value, row) => (
        <p
          style={{
            overflowY: "scroll",
            height: "100px",
          }}
        >
          {row?.discription ? parse(row?.discription) : "-"}
        </p>
      ),
    },
    {
      dataField: "aboutauthor",
      text: "About Author",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.aboutauthor ? parse(row.aboutauthor) : "-"}
        </p>
      ),
    },
    {
      dataField: "samplepdf",
      text: "Sample PDF",
      formatter: (value, row) =>
        row?.samplepdf ? (
          <>
            <img
              src={"https://cdn-icons-png.flaticon.com/128/202/202298.png"}
              alt="avathar"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
              onClick={() => handleShow3(row)}
            />
          </>
        ) : (
          "-"
        ),
    },
    {
      dataField: "ebook",
      text: "eBook",
      formatter: (value, row) =>
        row?.ebook ? (
          <>
            <img
              src={"https://cdn-icons-png.flaticon.com/128/202/202298.png"}
              alt="avathar"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
              onClick={() => handleShow4(row)}
            />
          </>
        ) : (
          "-"
        ),
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow5(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Books
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={newdata}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button>
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "900px" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add-Books</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>
                  Category <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setcatname(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>Select</option>
                  {Bookscategory?.map((cat) => (
                    <option value={cat?.name}>{cat?.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Amount <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  type="number"
                  min="0"
                  step="1"
                  placeholder="Amount"
                  onChange={(e) => setamount(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Discount %<span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  type="tele"
                  maxLength={2}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Discount"
                  onChange={(e) => setdiscount(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Author Name <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Author name"
                  onChange={(e) => setauthorname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Distributor link <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Distributor link"
                  onChange={(e) => setdistributorlink(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <label>
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <Row className="mt-3"></Row>
              <CKEditor
                editor={ClassicEditor}
                data={discription}
                onChange={handleEditordiscription}
              />
            </Row>
            <Row className="mt-3">
              <label>
                About author <span style={{ color: "red" }}>*</span>
              </label>
            </Row>
            <Row className="mt-3">
              <CKEditor
                editor={ClassicEditor}
                data={aboutauthor}
                onChange={handleEditoraboutauthor}
              />
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Image <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="image"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image"
                    type="file"
                    onChange={(e) => setBooksimg(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        Booksimg
                          ? URL.createObjectURL(Booksimg)
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Sample PDF <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="pdf"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept=".pdf"
                    id="pdf"
                    type="file"
                    onChange={(e) => setsamplepdf(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        samplepdf
                          ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  eBook <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="epdf"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept=".pdf"
                    id="epdf"
                    type="file"
                    onChange={(e) => setebook(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        ebook
                          ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addBooks}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Books</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <label>Category</label>
              </Col>
              <Col md={6}>
                <select
                  onChange={(e) => setcatname1(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>{editdata?.catname}</option>
                  {Bookscategory?.map((cat) => (
                    <option value={cat?.name}>{cat?.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.name}
                  onChange={(e) => setname1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Amount</label>
              </Col>
              <Col md={6}>
                <input
                  type="number"
                  placeholder={editdata?.amount}
                  onChange={(e) => setamount1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Discount %</label>
              </Col>
              <Col md={6}>
                <input
                  type="number"
                  placeholder={editdata?.discount}
                  onChange={(e) => setdiscount1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Author Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.authorname}
                  onChange={(e) => setauthorname1(e.target.value)}
                />
              </Col>
            </Row>
            {/* <Row className="mt-3">
              <Col md={6}>
                <label>Discription</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.discription}
                  onChange={(e) => setdiscription1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>About author</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.aboutauthor}
                  onChange={(e) => setaboutauthor1(e.target.value)}
                />
              </Col>
            </Row> */}
            <Row className="mt-3">
              <Col md={6}>
                <label>Distributor link</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.distributorlink}
                  onChange={(e) => setdistributorlink1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <label>Description</label>
              <Row className="mt-3"></Row>
              <CKEditor
                data={editdata?.discription}
                editor={ClassicEditor}
                onChange={handleEditordiscription1}
              />
            </Row>
            <Row className="mt-3">
              <label>About author</label>
            </Row>
            <Row className="mt-3">
              <CKEditor
                data={editdata?.aboutauthor}
                editor={ClassicEditor}
                onChange={handleEditoraboutauthor1}
              />
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <label>Image</label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="image"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image"
                    type="file"
                    onChange={(e) => setBooksimg1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        Booksimg1
                          ? URL.createObjectURL(Booksimg1)
                          : "https://relentlessyou.live/Books/" +
                            editdata?.Booksimg
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Sample PDF</label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="pdf"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept=".pdf"
                    id="pdf"
                    type="file"
                    onChange={(e) => setsamplepdf1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        samplepdf1
                          ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>eBook</label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="epdf"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept=".pdf"
                    id="epdf"
                    type="file"
                    onChange={(e) => setebook1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        ebook1
                          ? "https://cdn-icons-png.flaticon.com/128/202/202298.png"
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editBooks}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{pdfdata?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Books/" + pdfdata?.samplepdf}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{epdfdata?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Books/" + epdfdata?.ebook}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteBooks}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Books;
