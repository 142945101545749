import React, { useState } from "react";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Sidebar1() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  // const [subadmin, setsubadmin] = useState(false);
  // const [books, setbooks] = useState(false);
  // const [videolist, setvideolist] = useState(false);
  // const [audiolist, setaudiolist] = useState(false);
  // const [kidssection, setkidssection] = useState(false);
  // const [mp, setmp] = useState(false);
  // const [ma, setma] = useState(false);
  // const [podcast, setpodcast] = useState(false);
  // const [musictherapy, setmusictherapy] = useState(false);
  // const [subscription, setsubscription] = useState(false);

  console.log("admin", admin);
  return (
    <ProSidebarProvider>
      <Sidebar>
        <Menu>
          <MenuItem
            component={<Link to="/admin/dashbord" />}
            style={{ color: "white", fontWeight: "bold" }}
          >
            {/* <img
              src="https://cdn-icons-png.flaticon.com/128/610/610106.png"
              style={{ width: "30px", marginLeft: "20px", marginTop: "10px" }}
            /> */}
            Dashbord
          </MenuItem>
          {admin?.adminType === "Admin" ? (
            <SubMenu
              label="Sub-Admin"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
              // open={false}
              // onClick={() => {
              //   setsubadmin(true);
              //   setbooks(false);
              //   setvideolist(false);
              //   setaudiolist(false);
              //   setkidssection(false);
              //   setmp(false);
              //   setma(false);
              //   setpodcast(false);
              //   setmusictherapy(false);
              //   setsubscription(false);
              // }}
            >
              <MenuItem component={<Link to="/admin/Subadmincreate" />}>
                Creation
              </MenuItem>
              <MenuItem component={<Link to="/admin/Subadmindetails" />}>
                Details
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.user ? (
            <MenuItem
              component={<Link to="/admin/Userlist" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              User List
            </MenuItem>
          ) : (
            ""
          )}
          {admin?.Book ? (
            <SubMenu
              label="Books"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
              // open={books}
              // onClick={() => {
              //   setsubadmin(false);
              //   setbooks(true);
              //   setvideolist(false);
              //   setaudiolist(false);
              //   setkidssection(false);
              //   setmp(false);
              //   setma(false);
              //   setpodcast(false);
              //   setmusictherapy(false);
              //   setsubscription(false);
              // }}
            >
              <MenuItem component={<Link to="/admin/Bookscategory" />}>
                Add Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Books" />}>
                Add Books
              </MenuItem>
              <MenuItem component={<Link to="/admin/Booksaccounthistory" />}>
                Order or Account History
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.video ? (
            <SubMenu
              label="Video List"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Videocategory" />}>
                Add Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Addvideo" />}>
                Add Video
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.audio ? (
            <SubMenu
              label="Audio List"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/AudioCategory" />}>
                Add Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Addaudio" />}>
                Add Audio
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.kids ? (
            <SubMenu
              label="Kids Section"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Kidsbanners" />}>
                Banners
              </MenuItem>
              <MenuItem component={<Link to="/admin/Kidscategory" />}>
                Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Kidsaudio" />}>
                Audio
              </MenuItem>
              <MenuItem component={<Link to="/admin/Kidsvideo" />}>
                Video
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.Mpopular ? (
            <SubMenu
              label="Most Popular"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Mostpopularaudio" />}>
                Audio
              </MenuItem>
              <MenuItem component={<Link to="/admin/Mostpopularvideo" />}>
                Video
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.Mawaited ? (
            <SubMenu
              label="Most Awaited"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Awaitedaudio" />}>
                Audio
              </MenuItem>
              <MenuItem component={<Link to="/admin/Awaitedvideo" />}>
                Video
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}

          {admin?.podcast ? (
            <SubMenu
              label="Podcast List"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Podcastcategory" />}>
                Add Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Adpodcast" />}>
                Add Podcast
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.musictherapy ? (
            <SubMenu
              label="Music Therapy"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Musictherapistbanners" />}>
                Add Banners
              </MenuItem>
              <MenuItem component={<Link to="/admin/Musictherapistcategory" />}>
                Add Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/Musictherapistmusic" />}>
                Add Music
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}
          {admin?.snippets ? (
            <MenuItem
              component={<Link to="/admin/Snippet" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Snippet
            </MenuItem>
          ) : (
            ""
          )}
          {admin?.blog ? (
            <MenuItem
              component={<Link to="/admin/Blogpage" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Blog page
            </MenuItem>
          ) : (
            ""
          )}
          {admin?.subscription ? (
            <SubMenu
              label="Subscription"
              style={{
                color: "transparent",
                textShadow: "0 0 0 red",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MenuItem component={<Link to="/admin/Subscribecat" />}>
                Subscription Category
              </MenuItem>
              <MenuItem component={<Link to="/admin/SubscribePlan" />}>
                Subscription Plan
              </MenuItem>
              <MenuItem component={<Link to="/admin/Subscriberlist" />}>
                Subscriber List
              </MenuItem>
            </SubMenu>
          ) : (
            ""
          )}

          {/* <MenuItem
            component={<Link to="/admin/SubscribePlan" />}
            style={{ color: "white", fontWeight: "bold" }}
          >
            Subscribe Plan
          </MenuItem> */}
          {/* <MenuItem
            component={<Link to="/admin/Subscriberlist" />}
            style={{ color: "white", fontWeight: "bold" }}
          >
            Subscriber list
          </MenuItem> */}
          {admin?.accounts ? (
            <MenuItem
              component={<Link to="/admin/AccountHistory" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Account History
            </MenuItem>
          ) : (
            ""
          )}
          {admin?.contactus ? (
            <MenuItem
              component={<Link to="/admin/Contactus" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Contact-Us
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
      </Sidebar>
    </ProSidebarProvider>
  );
}

export default Sidebar1;
