import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";

import moment from "moment";

function AccountHistory() {
  const [newdata, setnewdata] = useState([]);
  const [start, setstart] = useState();
  const [end, setend] = useState();
  const [filterdata, setfilterdata] = useState([]);

  const data = [];

  useEffect(() => {
    getUsersubscribe();
  }, []);

  // useEffect(() => {
  //   const filtered = data?.filter((item) => {
  //     if (start && end) {
  //       return (
  //         moment(item?.createdAt).isSameOrAfter(moment(start), "day") &&
  //         moment(item?.createdAt).isSameOrBefore(moment(end), "day")
  //       );
  //     } else if (start) {
  //       return moment(item?.createdAt).isSameOrAfter(moment(start), "day");
  //     } else if (end) {
  //       return moment(item?.createdAt).isSameOrBefore(moment(end), "day");
  //     }
  //     return true; // Return true if neither start nor end date is set
  //   });

  //   setfilterdata(filtered);
  // }, [start, end]);

  useEffect(() => {
    const filtered = data?.filter((item) => {
      if (start && end) {
        return (
          item?.createdAt >= moment(start).format("DD/MM/YYYY") &&
          item?.createdAt <= moment(end).format("DD/MM/YYYY")
        );
      } else if (start) {
        return item?.createdAt >= moment(start).format("DD/MM/YYYY");
      } else if (end) {
        return (
          item?.createdAt <= item?.createdAt <= moment(end).format("DD/MM/YYYY")
        );
      }
    });

    setfilterdata(filtered);
  }, [start, end]);

  console.log("filterdata", filterdata);

  const getUsersubscribe = () => {
    axios
      .get("https://relentlessyou.live/api/user/getUsersubscribe")
      .then(function (response) {
        console.log(response.data.UsersubscribeList);
        setnewdata(response.data.UsersubscribeList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  for (let i = 0; i < newdata.length; i++) {
    data.push({
      SubscriptionID: newdata[i]?.SubscriptionID,
      Expire_date: newdata[i]?.expiredate,
      Amount: newdata[i]?.subscribeplans[0]?.Amount,
      Duration: newdata[i]?.subscribeplans[0]?.Duration,
      Name: newdata[i]?.subscribeplans[0]?.Name,
      Type: newdata[i]?.subscribeplans[0]?.Type,
      User_Name: newdata[i]?.user[0]?.name,
      User_Email: newdata[i]?.user[0]?.email,
      User_Contact_No: newdata[i]?.user[0]?.mobile,

      AudioVideo: newdata[i]?.subscribeplans[0]?.AudioVideo,

      Blogs: newdata[i]?.subscribeplans[0]?.Blogs,

      KidsSection: newdata[i]?.subscribeplans[0]?.KidsSection,

      MostAwaitedAudio: newdata[i]?.subscribeplans[0]?.MostAwaitedAudio,

      MostAwaitedVideo: newdata[i]?.subscribeplans[0]?.MostAwaitedVideo,

      MostPopularAudio: newdata[i]?.subscribeplans[0]?.MostPopularAudio,

      MostPopularVideo: newdata[i]?.subscribeplans[0]?.MostPopularVideo,

      MusicTherapy: newdata[i]?.subscribeplans[0]?.MusicTherapy,

      Podcast: newdata[i]?.subscribeplans[0]?.Podcast,

      Snippets: newdata[i]?.subscribeplans[0]?.Snippets,
      createdAt: moment(newdata[i]?.createdAt).format("DD/MM/YYYY"),
    });
  }

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Name",
      text: "Subscription Category",
      formatter: (value, row) => <p>{row?.Name ? row?.Name : "-"}</p>,
    },
    {
      dataField: "Type",
      text: "Subscription Type",
      formatter: (value, row) => <p>{row?.Type ? row?.Type : "-"}</p>,
    },
    {
      dataField: "User_Name",
      text: "Subscriber Name",
      formatter: (value, row) => <p>{row?.User_Name ? row?.User_Name : "-"}</p>,
    },
    {
      dataField: "User_Contact_No",
      text: "Subscriber Mobile No.",
      formatter: (value, row) => (
        <p>{row?.User_Contact_No ? row?.User_Contact_No : "-"}</p>
      ),
    },
    {
      dataField: "User_Email",
      text: "Subscriber Email ID",
      formatter: (value, row) => (
        <p>{row?.User_Email ? row?.User_Email : "-"}</p>
      ),
    },
    {
      dataField: "Amount",
      text: "Amount",
      formatter: (value, row) => <p>{row?.Amount ? row?.Amount : "-"}</p>,
    },
    {
      dataField: "createdAt",
      text: "Payment Date",
      formatter: (value, row) => <p>{row?.createdAt ? row?.createdAt : "-"}</p>,
    },
    {
      dataField: "-",
      text: "Payment ID",
      formatter: (value, row) => <p>{"-"}</p>,
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Account History
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={start && end ? filterdata : data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#f37261",
                      color: "white",
                      marginLeft: "10px",
                      height: "38px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Start Date</p>
                    <input
                      type="date"
                      onChange={(e) => setstart(e.target.valueAsDate)}
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>End Date</p>
                    <input
                      type="date"
                      onChange={(e) => setend(e.target.valueAsDate)}
                    />
                  </div>
                </div>
              </div>

              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
                <p style={{ textAlign: "right", fontWeight: "bold" }}>
                  Total Amount:{" "}
                  {start && end
                    ? filterdata?.reduce((a, item) => a + item?.Amount, 0)
                    : data?.reduce((a, item) => a + item?.Amount, 0)}
                </p>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default AccountHistory;
