import Aos from "aos";
import React, { useEffect, useInsertionEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { IoMdArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "./Blog/Blog.css";
import axios from "axios";
import Slider from "react-slick";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Mobileapp from "./Home/mobileapp";
import { FaDownload } from "react-icons/fa";
import "./Maditation/styles/Happy.css";

const Booksnew = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  window.scroll(0, 0);

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
          // backgroundAttachment: "fixed",
          // marginTop: "-86px",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh pt-5">
          <div className="row">
            <div className="col-md-12  ojjjpoiiii text-center">
              <p>Books</p>
              <span>
                <a href="/">Home/</a>
                <a href="">Books</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div
        style={{
          backgroundColor: "#F7F7F7",
          padding: "16px 20px 16px 20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div className="hvetfv">
          <div style={{ position: "absolute", top: "-75px", left: "0px" }}>
            <img src="../Img/39.png" alt="" style={{ width: "120px" }} />
          </div>
          <div style={{ position: "absolute", top: "-75px", right: "0px" }}>
            <img src="../Img/40.png" alt="" style={{ width: "120px" }} />
          </div>
        </div>
        <div className="container mb-5">
          <div className="row mt-5">
            <div className="col-md-4">
              <img
                // src="../Img/88.png"
                src="../Image/img13.png"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </div>
            <div className="col-md-8 m-auto" style={{ display: "grid" }}>
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 30,
                }}
              >
                Mindfulness And Your Child -{" "}
                <span
                  style={{
                    lineHeight: "1.6",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 30,
                    color: "#f37261",
                  }}
                >
                  A Parent's Guide
                </span>
              </p>
              <p
                style={{
                  lineHeight: "1.6",
                  textAlign: "center",
                }}
                className="fs-5"
              >
                A self-help book for Parents that especially works with self
                Awareness in the most nascent years of a child's development
                keeping relentless improvement in mind.
              </p>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  border: "none",
                  padding: "10px 30px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "150px",
                }}
                // onClick={handleShow}
                onClick={() => window.location.assign("booksection")}
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#F58243" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#F58243" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Booksnew;
