export let qa = [
  {
    id: 1,
    question: "Pick a Silent Place",
    ans: "A tranquil environment with fewer distractions allows better focus. Choose a place that is comfortable and lets you have an uninterrupted session. Also, try to maintain an upright posture while meditating.",
  },

  {
    id: 2,
    question: "Fix Time and Length",
    ans: "You can meditate at any anytime and for as long as you like. But usually, when we begin, our mind tends to get distracted a lot. Even five minutes feel like an hour. So to develop disciple and stop the mind from wandering a lot, set a fixed time and length of each session.",
  },

  {
    id: 3,
    question: "Close Your Eyes and Breathe",
    ans: "It is a fundamental way to get started. After settling down, close your eyes and take a deep breath. The basic idea here is to focus on the breathing pattern as you inhale and exhale so that you can focus your mind on something other than thinking. If it still wanders, try to pull it back in the process. ",
  },

  {
    id: 4,
    question: "How does meditation helps",
    ans: "There have been many studies conducted to analyze how does meditation help? And it has been found that meditation not only helps with destressing and attaining calmness but also with depression, anxiety, irritated bowel movements, memory loss, etc. According to one study, the effects of meditation can also help students with reducing stress and harboring mindfulness . In another study, researchers observed that adult individuals and communities showed better health and social interaction after following a meditation routine.",
  },
];
