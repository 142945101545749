import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";

import moment from "moment";

function Booksaccounthistory() {
  const [data, setdata] = useState([]);
  const [start, setstart] = useState();
  const [end, setend] = useState();
  const [filterdata, setfilterdata] = useState([]);

  const [show4, setShow4] = useState(false);
  const [epdfdata, setepdfdata] = useState({});
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setShow4(true);
    setepdfdata(data);
  };

  useEffect(() => {
    getBooksorder();
  }, []);

  const newdata = [];
  for (let i = 0; i < data.length; i++) {
    newdata.push({
      username: data[i]?.users[0]?.name,
      useremail: data[i]?.users[0]?.email,
      userontactnumber: data[i].users[0]?.mobile,
      Bookname: data[i].books[0]?.name,
      Bookscatname: data[i].books[0]?.catname,
      authorname: data[i].books[0]?.authorname,
      eBook: data[i].books[0]?.ebook,
      amount: data[i].books[0]?.amount,
      discount: data[i].books[0]?.discount,
      bookimg: data[i].books[0]?.Booksimg,
      payableamount:
        data[i].books[0]?.amount -
        (data[i].books[0]?.amount * data[i].books[0]?.discount) / 100,
      paymentId: data[i].paymentId,
      paymenttype: data[i].paymenttype,
      createdAt: moment(data[i]?.createdAt).format("DD/MM/YYYY"),
    });
  }

  useEffect(() => {
    const filtered = newdata?.filter((item) => {
      if (start && end) {
        return (
          item?.createdAt >= moment(start).format("DD/MM/YYYY") &&
          item?.createdAt <= moment(end).format("DD/MM/YYYY")
        );
      } else if (start) {
        return item?.createdAt >= moment(start).format("DD/MM/YYYY");
      } else if (end) {
        return (
          item?.createdAt <= item?.createdAt <= moment(end).format("DD/MM/YYYY")
        );
      }
    });

    setfilterdata(filtered);
  }, [start, end]);

  console.log("filterdata", filterdata);

  const getBooksorder = () => {
    axios
      .get("https://relentlessyou.live/api/user/getBooksorder")
      .then(function (response) {
        console.log("BooksorderList1", response.data.BooksorderList);
        setdata(response.data.BooksorderList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: newdata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "bookimg",
      text: "Image",
      formatter: (value, row) =>
        row?.bookimg ? (
          <img
            src={"https://relentlessyou.live/Books/" + row.bookimg}
            width={100}
            height={80}
            onClick={() =>
              window.open("https://relentlessyou.live/Books/" + row.bookimg)
            }
          />
        ) : (
          "-"
        ),
    },
    {
      dataField: "eBook",
      text: "eBook",
      formatter: (value, row) =>
        row?.eBook ? (
          <>
            <img
              src={"https://cdn-icons-png.flaticon.com/128/202/202298.png"}
              alt="avathar"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
              onClick={() => handleShow4(row)}
            />
          </>
        ) : (
          "-"
        ),
    },
    {
      dataField: "Bookscatname",
      text: "Category Name",
      formatter: (value, row) => (
        <p>{row?.Bookscatname ? row?.Bookscatname : "-"}</p>
      ),
    },
    {
      dataField: "Bookname",
      text: "Book Name",
      formatter: (value, row) => <p>{row?.Bookname ? row?.Bookname : "-"}</p>,
    },
    {
      dataField: "authorname",
      text: "Author name",
      formatter: (value, row) => (
        <p>{row?.authorname ? row?.authorname : "-"}</p>
      ),
    },
    {
      dataField: "amount",
      text: "Total Amount",
      formatter: (value, row) => <p>{row?.amount ? row?.amount : "-"}</p>,
    },
    {
      dataField: "discount",
      text: "Discount %",
      formatter: (value, row) => <p>{row?.discount ? row?.discount : "-"}</p>,
    },
    {
      dataField: "payableamount",
      text: "Payable Amount",
      formatter: (value, row) => (
        <p>{row?.payableamount ? row?.payableamount?.toFixed(2) : "-"}</p>
      ),
    },
    {
      dataField: "createdAt",
      text: "Payment Date",
      formatter: (value, row) => <p>{row?.createdAt ? row?.createdAt : "-"}</p>,
    },
    {
      dataField: "paymenttype",
      text: "Payment Type",
      formatter: (value, row) => (
        <p>{row?.paymenttype ? row?.paymenttype : "-"}</p>
      ),
    },

    {
      dataField: "paymentId",
      text: "Payment ID",
      formatter: (value, row) => <p>{row?.paymentId ? row?.paymentId : "-"}</p>,
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Books Account History
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={start && end ? filterdata : newdata}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#f37261",
                      color: "white",
                      marginLeft: "10px",
                      height: "38px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Start Date</p>
                    <input
                      type="date"
                      onChange={(e) => setstart(e.target.valueAsDate)}
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>End Date</p>
                    <input
                      type="date"
                      onChange={(e) => setend(e.target.valueAsDate)}
                    />
                  </div>
                </div>
              </div>

              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "900px" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
                <p style={{ textAlign: "right", fontWeight: "bold" }}>
                  Total Amount:{" "}
                  {start && end
                    ? filterdata?.reduce(
                        (a, item) => a + item?.payableamount,
                        0
                      )
                    : newdata?.reduce((a, item) => a + item?.payableamount, 0)}
                </p>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Modal show={show4} onHide={handleClose4} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{epdfdata?.Bookname}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Books/" + epdfdata?.eBook}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Booksaccounthistory;
