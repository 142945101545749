import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";

function Adpodcast() {
  const Input = styled("input")({
    display: "none",
  });

  const [data, setdata] = useState([]);
  const [podcastcat, setpodcastcat] = useState([]);

  const [Category, setCategory] = useState({});
  const [PodcastVideo, setPodcastVideo] = useState("");
  const [PodcastAudio, setPodcastAudio] = useState("");
  const [PodcastImage, setPodcastImage] = useState("");
  const [PodcastText, setPodcastText] = useState("");
  const [PodcastHeader, setPodcastHeader] = useState("");
  const [Type, setType] = useState("");

  const [Category1, setCategory1] = useState({});
  const [PodcastVideo1, setPodcastVideo1] = useState("");
  const [PodcastAudio1, setPodcastAudio1] = useState("");
  const [PodcastImage1, setPodcastImage1] = useState("");
  const [PodcastText1, setPodcastText1] = useState("");
  const [PodcastHeader1, setPodcastHeader1] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    setShow(true);
    setCategory(row);
  };

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    seteditdata(data);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setCategory(data);
  };

  const [show3, setShow3] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setdeletedata(data);
  };

  useEffect(() => {
    getPodcastcategory();
    getPodcast();
  }, []);

  const getPodcastcategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getPodcastcategory")
      .then(function (response) {
        console.log(response.data);
        setpodcastcat(response.data.PodcastcategoryList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formdata = new FormData();
  const addPodcast = async () => {
    if (!PodcastHeader || !Category?.Type) {
      alert("Please enter Podcast header and Podcast Type");
    } else if (!PodcastImage) {
      alert("Please upload Image");
    } else if (Category?.Type === "Audio" && !PodcastAudio) {
      alert("Please upload Podcast Audio");
    } else if (Category?.Type === "Video" && !PodcastVideo) {
      alert("Please upload Podcast Video");
    } else {
      formdata.append("CategoryId", Category?._id);
      formdata.append("CategoryName", Category?.name);
      formdata.append("PodcastHeader", PodcastHeader);
      formdata.append("PodcastText", PodcastText);
      formdata.append("PodcastVideo", PodcastVideo);
      formdata.append("PodcastAudio", PodcastAudio);
      formdata.append("PodcastImage", PodcastImage);
      try {
        const config = {
          url: "/addPodcast",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
            // getallBanners();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert(error.response.data.error);
        alert(error.response.data.error);
      }
    }
  };

  const getPodcast = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getPodcast")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.PodcastList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editPodcast = async () => {
    formdata.append(
      "PodcastHeader",
      PodcastHeader1 ? PodcastHeader1 : editdata?.PodcastHeader
    );
    formdata.append(
      "PodcastText",
      PodcastText1 ? PodcastText1 : editdata?.PodcastText
    );
    formdata.append(
      "PodcastVideo",
      PodcastVideo1 ? PodcastVideo1 : editdata?.PodcastVideo
    );
    formdata.append(
      "PodcastAudio",
      PodcastAudio1 ? PodcastAudio1 : editdata?.PodcastAudio
    );
    formdata.append(
      "PodcastImage",
      PodcastImage1 ? PodcastImage1 : editdata?.PodcastImage
    );
    try {
      const config = {
        url: "/editPodcast/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getPodcast();
          handleClose1();
          alert("Data updated successfully");
          // window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getPodcast();
      alert(error.response.data.error);
    }
  };

  const deletePodcast = async () => {
    try {
      const config = {
        url: "/deletePodcast/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose3();
          getPodcast();
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const Audiocolumns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "CategoryName",
      text: "Category",
      formatter: (value, row) => (
        <p>{row?.CategoryName ? parse(row?.CategoryName) : "-"}</p>
      ),
    },
    {
      dataField: "PodcastHeader",
      text: "Header",
      formatter: (value, row) => (
        <p>{row?.PodcastHeader ? parse(row?.PodcastHeader) : "-"}</p>
      ),
    },
    {
      dataField: "PodcastText",
      text: "Text",
      formatter: (value, row) => (
        <p style={{ overflowY: "auto", maxHeight: "100px" }}>
          {row?.PodcastText ? parse(row?.PodcastText) : "-"}
        </p>
      ),
    },
    {
      dataField: "PodcastImage",
      text: "Image",
      formatter: (value, row) =>
        row?.PodcastImage ? (
          <img
            src={"https://relentlessyou.live/Podcast/" + row.PodcastImage}
            width={100}
            height={80}
            onClick={() =>
              window.open(
                "https://relentlessyou.live/Podcast/" + row.PodcastImage
              )
            }
          />
        ) : (
          "-"
        ),
    },
    {
      dataField: "PodcastAudio",
      text: "Audio",
      formatter: (value, row) =>
        row?.PodcastAudio ? (
          <audio
            width="100"
            height="100"
            controls
            autoPlay={false}
            onClick={() =>
              window.open(
                "https://relentlessyou.live/Podcast/" + row.PodcastAudio
              )
            }
          >
            <source
              src={"https://relentlessyou.live/Podcast/" + row.PodcastAudio}
              type="audio/mp3"
              onClick={() =>
                window.open(
                  "https://relentlessyou.live/Podcast/" + row.PodcastAudio
                )
              }
            />
            Your browser does not support the video tag.
          </audio>
        ) : (
          "-"
        ),
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];
  const Videocolumns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "CategoryName",
      text: "Category",
      formatter: (value, row) => (
        <p>{row?.CategoryName ? parse(row?.CategoryName) : "-"}</p>
      ),
    },
    {
      dataField: "PodcastHeader",
      text: "Header",
      formatter: (value, row) => (
        <p>{row?.PodcastHeader ? parse(row?.PodcastHeader) : "-"}</p>
      ),
    },
    {
      dataField: "PodcastText",
      text: "Text",
      formatter: (value, row) => (
        <p style={{ overflowY: "auto", maxHeight: "100px" }}>
          {row?.PodcastText ? parse(row?.PodcastText) : "-"}
        </p>
      ),
    },
    {
      dataField: "PodcastImage",
      text: "Image",
      formatter: (value, row) =>
        row?.PodcastImage ? (
          <img
            src={"https://relentlessyou.live/Podcast/" + row.PodcastImage}
            width={100}
            height={80}
            onClick={() =>
              window.open(
                "https://relentlessyou.live/Podcast/" + row.PodcastImage
              )
            }
          />
        ) : (
          "-"
        ),
    },
    {
      dataField: "PodcastVideo",
      text: "Video",
      formatter: (value, row) =>
        row?.PodcastVideo ? (
          <video
            width="100"
            height="100"
            controls
            autoPlay={false}
            onClick={() =>
              window.open(
                "https://relentlessyou.live/Podcast/" + row.PodcastVideo
              )
            }
          >
            <source
              src={"https://relentlessyou.live/Podcast/" + row.PodcastVideo}
              type="video/mp4,video/webm,"
              onClick={() =>
                window.open(
                  "https://relentlessyou.live/Podcast/" + row.PodcastVideo
                )
              }
            />
          </video>
        ) : (
          "-"
        ),
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  const columnscategory = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Podcastcategoryimg",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={
            "https://relentlessyou.live/Podcastcategory/" +
            row.Podcastcategoryimg
          }
          width={100}
          height={80}
          onClick={() =>
            window.open(
              "https://relentlessyou.live/Podcastcategory/" +
                row.Podcastcategoryimg
            )
          }
        />
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "Type",
      text: "Type",
      formatter: (value, row) => <p>{row?.Type ? row?.Type : "-"}</p>,
    },
    {
      dataField: "name",
      text: "Add Episodes",
      formatter: (value, row) => (
        <button
          style={{
            backgroundColor: "#f37261",
            color: "white",
            marginLeft: "10px",
            border: "none",
            padding: "6px",
            borderRadius: "5px",
            marginLeft: "467px",
          }}
          onClick={() => handleShow(row)}
        >
          +Add
        </button>
      ),
    },

    {
      dataField: "",
      text: "View",
      formatter: (value, row) => (
        <button
          onClick={() => handleShow2(row)}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <i
            class="fa fa-eye"
            aria-hidden="true"
            style={{ color: "#f37261" }}
          ></i>
        </button>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Podcast
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={podcastcat}
          columns={columnscategory}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              {/* <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton> */}
              {/* <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button> */}
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Podcast</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col md={4}>
                <label>
                  Podcast Category<span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  value={Category?.name}
                  // onChange={(e) => setPodcastHeader(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>
                  Podcast Header <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  placeholder="Header"
                  onChange={(e) => setPodcastHeader(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Podcast Text</label>
              </Col>
              <Col md={6}>
                <textarea
                  style={{ width: "100%" }}
                  placeholder="Text"
                  onChange={(e) => setPodcastText(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>
                  Podcast Type <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                {/* <select
                  onChange={(e) => setType(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>Select</option>
                  <option value="Audio">Audio</option>
                  <option value="Video">Video</option>
                </select> */}
                <input style={{ width: "100%" }} value={Category?.Type} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>
                  Image <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="image5"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setPodcastImage(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        PodcastImage
                          ? URL.createObjectURL(PodcastImage)
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>
            {Category?.Type === "Audio" ? (
              <Row className="mt-3">
                <Col md={4}>
                  <label>
                    Audio <span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <label
                    htmlFor="audioInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="audio/*"
                      id="audioInput"
                      type="file"
                      onChange={(e) => setPodcastAudio(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          PodcastAudio
                            ? "https://cdn-icons-png.flaticon.com/128/4340/4340355.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Col>
              </Row>
            ) : Category?.Type === "Video" ? (
              <Row className="mt-3">
                <Col md={4}>
                  <label>
                    Video <span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <label
                    htmlFor="videoInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="video/*"
                      id="videoInput"
                      type="file"
                      onChange={(e) => setPodcastVideo(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          PodcastVideo
                            ? "https://cdn-icons-png.flaticon.com/128/2377/2377793.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addPodcast}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Podcast</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col md={4}>
                <label>Podcast Category</label>
              </Col>
              <Col md={6}>
                {/* <select
                  onChange={(e) => setCategory1(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option>{editdata?.Category}</option>
                  {podcastcat
                    ?.filter((pod) => pod.name !== editdata?.Category)
                    ?.map((item) => (
                      <option value={item?.name}>{item?.name}</option>
                    ))}
                </select> */}
                <input
                  style={{ width: "100%" }}
                  value={Category?.name}
                  // onChange={(e) => setPodcastHeader(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Podcast Header</label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  placeholder={editdata?.PodcastHeader}
                  onChange={(e) => setPodcastHeader1(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Podcast Text</label>
              </Col>
              <Col md={6}>
                <textarea
                  style={{ width: "100%" }}
                  placeholder={editdata?.PodcastText}
                  onChange={(e) => setPodcastText1(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Podcast Type</label>
              </Col>
              <Col md={6}>
                <input style={{ width: "100%" }} value={Category?.Type} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <label>Image</label>
              </Col>
              <Col md={6}>
                <label
                  htmlFor="image5"
                  style={{ display: "grid", alignSelf: "center" }}
                >
                  <Input
                    accept="image/*"
                    id="image5"
                    type="file"
                    onChange={(e) => setPodcastImage1(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        PodcastImage1
                          ? URL.createObjectURL(PodcastImage1)
                          : "https://relentlessyou.live/Podcast/" +
                            editdata?.PodcastImage
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </Col>
            </Row>

            {Category?.Type === "Audio" ? (
              <Row className="mt-3">
                <Col md={4}>
                  <label>Audio</label>
                </Col>
                <Col md={6}>
                  <label
                    htmlFor="audioInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="audio/*"
                      id="audioInput"
                      type="file"
                      onChange={(e) => setPodcastAudio1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          PodcastAudio1
                            ? "https://cdn-icons-png.flaticon.com/128/4340/4340355.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Col>
              </Row>
            ) : Category?.Type === "Video" ? (
              <Row className="mt-3">
                <Col md={4}>
                  <label>Video</label>
                </Col>
                <Col md={6}>
                  <label
                    htmlFor="videoInput"
                    style={{ display: "grid", alignSelf: "center" }}
                  >
                    <Input
                      accept="video/*"
                      id="videoInput"
                      type="file"
                      onChange={(e) => setPodcastVideo1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          PodcastVideo1
                            ? "https://cdn-icons-png.flaticon.com/128/2377/2377793.png"
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyHlhRBUevbh8DcWe7o5epTHj3PS0o7vsV1A&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editPodcast}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="modalpodcast">
        <Modal.Header closeButton>
          <Modal.Title>Podcast-List</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data?.filter((dt) => dt?.CategoryId === Category?._id)}
              columns={Category?.Type === "Audio" ? Audiocolumns : Videocolumns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#f37261",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  {/* <button
                    style={{
                      backgroundColor: "#f37261",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button> */}
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={deletePodcast}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Adpodcast;
