import React from "react";
import "../Aboutus/Aboutus.css";

const Aboutme = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
          // backgroundAttachment: "fixed",
          // marginTop: "-86px",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh pt-5">
          <div className="row">
            <div className="col-md-12  ojjjpoiiii text-center">
              <p>About Author</p>
              <span>
                <a href="/">Home/</a>
                <a href="">About Author</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div
        className="container"
        style={{
          marginBottom: "380px",
          marginTop: "20px",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div style={{ position: "relative" }}>
              <img
                src="../Img/64.png"
                alt=""
                style={{
                  width: "968px",
                  height: "676px",
                  objectPosition: "45.65% 50%",
                  overflow: "hidden",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  // width: "650px",
                  // height: "720px",
                  //   minHeight: "644px",
                  backgroundColor: "white",
                  padding: "20px 40px 0px 40px",
                  top: "28rem",
                  // right: "10%",
                  left: "35%",
                }}
              >
                <h3 className="fs-1 fw-bold">
                  Welcome to the World of Mindfuless
                </h3>
                <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.6",
                  }}
                  className="fs-5"
                >
                  Behzad Randeria has served as a Corporate Executive, an
                  Executive Life Coach and Mentor to staff across prestigious
                  organizations.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.6",
                  }}
                  className="fs-5"
                >
                  With a degree in Sociology, Certifications in Neurolinguistic
                  Programming, Hypnotherapy, Master Spirit Life Coaching,
                  Professional Life Coaching (PCC-ICF), a Certification in CHRO
                  Leadership (Cornell University), Behzad has carved out her
                  niche, within the Mindfulness Space, with life’s essential
                  behavioural qualities.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.6",
                  }}
                  className="fs-5"
                >
                  Based on everything she has assimilated, through learning,
                  experience, and wisdom, she has curated and voiced over 100
                  Mindfulness based meditations, for one of India’s largest
                  meditation apps.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.6",
                  }}
                  className="fs-5"
                >
                  Behzad is a wife and mother, who has to her credit over 2500
                  coaching hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutme;
