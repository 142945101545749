import React from "react";
import ChakrasTimeline from "./ChakrasTimeline";

const Chakras = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "300px",
          position: "relative",
          backgroundAttachment: "fixed",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh">
          <div className="row">
            <div className="col-md-12 ojjjpoiiii text-center">
              <p>Balance Your Chakras</p>
              <span>
                <a href="/">Home/</a>
                <a href="">Meditation/</a>
                <a href="">Balance Your Chakras</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-3">
            <p className="biuggbui fs-2">Balance Your Chakras</p>
            <div className="iuyiuyf"></div>
            <div className="mt-4">
              <ChakrasTimeline />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chakras;
