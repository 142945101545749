import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import StarRatings from "react-star-ratings";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { colors } from "@mui/material";
import parse from "html-react-parser";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import { FaDownload } from "react-icons/fa";

const Booksdetails = () => {
  // const urlObj = new URL(window.location.href);

  // // Get the 'data' query parameter
  // const dataParam = urlObj.searchParams.get("data");

  // // Decode and parse the data
  // const decodedData = decodeURIComponent(dataParam);
  // const parsedData = JSON.parse(decodedData);

  // // Use the parsed data
  // console.log(
  //   "parsedData",
  //   parsedData,
  //   parsedData?.data?.merchantTransactionId
  // );

  // useEffect(() => {
  //   if (parsedData?.data?.merchantTransactionId) {
  //     getBooksDetails();
  //   }
  // }, [parsedData?.data?.merchantTransactionId]);

  // const [Book, setBook] = useState();
  // const getBooksDetails = async () => {
  //   try {
  //     const config = {
  //       url: "/user/phonePay",
  //       method: "post",
  //       baseURL: "https://relentlessyou.live/api",
  //       // headers: { "content-type": "multipart/form-data" },
  //       data: {
  //         merchantTransactionId: parsedData?.data?.merchantTransactionId,
  //       },
  //     };
  //     let response = await axios(config);
  //     if (response.status === 200) {
  //       setBook(response.data.Book);
  //     }
  //   } catch (error) {
  //     console.log(error);

  //     if (error.response) {
  //       alert(error.response.data.error);
  //     }
  //   }
  // };

  const uselocation = useLocation();
  const { item } = uselocation.state;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
  };

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setShow4(true);
  };

  console.log("item", item);

  const [bookoverview, setbookoverview] = useState("Description");
  const [isShowMore, setIsShowMore] = useState(false);

  const [Bookscat, setBookscat] = useState([]);
  const [Books, setBooks] = useState([]);
  const [Allbooks, setAllbooks] = useState([]);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
  };

  const getBooks = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBooks")
      .then(function (response) {
        setAllbooks(
          response.data.BooksList?.filter(
            (cat) => cat?.catname === item?.catname && cat?._id !== item?._id
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (Object.keys(item).length > 0) {
      getBooks();
    }
  }, [item]);

  const ratings =
    item?.reviews?.length > 0
      ? item?.reviews?.reduce((a, rat) => a + Number(rat.rating), 0)
      : 0;

  const [showUserDetails, setShowUserDetails] = useState(false);
  const handleCloseUserDetails = () => setShowUserDetails(false);
  const handleShowUserDetails = (data) => {
    setShowUserDetails(true);
  };

  const [userName, setuserName] = useState("");
  const [mobileNumber, setmobileNumber] = useState();

  const MakePayment = async () => {
    if (!userName || !mobileNumber) {
      alert("Please fill both the fields");
    } else {
      try {
        const config = {
          url: "/user/phonePay",
          method: "post",
          baseURL: "https://relentlessyou.live/api",
          // headers: { "content-type": "multipart/form-data" },
          data: {
            BookId: item?._id,
            userName: userName,
            amount: (
              item?.amount -
              (item?.amount * item?.discount) / 100
            )?.toFixed(2),
            userName: userName,
            mobileNumber: mobileNumber,
          },
        };
        let response = await axios(config);
        if (response.status === 200) {
          // console.log("resp", response);
          // placeorder();
          console.log("url", response.data.url.url);
          window.location.assign(response.data?.url?.url);
        }
      } catch (error) {
        console.log(error);

        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const placeorder = async (paymentId) => {
    try {
      const config = {
        url: "/user/addBooksorder",
        method: "post",
        baseURL: "https://relentlessyou.live/api",
        // headers: {'content-type': 'multipart/form-data'},
        data: {
          BookId: item?._id,
          userName: userName,
          paymentId: paymentId,
        },
      };
      let response = await axios(config);
      if (response.status === 200) {
        // alert("Payment done successfully");
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  console.log("Allbooks", Allbooks, Books);
  return (
    <div style={{ padding: "30px" }}>
      <Breadcrumb>
        <Breadcrumb.Item href="booksection">Books</Breadcrumb.Item>
        <Breadcrumb.Item href="booksection">{item?.catname}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={3}>
          <img
            src={`https://relentlessyou.live/Books/${item?.Booksimg}`}
            style={{ height: "375px", width: "100%" }}
          />
        </Col>
        <Col md={6}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              marginBottom: "0px",
            }}
          >
            {item?.name}
          </p>
          <p style={{ marginBottom: "0px" }}>{item?.authorname}</p>
          <StarRatings
            rating={ratings}
            starRatedColor="gold"
            // changeRating={3}
            numberOfStars={5}
            name="rating"
            starDimension="20px"
            starSpacing="0px"
          />
          <Row className="mt-3">
            <Col md={2}>Price</Col>
            <Col md={2}> ₹{item?.amount?.toFixed(2)}</Col>
          </Row>
          <Row>
            <Col md={2}>Offer Price</Col>
            <Col md={2}>
              ₹
              {(item?.amount - (item?.amount * item?.discount) / 100)?.toFixed(
                2
              )}
            </Col>
          </Row>
          <Row>
            <Col md={2}>You Save</Col>
            <Col md={2}>
              ₹
              {(
                item?.amount -
                (item?.amount - (item?.amount * item?.discount) / 100)
              )?.toFixed(2)}
            </Col>
          </Row>
          <div style={{ marginTop: "30px" }}>
            <a
              onClick={handleShow3}
              style={{ color: "blue", textDecorationLine: "underline" }}
            >
              Read Sample
            </a>
          </div>

          <Row className="mt-5">
            <Col md={4}>
              <botton
                style={{
                  color: "white",
                  backgroundColor: "black",
                  padding: "10px",
                  display: "block",
                  width: "100%",
                  borderRadius: "5px",
                  margin: "10px",
                  textAlign: "center",
                }}
                // onClick={handleShow}
                onClick={handleShowUserDetails}
              >
                Buy ebook
              </botton>
            </Col>
            <Col md={4}>
              <botton
                style={{
                  color: "white",
                  backgroundColor: "#f37261",
                  padding: "10px",
                  display: "block",
                  width: "100%",
                  borderRadius: "5px",
                  margin: "10px",
                  textAlign: "center",
                }}
                onClick={() => window.location.assign(item?.distributorlink)}
              >
                Buy HardCopy
              </botton>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Col>
        <Col md={3}>
          <div
            style={{
              border: "3px solid #e5e5e5",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                marginBottom: "0px",
              }}
            >
              Customer Reviews
            </p>
            <StarRatings
              rating={ratings}
              starRatedColor="gold"
              numberOfStars={5}
              name="rating"
              starDimension="20px"
              starSpacing="0px"
            />
            <p style={{ color: "gray", marginBottom: "0px" }}>
              {item?.reviews?.length > 0
                ? item?.reviews?.reduce((a, rat) => a + Number(rat.rating), 0)
                : 0}{" "}
              out of 5
            </p>
            <p style={{ color: "gray" }}>
              {item?.reviews?.length} global ratings
            </p>
            <div
              style={{
                height: "280px",
                overflowY: "scroll",
              }}
            >
              {item?.reviews?.map((rev) => (
                <div
                  style={{
                    marginHorizontal: 20,
                    marginTop: 10,
                    borderBottom: "1px solid #e5e5e5",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50px",
                      }}
                      src={`https://relentlessyou.live/Customer/${rev?.userProflepic}`}
                    />
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          margin: 5,
                        }}
                      >
                        {rev?.userName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <StarRatings
                      rating={parseInt(rev.rating)}
                      starRatedColor="gold"
                      numberOfStars={5}
                      name="rating"
                      starDimension="15px"
                      starSpacing="0px"
                    />
                  </div>
                  <p style={{ marginBottom: "0px" }}>{rev?.message}</p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      {/* {Allbooks?.length > 0 ? (
        <div>
          <p style={{ fontWeight: "bold", fontSize: "25px" }}>Related Book's</p>
          <Slider {...settings}>
            {Allbooks?.map((item) => {
              return (
                <Col md={3}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/booksdetails"
                    state={{ item: item }}
                  >
                    <Card
                      style={{
                        margin: "2px",
                        height: "375px",
                        overflow: "hidden",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={`https://relentlessyou.live/Books/${item?.Booksimg}`}
                        style={{
                          padding: "10px",
                          height: "250px",
                          overflow: "hidden",
                          // objectFit: "cover",
                        }}
                      />
                      <Card.Body>
                        <Card.Title style={{ whiteSpace: "nowrap" }}>
                          {item?.name}
                        </Card.Title>
                        <Card.Text>
                          <div>
                            <p style={{ margin: "0px" }}>
                              by {item?.authorname}
                            </p>
                            <StarRatings
                              rating={
                                item?.reviews?.length > 0
                                  ? item?.reviews?.reduce(
                                      (a, rat) => a + Number(rat.rating),
                                      0
                                    )
                                  : 0
                              }
                              starRatedColor="gold"
                              // changeRating={3}
                              numberOfStars={5}
                              name="rating"
                              starDimension="20px"
                              starSpacing="0px"
                            />
                            <p>
                              ₹{" "}
                              {(
                                item?.amount -
                                (item?.amount * item?.discount) / 100
                              )?.toFixed(2)}{" "}
                              M.R.P:{" "}
                              <span
                                style={{
                                  textDecorationLine: "line-through",
                                  fontSize: "12px",
                                }}
                              >
                                ₹{item?.amount}
                              </span>{" "}
                              <span
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                ({item?.discount}%)
                              </span>
                            </p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Slider>
        </div>
      ) : (
        ""
      )} */}
      <Row className="mt-5">
        <p style={{ fontWeight: "bold", fontSize: "25px" }}>Book Overview</p>
        <div style={{ display: "flex", gap: "30px" }}>
          <p
            style={{
              textAlign: "center",
              color: bookoverview === "Description" ? "#176B87" : "black",
              fontWeight: bookoverview === "Description" ? "bold" : "500",
              marginHorizontal: 20,
            }}
            onClick={() => setbookoverview("Description")}
          >
            Description
          </p>
          <p
            style={{
              textAlign: "center",
              color: bookoverview === "About the Author" ? "#176B87" : "black",
              fontWeight: bookoverview === "About the Author" ? "bold" : "500",
              marginHorizontal: 20,
            }}
            onClick={() => setbookoverview("About the Author")}
          >
            About the Author
          </p>
        </div>

        {bookoverview === "Description" ? (
          <div style={{ marginTop: 10, textAlign: "justify" }}>
            {isShowMore
              ? parse(item?.discription)
              : parse(item?.discription?.substring(0, 500))}
          </div>
        ) : (
          <div style={{ margiTop: 10, textAlign: "justify" }}>
            {isShowMore
              ? parse(item?.aboutauthor)
              : parse(item?.aboutauthor?.substring(0, 500))}
          </div>
        )}

        <div
          style={{
            margin: 20,
            marginTop: 0,
          }}
        >
          <p style={{ color: "#176B87" }} onClick={toggleReadMoreLess}>
            {isShowMore ? "Read Less" : "Read More"}
          </p>
        </div>
      </Row>

      {/* <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#F58243" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#F58243" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
     
      </Modal> */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            {/* Choose a way to Pay ₹
            {(item?.amount - (item?.amount * item?.discount) / 100)?.toFixed(2)} */}
            {item?.catname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="container-fluid">
            <p>Pay online securely with Relentless-You</p>
            <input
              type="radio"
              id="html"
              name="fav_language"
              value="Net Banking"
            />
            <label for="html">Net Banking</label>
            <br />
            <input type="radio" id="css" name="fav_language" value="UPI" />
            <label for="css">UPI</label>
            <br />
            <input
              type="radio"
              id="javascript"
              name="fav_language"
              value="JavaScript"
            />
            <label for="javascript">Add a Credit, Debit or ATM Card</label>
          </div> */}
          <div className="container-fluid">
            <Row>
              <Col md={3}>
                <img
                  src={`https://relentlessyou.live/Books/${item?.Booksimg}`}
                  style={{ height: "100px", width: "100px" }}
                />
              </Col>
              <Col md={6}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "0px",
                  }}
                >
                  {item?.name}
                </p>
                <p style={{ marginBottom: "0px" }}>{item?.authorname}</p>
                <StarRatings
                  rating={ratings}
                  starRatedColor="gold"
                  // changeRating={3}
                  numberOfStars={5}
                  name="rating"
                  starDimension="20px"
                  starSpacing="0px"
                />

                <Row>
                  <Col md={4}>eBook Price :</Col>
                  <Col md={2}>
                    ₹
                    {(
                      item?.amount -
                      (item?.amount * item?.discount) / 100
                    )?.toFixed(2)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <botton
                style={{
                  color: "white",
                  marginTop: "5%",
                  backgroundColor: "#f37261",
                  padding: "10px 45px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                onClick={handleShow4}
              >
                Download eBook
              </botton>
            </Row>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>

      <Modal show={show3} onHide={handleClose3} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{item?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Books/" + item?.samplepdf}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4} className="pdfmodal">
        <Modal.Header closeButton>
          <Modal.Title>{item?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div>
            <iframe
              src={"https://relentlessyou.live/Books/" + item?.ebook}
              width="100%"
              height="500px"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUserDetails} onHide={handleCloseUserDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Enter your details to make payment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ display: "grid" }}>
            <input
              placeholder=" Name"
              style={{
                borderRadius: "5px",
                margin: "10px auto 0px auto",
              }}
              onChange={(e) => setuserName(e.target.value)}
            />
            <br></br>
            <input
              placeholder=" Phone Number"
              style={{
                borderRadius: "5px",
                margin: "10px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={10}
              onChange={(e) => setmobileNumber(e.target.value)}
            />
            <botton
              style={{
                color: "white",
                marginTop: "5%",
                backgroundColor: "#f37261",
                padding: "10px 45px",
                borderRadius: "5px",
                textAlign: "center",
              }}
              onClick={MakePayment}
            >
              Make Payment
            </botton>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserDetails}>
            Ok
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Booksdetails;
