import React from "react";
import "../Maditation/styles/Maditation1.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import Maditationfaq from "./Maditationfaq/Maditationfaq";

const MaditationSoul = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          // backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "300px",
          position: "relative",
          backgroundAttachment: "fixed",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh">
          <div className="row">
            <div className="col-md-12 ojjjpoiiii text-center">
              <p>Meditations For the Soul</p>
              <span>
                <a href="/">Home/</a>
                <a href="">Meditation/</a>
                <a href="">Meditations For the Soul</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>
      <div style={{ backgroundColor: "#F5F7F9", marginBottom: "10px" }}>
        <div className="container pt-5 py-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="biuggbui fs-1">What Comes to Your Mind</p>
              <p className="biuggbui fs-4">
                When You Hear the Word MEDITATION ?
              </p>
              <div className="iuyiuyf"></div>
            </div>
          </div>
          <div className="row mt-5 mb-5 jfufttt">
            <div className="col-md-12 text-center">
              <img src="/Img/57.jpg" alt="" style={{ width: "100%" }} />
            </div>
            <div className="col-md-12 jeheuih m-auto">
              <p></p>
              <p>
                Is it a monk, still in his lotus posture, reflecting over the
                perspective of life at the top of a secluded mountain? Or the
                amount of patience that I’d would require to sit like that?
                Traditionally, meditation has been associated with eastern
                mysticism and spiritual awareness. And for the decades, it has
                been portrayed in the same way, making us believe that this
                rather holistic practice is just limited to a spiritual outlook.
                I am not denying the efficiency of meditation in helping one
                heighten their spiritual awareness. But, as the scientist and
                researchers started to go deep in understanding how meditation
                works? It was made clear that cultivating a habit of meditation
                can dramatically enhance our mental health and overall
                well-being.While it’s hard to define meditation in calculated
                words. It is popularly known as ‘the practice of training your
                brain to focus its attention on achieving emotional as well as
                mental peace.’ It is used to develop compassion, self-awareness,
                positive outlooks, discipline, and even increased tolerance. It
                is a wholesome way of self-care that is gaining popularity, as
                both mental health experts and coaches continue to recommend it.
                It is seen as a promising non- pharmacological alternative to
                discover balance and calmness in the fast-paced and disruptive
                world. The meditation library created on this website is
                handpicked, exclusive and designed to consistently target and
                help you with your goals. The music you hear has been developed
                by a qualified Music Therapist. Therefore, the dual effect of
                Musical Therapy and Hypnotherapy is quite the power packed punch
                that you need.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          backgroundColor: "#F7F7F7",
          padding: "16px 20px 16px 20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div>
          <div style={{ position: "absolute", top: "-85px", left: "0px" }}>
            <img src="../Img/39.png" alt="" />
          </div>
          <div style={{ position: "absolute", top: "-85px", right: "0px" }}>
            <img src="../Img/40.png" alt="" />
          </div>
          <div style={{ position: "absolute", top: "30.5rem", left: "0px" }}>
            <img src="../Img/43.png" alt="" />
          </div>
          <div style={{ position: "absolute", top: "30.5rem", right: "0px" }}>
            <img src="../Img/45.png" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/Img/60.png"
                alt=""
                style={{ width: "100%", height: "600px" }}
              />
            </div>
            <div className="col-md-6 jeheuih m-auto">
              <p></p>
              <p>
                "As a Life and Executive Coach, one of the earliest lessons I
                learned was not to make any reference point from a client become
                ‘my agenda’. It’s all about them. Their perspective, their
                goals, their growth, their agenda. But, here’s the thing. A
                teeny-tiny voice inside me sometimes questions my own field of
                reference at strategic points. Let me elaborate here".
              </p>
              <br />
              <p>
                Children are going to be those little sponges that In spite of
                this notion, they are still going to be those little sponges
                that take in every bit of action, reaction and inaction coming
                from their guardians. I mean, the first time I saw my son
                manipulate a particular conversation in not such a positive
                manner and I had called him out on it, he very tacitly came back
                with “But you do the same Ma” His expressions, his body
                language, all of them conveyed a bit of confusion over my
                calling out that behaviour instead of rewarding it. Point taken.
                As adults, we don’t often have our behaviours called out,
                therefore, to have ‘certain qualities’ fall by the wayside and
                become ingrained personality traits is a no brainer. Taaadaaa!
                Mindfulness makes it’s grand entry here.
              </p>
              <div
                className="hhhhk_000 huuiugg mt-2"
                // data-aos="fade-left"
                // data-aos-duration="500"
              >
                <button>
                  Know More{" "}
                  <span>
                    <MdOutlineArrowRightAlt />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container mt-4 yuguyg" style={{ height: "543px" }}>
        <div className="row">
          <div className="col-md-6">
            <img
              src="../Img/62.jpg"
              alt=""
              style={{ width: "100%", height: "60%" }}
            />
          </div>
          <div className="col-md-6">
            <div style={{ backgroundColor: "#000", padding: "20px" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <p className="biuggbui">How to Meditate?</p>
                    <p className="text-white">
                      No, I am not going to recommend you sitting in a lotus
                      posture if you don’t want to. There is no fixed answer to
                      this question as well, but there are a few guidelines that
                      you can follow for a better and enriching experience.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <Maditationfaq />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaditationSoul;
