import axios from "axios";
import React, { useEffect, useState } from "react";

function Login() {
  const [loginPassword, setloginPassword] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const login = async (e) => {
    if (!email | !password) {
      alert("Please Fill All The Field");
    } else {
      try {
        const config = {
          url: "/login",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
            password: password,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data.success);
          alert("Login Success");
          sessionStorage.setItem("admin", JSON.stringify(res.data.success));
          window.location.assign("/admin/dashbord");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      <div className="maindiv">
        <div className="firstdiv">
          <img src="/Image/logo.png" />
        </div>
        <div className="seconddiv">
          <p className="login-header">Admin / Subadmin</p>
          <p style={{ color: "white", textAlign: "center" }}>
            Yoga is a mirror to look at ourselves from within...
          </p>
          <div className="mt-5" style={{ marginLeft: "10%" }}>
            <label className="login-label">Email ID *</label>
            <br></br>
            <input
              className="login-input"
              placeholder="Email ID"
              type="text"
              onChange={(e) => setemail(e.target.value)}
            />
            <br></br>
            <br></br>
            <label className="login-label">Password *</label>
            <br></br>
            <div className="cust-login1">
              <input
                className="login-input"
                type={loginPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
              />
              {loginPassword ? (
                <button
                  onClick={() => setloginPassword(!loginPassword)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginLeft: "-38px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: "#f37261" }}
                  ></i>
                </button>
              ) : (
                <button
                  onClick={() => setloginPassword(!loginPassword)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginLeft: "-38px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                    style={{ color: "#f37261" }}
                  ></i>
                </button>
              )}
            </div>

            <a
              href="Forgotpassword"
              style={{ color: "white", marginLeft: "36%" }}
            >
              Forgot Password...?
            </a>
          </div>
          <button className="login-btn" onClick={login}>
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
