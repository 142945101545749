import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FAQs from "./Components/FAQs";
import BlogViewMore from "./Components/BlogViewMore";
import Services from "./Components/Services";
import ContactUs from "./Components/Contactus";
import Home from "../src/Components/Home/Home";
import Maditation1 from "./Components/Maditation/Maditation1";
import MaditationSoul from "./Components/Maditation/MaditationSoul";
import Footerr from "./Components/Footer/Footerr";
import Navbarr from "./Components/Header/Navbar";
import Aboutus from "./Components/Aboutus/Aboutus";
import Chakras from "./Components/Maditation/Chakras/Chakras";
import Blog from "./Components/Blog/Blog";
import Backtotop from "./Components/Backtotop/Backtotop";
import Aboutme from "./Components/Aboutus/Aboutme";
import Heloholistic from "./Components/Maditation/Heloholistic";
import MusicalTherapy from "./Components/Maditation/MusicalTherapy";
import Contactuss from "./Components/Contactuss/Contactuss";
import Login from "./Components/Admin/Login";
import AdminPanel from "./Components/Admin/Adminpanel";
import Dashboard from "./Components/Admin/Dashboard";
import Forgotpassword from "./Components/Admin/Forgotpassword";
import Verifyotp from "./Components/Admin/Verifyotp";
import Changepassword from "./Components/Admin/Changepassword";
import Videocategory from "./Components/Admin/Videocategory";
import Snippet from "./Components/Admin/Snippet";
import SubscribePlan from "./Components/Admin/SubscribePlan";
import Userlist from "./Components/Admin/Userlist";
import Subscriberlist from "./Components/Admin/Subscriberlist";
import Blogpage from "./Components/Admin/Blogpage";
import UserBlog from "./Components/Admin/UserBlog";
import AccountHistory from "./Components/Admin/AccountHistory";
import Addvideo from "./Components/Admin/Addvideo";
import AudioCategory from "./Components/Admin/AudioCategory";
import Addaudio from "./Components/Admin/Addaudio";
import Kidsbanners from "./Components/Admin/Kidsbanners";
import Kidscategory from "./Components/Admin/Kidscategory";
import Kidsaudio from "./Components/Admin/Kidsaudio";
import Kidsvideo from "./Components/Admin/Kidsvideo";
import Podcastcategory from "./Components/Admin/Podcastcategory";
import Adpodcast from "./Components/Admin/Adpodcast";
import Musictherapistbanners from "./Components/Admin/Musictherapistbanners";
import Musictherapistcategory from "./Components/Admin/Musictherapistcategory";
import Musictherapistmusic from "./Components/Admin/Musictherapistmusic";
import Contactus from "./Components/Admin/Contactus";
import Mostpopularvideo from "./Components/Admin/Mostpopularvideo";
import Mostpopularaudio from "./Components/Admin/Mostpopularaudio";
import Awaitedaudio from "./Components/Admin/Awaitedaudio";
import Awaitedvideo from "./Components/Admin/Awaitedvideo";
import Subscribecat from "./Components/Admin/Subscribecat";
import Subadmincreate from "./Components/Admin/Subadmincreate";
import Subadmindetails from "./Components/Admin/Subadmindetails";
import Bookscategory from "./Components/Admin/Bookscategory";
import Books from "./Components/Admin/Books";
import Booksaccounthistory from "./Components/Admin/Booksaccounthistory";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Booksection from "./Components/Booksection";
import Booksdetails from "./Components/Booksdetails";
import Booksnew from "./Components/Booksnew";
import Privacy from "./Components/Privacy";
import TermsCondition from "./Components/TermsCondition";

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Navbarr />
                  <Home />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/faq"
              element={
                <>
                  <Navbarr />
                  <FAQs />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/blog"
              element={
                <>
                  <Navbarr />
                  <Blog />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/blogviewmore"
              element={
                <>
                  <Navbarr />
                  <BlogViewMore />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/service"
              element={
                <>
                  <Navbarr />
                  <Services />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/aboutus"
              element={
                <>
                  <Navbarr />
                  <Aboutus />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/contactus"
              element={
                <>
                  <Navbarr />
                  <Contactuss />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/maditation1"
              element={
                <>
                  <Navbarr />
                  <Maditation1 />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/booksection"
              element={
                <>
                  <Navbarr />
                  <Booksection />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/booksdetails"
              element={
                <>
                  <Navbarr />
                  <Booksdetails />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/Booksnew"
              element={
                <>
                  <Navbarr />
                  <Booksnew />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/maditationsoul"
              element={
                <>
                  <Navbarr />
                  <MaditationSoul />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/chakras"
              element={
                <>
                  <Navbarr />
                  <Chakras />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/aboutme"
              element={
                <>
                  <Navbarr />
                  <Aboutme />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/helloholistic"
              element={
                <>
                  <Navbarr />
                  <Heloholistic />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            <Route
              path="/musicaltherapy"
              element={
                <>
                  <Navbarr />
                  <MusicalTherapy />
                  <Backtotop />
                  <Footerr />
                </>
              }
            />
            {/* Admin */}

            <Route
              path="/admin"
              exact
              element={
                <>
                  <Login />
                </>
              }
            />
            <Route
              path="/Forgotpassword"
              exact
              element={
                <>
                  <Forgotpassword />
                </>
              }
            />
            <Route
              path="/Verifyotp"
              exact
              element={
                <>
                  <Verifyotp />
                </>
              }
            />
            <Route
              path="/privacy-policy"
              exact
              element={
                <>
                  <Privacy />
                </>
              }
            />
            <Route
              path="/terms-condition"
              exact
              element={
                <>
                  <TermsCondition />
                </>
              }
            />
            <Route
              path="/Changepassword"
              exact
              element={
                <>
                  <Changepassword />
                </>
              }
            />
            <Route path="/adminPanel" element={<AdminPanel />}></Route>
            <Route
              path="admin/dashbord"
              element={<AdminPanel children={<Dashboard />} />}
            ></Route>
            <Route
              path="admin/Subadmincreate"
              element={<AdminPanel children={<Subadmincreate />} />}
            ></Route>
            <Route
              path="admin/Subadmindetails"
              element={<AdminPanel children={<Subadmindetails />} />}
            ></Route>
            <Route
              path="admin/Bookscategory"
              element={<AdminPanel children={<Bookscategory />} />}
            ></Route>
            <Route
              path="admin/Books"
              element={<AdminPanel children={<Books />} />}
            ></Route>
            <Route
              path="admin/Booksaccounthistory"
              element={<AdminPanel children={<Booksaccounthistory />} />}
            ></Route>
            <Route
              path="admin/Videocategory"
              element={<AdminPanel children={<Videocategory />} />}
            ></Route>
            <Route
              path="admin/Addvideo"
              element={<AdminPanel children={<Addvideo />} />}
            ></Route>
            <Route
              path="admin/AudioCategory"
              element={<AdminPanel children={<AudioCategory />} />}
            ></Route>
            <Route
              path="admin/Addaudio"
              element={<AdminPanel children={<Addaudio />} />}
            ></Route>
            <Route
              path="admin/Snippet"
              element={<AdminPanel children={<Snippet />} />}
            ></Route>
            <Route
              path="admin/SubscribePlan"
              element={<AdminPanel children={<SubscribePlan />} />}
            ></Route>
            <Route
              path="admin/Userlist"
              element={<AdminPanel children={<Userlist />} />}
            ></Route>
            <Route
              path="admin/Kidsbanners"
              element={<AdminPanel children={<Kidsbanners />} />}
            ></Route>
            <Route
              path="admin/Kidscategory"
              element={<AdminPanel children={<Kidscategory />} />}
            ></Route>

            <Route
              path="admin/Kidsaudio"
              element={<AdminPanel children={<Kidsaudio />} />}
            ></Route>

            <Route
              path="admin/Kidsvideo"
              element={<AdminPanel children={<Kidsvideo />} />}
            ></Route>
            <Route
              path="admin/Podcastcategory"
              element={<AdminPanel children={<Podcastcategory />} />}
            ></Route>
            <Route
              path="admin/Adpodcast"
              element={<AdminPanel children={<Adpodcast />} />}
            ></Route>
            <Route
              path="admin/Mostpopularvideo"
              element={<AdminPanel children={<Mostpopularvideo />} />}
            ></Route>
            <Route
              path="admin/Mostpopularaudio"
              element={<AdminPanel children={<Mostpopularaudio />} />}
            ></Route>
            <Route
              path="admin/Awaitedaudio"
              element={<AdminPanel children={<Awaitedaudio />} />}
            ></Route>
            <Route
              path="admin/Awaitedvideo"
              element={<AdminPanel children={<Awaitedvideo />} />}
            ></Route>
            <Route
              path="admin/Musictherapistbanners"
              element={<AdminPanel children={<Musictherapistbanners />} />}
            ></Route>
            <Route
              path="admin/Musictherapistcategory"
              element={<AdminPanel children={<Musictherapistcategory />} />}
            ></Route>
            <Route
              path="admin/Musictherapistmusic"
              element={<AdminPanel children={<Musictherapistmusic />} />}
            ></Route>
            <Route
              path="admin/Subscribecat"
              element={<AdminPanel children={<Subscribecat />} />}
            ></Route>
            <Route
              path="admin/Subscriberlist"
              element={<AdminPanel children={<Subscriberlist />} />}
            ></Route>
            <Route
              path="admin/Blogpage"
              element={<AdminPanel children={<Blogpage />} />}
            ></Route>
            <Route
              path="admin/UserBlog"
              element={<AdminPanel children={<UserBlog />} />}
            ></Route>
            <Route
              path="admin/AccountHistory"
              element={<AdminPanel children={<AccountHistory />} />}
            ></Route>
            <Route
              path="admin/Contactus"
              element={<AdminPanel children={<Contactus />} />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
