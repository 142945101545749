import Aos from "aos";
import React, { useEffect, useInsertionEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { IoMdArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "../Blog/Blog.css";
import axios from "axios";
import Slider from "react-slick";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Mobileapp from "../Home/mobileapp";
import { FaDownload } from "react-icons/fa";

const Blog = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  window.scroll(0, 0);

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [Blogs, setBlogs] = useState([]);
  const [Podcast, setPodcast] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getBlogs();
    getPodcastcategory();
  }, []);

  const getBlogs = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getBlogs")
      .then(function (response) {
        // console.log(response.data);
        setBlogs(response.data.BlogsList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPodcastcategory = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getPodcastcategory")
      .then(function (response) {
        console.log(response.data);
        setPodcast(
          response.data.PodcastcategoryList?.filter(
            (item) => item.Type === "Audio"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const currentAudioRef = useRef(null);

  const handlePlay = (audioElement) => {
    // Pause the currently playing audio if there is one
    if (currentAudioRef.current && currentAudioRef.current !== audioElement) {
      currentAudioRef.current.pause();
    }

    // Set the new audio element as the current one
    currentAudioRef.current = audioElement;
  };

  // console.log("Podcast", Podcast);

  return (
    <div>
      <div
        style={{
          backgroundImage: "url('../Img/65.jpg')",
          //   backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "350px",
          position: "relative",
          // backgroundAttachment: "fixed",
        }}
        className="wwhhh"
      >
        <div className="container kjhkhhh">
          <div className="row me-0">
            <div className="col-md-12  ojjjpoiiii text-center">
              <p>Blogs / Podcast</p>
              <span>
                <a href="/">Home /</a>
                <a href="">Blogs / Podcast</a>
              </span>
            </div>
          </div>
        </div>
        <div className="bs-slider"></div>
      </div>

      <div className="container my-4">
        <div className="row me-0">
          <div className="container mb-5 mt-5 ">
            <div className="mb-5">
              <p className="biuggbui">Blogs</p>
              <div className="iuyiuyf"></div>
              <div className="col-md-12 text-center mt-4">
                <p className="fs-5">
                  As an individual in this dramatically dynamic world, where
                  fads have taken over tradition and change is somehow the only
                  constant, one needs to evolve with the fast pace of change
                  that has somehow birthed fear, left us confused, and left us
                  in a constant feeling of anxiety with a perpetual lingering
                  thought of - what now?
                </p>
                <p className="fs-5">
                  Our series of blogs lay emphasis on the need to pause, silence
                  the chaos surrounding you and open your door to mindfulness
                  which has been knocking for a while. These series of blogs
                  will serve as reinforcement while you battle through your day
                  be that at work, at home, or even while parenting, and soon
                  you will realize that fear, confusion, and anxiety have taken
                  a back seat leaving you with everlasting peace.
                </p>
              </div>
            </div>
            <Slider {...settings}>
              {Blogs?.map((blog, index) => (
                <div
                  className="col-md-3"
                  data-aos="fade-up"
                  data-aos-duration="4000"
                  data-aos-easing="ease-in-out"
                  // key={index}
                >
                  <div className=" oiuoiu hhhhk_00000 kjbiugui1">
                    <Card style={{ width: "16rem", height: "480px" }}>
                      <Card.Img variant="top" />
                      <div className="guyyytrts landing-image-text-overlay">
                        <a href="" className="landing-image-text-overlay-link">
                          <img
                            src={
                              "https://relentlessyou.live/Blogs/" +
                              blog?.BlogsImage
                            }
                            alt=""
                            style={{ height: "250px", width: "100%" }}
                          />
                        </a>
                      </div>
                      <Card.Body className="text-center">
                        <Card.Title
                          style={{
                            fontSize: "1.2rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "clip",
                          }}
                        >
                          {blog?.BlogsHeader}
                        </Card.Title>
                        <Card.Text style={{ fontSize: "1.1rem" }}>
                          <p>
                            <strong>Posted on :</strong>{" "}
                            {moment(blog?.createdAt).format("MMM Do YY")}
                          </p>
                          <p className="truncate">{blog?.BlogsText}</p>
                        </Card.Text>
                        <button
                          variant="primary"
                          onClick={handleShow}
                          style={{
                            textDecoration: "none",
                            background: "none",
                            border: "none",
                            color: "#F57E41",
                            fontSize: "20px",
                            fontStyle: "bold",
                            cursor: "pointer",
                            bottom: "10px",
                            position: "fixed",
                          }}
                        >
                          Learn More <IoMdArrowForward />
                        </button>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="container mb-5 mt-5 ">
            <div className="mb-5">
              <p className="biuggbui">Podcast</p>
              <div className="iuyiuyf"></div>
              <div className="col-md-12 text-center mt-4">
                <p className="fs-5">
                  If you are someone who is looking to start your journey
                  towards mindfulness and are focusing on the present moment,
                  especially as part of a healing or meditative technique.
                  Listening to our podcast series is the best way to get
                  started. Our podcast series speaks to a wide variety of
                  audiences such as students, working moms, newly wedded
                  couples, parents, teachers, beginners and also children.
                </p>
                <p className="fs-5">
                  Our podcast series on mindfulness offers a brief reminder or
                  tips on how to be mindful throughout the day, no matter what
                  the situation may be. It also deep dives into tough subjects
                  however, teaches you to create a life of greater peace,
                  resilience and happiness.
                </p>
              </div>
            </div>
            <Slider {...settings}>
              {Podcast?.map((pod, index) => (
                <div
                  className="col-md-3"
                  style={{ margin: "10px", marginTop: "0px" }}
                >
                  <div style={{ position: "relative" }} className="iuwgywg">
                    <h5>{pod?.name}</h5>
                    <img
                      src={
                        "https://relentlessyou.live/Podcastcategory/" +
                        pod?.Podcastcategoryimg
                      }
                      alt=""
                      style={{ width: "95%", height: "300px" }}
                    />
                    <div className="jhhvytcfy">
                      <audio
                        src={
                          "https://relentlessyou.live/Podcast/" +
                          pod?.podcast[0]?.PodcastAudio
                        }
                        controls
                        type="audio/mpeg"
                        style={{ width: "265px" }}
                        onPlay={(e) => handlePlay(e.target)}
                      ></audio>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <button
              variant="primary"
              onClick={handleShow}
              style={{
                textDecoration: "none",
                background: "none",
                border: "none",
                color: "#F57E41",
                fontSize: "20px",
                fontStyle: "bold",
                cursor: "pointer",
                right: "10px",
                position: "absolute",
              }}
            >
              View All <IoMdArrowForward />
            </button>
            {/* <div className="row mt-4">
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/84.jpg"
                alt=""
                style={{ width: "100%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                  style={{width:"261px"}}
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/85.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/86.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ position: "relative" }} className="iuwgywg">
              <img
                src="../Img/87.jpg"
                alt=""
                style={{ width: "98%", height: "300px" }}
              />
              <div className="jhhvytcfy">
                <audio
                  src="../Img/yoga_1.mp3"
                  controls
                  type="audio/mpeg"
                ></audio>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <div>
      <div
        style={{
          backgroundImage: "url('../Img/56.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "450px",
          backgroundAttachment: "fixed",
          position: "relative",
        }}
        className="fcrtxrt"
      >
        <div
          className="container"
          style={{ position: "relative", zIndex: "9999" }}
        >
          <div className="row">
            <div className="col-6 bugufufu">
              <h1>DOWNLOAD OUR APP FOR FREE</h1>
              <p>
                Revitalize your life with our Yoga App – your pocket-sized guide
                to a healthier, happier you! Download now for a world of ease
                and tranquility. From easy-breezy poses for beginners to
                advanced stretches for pros, find your perfect flow. Let
                soothing music and calming voices accompany your journey, making
                every session a joy.
              </p>
             
            </div>
            <div className="col-md-6" style={{display:"flex", alignItems:"center"}}>
             
                <img
                  src="../Img/playstore.png"
                  alt=""
                  style={{ width: "40%" }}
                />
                <img
                  src="../Img/app stoor.png"
                  alt=""
                  style={{ width: "40%" }}
                />
             
            </div>
          </div>
        </div>
        <div className="bs-sliderr"></div>
      </div>
    </div>
        </Modal.Body>
      
      </Modal> */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#F58243" }}>
            Download the app Now!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="row"
              style={{ padding: "3rem", backgroundColor: "#F58243" }}
            >
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <img
                  src="/Img/Download.png"
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6" style={{ backgroundColor: "#F58243" }}>
                <h4 style={{ color: "white" }}>AFFORDABLE FITNESS PLANS</h4>
                <br />
                <h6 style={{ color: "white" }}>Your FITNESS is Our Concern.</h6>
                <br />
                <p style={{ color: "white" }}>
                  Download the Yoga App Today and Embrace a Healthier You!"
                </p>
              </div>
            </div>
            <div className="row" style={{ backgroundColor: "#F58243" }}>
              <div className="col-md-12 mb-3 text-center">
                <div className="hhhhk_000">
                  <button>
                    <a
                      href="https://play.google.com/store/games?device=windows&pli=1"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <span>
                        <FaDownload style={{ fontSize: "30px" }} />
                      </span>
                      Download Android | ios
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Blog;
