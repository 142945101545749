import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import moment from "moment/moment";

function Subadmindetails() {
  const [data, setdata] = useState([]);

  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [SecurityKey, setSecurityKey] = useState("");

  const [user, setuser] = useState(false);
  const [Book, setBook] = useState(false);
  const [video, setvideo] = useState(false);
  const [audio, setaudio] = useState(false);
  const [kids, setkids] = useState(false);
  const [Mpopular, setMpopular] = useState(false);
  const [Mawaited, setMawaited] = useState(false);
  const [podcast, setpodcast] = useState(false);
  const [musictherapy, setmusictherapy] = useState(false);
  const [snippets, setsnippets] = useState(false);
  const [blog, setblog] = useState(false);
  const [subscription, setsubscription] = useState(false);
  const [accounts, setaccounts] = useState(false);
  const [contactus, setcontactus] = useState(false);

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, one special character and must be 8 digit!"
      );
      return false;
    }
  }

  const [show, setShow] = useState(false);
  const [userdata, setuserdata] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setuserdata(data);
  };

  const [show1, setShow1] = useState(false);
  const [userdata1, setuserdata1] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setuserdata1(data);
  };

  const [show3, setShow3] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setdeletedata(data);
  };

  useEffect(() => {
    if (Object.keys(userdata1)?.length > 0) {
      setuser(userdata1?.user);
      setBook(userdata1?.Book);
      setvideo(userdata1?.video);
      setaudio(userdata1?.audio);
      setkids(userdata1?.kids);
      setMpopular(userdata1?.Mpopular);
      setMawaited(userdata1?.Mawaited);
      setpodcast(userdata1?.podcast);
      setmusictherapy(userdata1?.musictherapy);
      setsnippets(userdata1?.snippets);
      setblog(userdata1?.blog);
      setsubscription(userdata1?.subscription);
      setaccounts(userdata1?.accounts);
      setcontactus(userdata1?.contactus);
    }
  }, [userdata1]);

  useEffect(() => {
    getsubadmin();
  }, []);

  const getsubadmin = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getsubadmin")
      .then(function (response) {
        console.log(response.data.subadminList);
        setdata(response.data.subadminList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editsubadmin = async (e) => {
    if (
      name
        ? validatename(name)
        : true && email
        ? ValidateEmail(email)
        : true && password
        ? CheckPassword(password)
        : true
    ) {
      try {
        const config = {
          url: "/editsubadmin/" + userdata1?._id,
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            name: name ? name : userdata1?.name,
            email: email ? email : userdata1?.email,
            password: password ? password : userdata1?.password,
            user: user,
            Book: Book,
            video: video,
            audio: audio,
            kids: kids,
            Mpopular: Mpopular,
            Mawaited: Mawaited,
            podcast: podcast,
            musictherapy: musictherapy,
            snippets: snippets,
            blog: blog,
            subscription: subscription,
            accounts: accounts,
            contactus: contactus,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data.success);
          alert("Subadmin edited Successfully");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const deletesubadmin = async () => {
    try {
      const config = {
        url: "/deletesubadmin/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Data deleted successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span classText="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD-MM-YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "",
      text: "View",
      formatter: (value, row) => (
        <button
          onClick={() => handleShow(row)}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <i
            class="fa fa-eye"
            aria-hidden="true"
            style={{ color: "#f37261" }}
          ></i>
        </button>
      ),
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  console.log("userdata1", userdata1);

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Sub-admin Details
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>

              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          Access Details of&nbsp;<b>{userdata?.name}</b>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <Row className="mt-2">
            <Col md={4}>
              <div>
                <input type="checkbox" checked={userdata?.user} />
                &nbsp;
                <label>User</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.video} />
                &nbsp;
                <label>Video List</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.audio} />
                &nbsp;
                <label>Audio List</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.kids} />
                &nbsp;
                <label>Kids Section</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.contactus} />
                &nbsp;
                <label>Contact-Us</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input type="checkbox" checked={userdata?.Book} />
                &nbsp;
                <label>Books</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.Mpopular} />
                &nbsp;
                <label>Most Popular</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.Mawaited} />
                &nbsp;
                <label>Most Awaited</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.podcast} />
                &nbsp;
                <label>Podcast List</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.musictherapy} />
                &nbsp;
                <label>Music Therapy</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input type="checkbox" checked={userdata?.snippets} />
                &nbsp;
                <label>Snippets</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.blog} />
                &nbsp;
                <label>Blogs</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.subscription} />
                &nbsp;
                <label>Subscription</label>
              </div>
              <div>
                <input type="checkbox" checked={userdata?.accounts} />
                &nbsp;
                <label>Account History</label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>Edit-Subadmin</Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div
            style={{
              overflow: "hidden",
            }}
          >
            <Row>
              <Col md={6}>
                <label>Name</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={userdata1?.name}
                  onChange={(e) => setname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Email ID</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={userdata1?.email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Password</label>
              </Col>
              <Col md={6}>
                <div style={{ display: "flex" }}>
                  <input
                    type={SecurityKey ? "text" : "password"}
                    placeholder="******"
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  {SecurityKey ? (
                    <button
                      onClick={() => setSecurityKey(!SecurityKey)}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        marginLeft: "-27px",
                        paddingRight: "10px",
                      }}
                    >
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        style={{ color: "#f37261" }}
                      ></i>
                    </button>
                  ) : (
                    <button
                      onClick={() => setSecurityKey(!SecurityKey)}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        marginLeft: "-27px",
                        paddingRight: "10px",
                      }}
                    >
                      <i
                        class="fa fa-eye-slash"
                        aria-hidden="true"
                        style={{ color: "#f37261" }}
                      ></i>
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mt-5">
            <Col md={4}>
              <div>
                <input
                  type="checkbox"
                  checked={user}
                  onChange={() => setuser(!user)}
                />
                &nbsp;
                <label>User</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={video}
                  onChange={() => setvideo(!video)}
                />
                &nbsp;
                <label>Video List</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={audio}
                  onChange={() => setaudio(!audio)}
                />
                &nbsp;
                <label>Audio List</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={kids}
                  onChange={() => setkids(!kids)}
                />
                &nbsp;
                <label>Kids Section</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={contactus}
                  onChange={() => setcontactus(!contactus)}
                />
                &nbsp;
                <label>Contact-Us</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input
                  type="checkbox"
                  checked={Book}
                  onChange={() => setBook(!Book)}
                />
                &nbsp;
                <label>Books</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={Mpopular}
                  onChange={() => setMpopular(!Mpopular)}
                />
                &nbsp;
                <label>Most Popular</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={Mawaited}
                  onChange={() => setMawaited(!Mawaited)}
                />
                &nbsp;
                <label>Most Awaited</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={podcast}
                  onChange={() => setpodcast(!podcast)}
                />
                &nbsp;
                <label>Podcast List</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={musictherapy}
                  onChange={() => setmusictherapy(!musictherapy)}
                />
                &nbsp;
                <label>Music Therapy</label>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <input
                  type="checkbox"
                  checked={snippets}
                  onChange={() => setsnippets(!snippets)}
                />
                &nbsp;
                <label>Snippets</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={blog}
                  onChange={() => setblog(!blog)}
                />
                &nbsp;
                <label>Blogs</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={subscription}
                  onChange={() => setsubscription(!subscription)}
                />
                &nbsp;
                <label>Subscription</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={accounts}
                  onChange={() => setaccounts(!accounts)}
                />
                &nbsp;
                <label>Account History</label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editsubadmin}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={deletesubadmin}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Subadmindetails;
