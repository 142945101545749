import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";

function SubscribePlan() {
  const formdata = new FormData();
  const Input = styled("input")({
    display: "none",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    seteditdata(data);
  };

  const [show2, setShow2] = useState(false);
  const [selectedData, setselectedData] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setselectedData(data);
  };

  const [show3, setShow3] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (data) => {
    setShow3(true);
    setdeletedata(data);
  };

  const [data, setdata] = useState([]);
  const [subscriptioncategory, setsubscriptioncategory] = useState([]);

  const [Name, setName] = useState("");
  const [Type, setType] = useState("");
  const [Duration, setDuration] = useState("");
  const [Amount, setAmount] = useState();
  const [MostPopularAudio, setMostPopularAudio] = useState(false);
  const [MostPopularVideo, setMostPopularVideo] = useState(false);
  const [MostAwaitedAudio, setMostAwaitedAudio] = useState(false);
  const [MostAwaitedVideo, setMostAwaitedVideo] = useState(false);
  const [KidsSection, setKidsSection] = useState(false);
  const [MusicTherapy, setMusicTherapy] = useState(false);
  const [AudioVideo, setAudioVideo] = useState(false);
  const [Snippets, setSnippets] = useState(false);
  const [Blogs, setBlogs] = useState(false);
  const [Podcast, setPodcast] = useState(false);
  // const [expiredate, setexpiredate] = useState();

  // useEffect(() => {
  //   if (Duration === "One Year") {
  //     setexpiredate(moment().add(365, "days").calendar());
  //   } else if (Duration === "One Month") {
  //     setexpiredate(moment().add(30, "days").calendar());
  //   } else if (Duration === "Three Months") {
  //     setexpiredate(moment().add(90, "days").calendar());
  //   } else if (Duration === "Six Months") {
  //     setexpiredate(moment().add(180, "days").calendar());
  //   }
  // }, [Duration]);

  const [Name1, setName1] = useState("");
  const [Type1, setType1] = useState("");
  const [Duration1, setDuration1] = useState("");
  const [Amount1, setAmount1] = useState();
  const [MostPopularAudio1, setMostPopularAudio1] = useState();
  const [MostPopularVideo1, setMostPopularVideo1] = useState();
  const [MostAwaitedAudio1, setMostAwaitedAudio1] = useState();
  const [MostAwaitedVideo1, setMostAwaitedVideo1] = useState();
  const [KidsSection1, setKidsSection1] = useState();
  const [MusicTherapy1, setMusicTherapy1] = useState();
  const [AudioVideo1, setAudioVideo1] = useState();
  const [Snippets1, setSnippets1] = useState();
  const [Blogs1, setBlogs1] = useState();
  const [Podcast1, setPodcast1] = useState();
  // const [expiredate1, setexpiredate1] = useState();

  useEffect(() => {
    if (editdata) {
      setName1(editdata?.setName);
      setType1(editdata?.Type);
      setDuration1(editdata?.Duration);
      setAmount1(editdata?.Amount);
      setMostPopularAudio1(editdata?.MostPopularAudio);
      setMostPopularVideo1(editdata?.MostPopularVideo);
      setMostAwaitedAudio1(editdata?.MostAwaitedAudio);
      setMostAwaitedVideo1(editdata?.MostAwaitedVideo);
      setKidsSection1(editdata?.KidsSection);
      setMusicTherapy1(editdata?.MusicTherapy);
      setAudioVideo1(editdata?.AudioVideo);
      setSnippets1(editdata?.Snippets);
      setBlogs1(editdata?.Blogs);
      setPodcast1(editdata?.Podcast);
      // setexpiredate1(editdata?.expiredate);
    }
  }, [editdata]);

  // useEffect(() => {
  //   if (Duration1 === "One Year") {
  //     setexpiredate1(moment().add(365, "days").calendar());
  //   } else if (Duration1 === "One Month") {
  //     setexpiredate1(moment().add(30, "days").calendar());
  //   } else if (Duration1 === "Three Months") {
  //     setexpiredate1(moment().add(90, "days").calendar());
  //   } else if (Duration1 === "Six Months") {
  //     setexpiredate1(moment().add(180, "days").calendar());
  //   }
  // }, [Duration1]);

  useEffect(() => {
    getSubscribeplans();
    getSubscriptioncat();
  }, []);

  const getSubscriptioncat = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getSubscriptioncat")
      .then(function (response) {
        console.log(response.data);
        setsubscriptioncategory(response.data.SubscriptioncatList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addSubscribeplans = async () => {
    if (!Type || !Duration || !Amount) {
      alert("Please enter Type, Duration & Amount");
    } else {
      try {
        const config = {
          url: "/addSubscribeplans",
          method: "post",
          baseURL: "https://relentlessyou.live/api/admin",
          data: {
            Name: Name,
            Type: Type,
            Duration: Duration,
            // expiredate: expiredate,
            Amount: Amount,
            MostPopularAudio: MostPopularAudio,
            MostPopularVideo: MostPopularVideo,
            MostAwaitedAudio: MostAwaitedAudio,
            MostAwaitedVideo: MostAwaitedVideo,
            KidsSection: KidsSection,
            MusicTherapy: MusicTherapy,
            AudioVideo: AudioVideo,
            Snippets: Snippets,
            Blogs: Blogs,
            Podcast: Podcast,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            handleClose();
            alert("Data Added successfully");
            window.location.reload();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    }
  };

  const getSubscribeplans = () => {
    axios
      .get("https://relentlessyou.live/api/admin/getSubscribeplans")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.SubscribeplansList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editSubscribeplans = async () => {
    try {
      const config = {
        url: "/editSubscribeplans/" + editdata?._id,
        method: "put",
        baseURL: "https://relentlessyou.live/api/admin",
        data: {
          Name: Name1 ? Name1 : editdata?.Name1,
          Type: Type1 ? Type1 : editdata?.Type1,
          Duration: Duration1 ? Duration1 : editdata?.Duration1,
          // expiredate: expiredate1 ? expiredate1 : editdata?.expiredate,
          Amount: Amount1 ? Amount1 : editdata?.Amount,
          MostPopularAudio: MostPopularAudio1,
          MostPopularVideo: MostPopularVideo1,
          MostAwaitedAudio: MostAwaitedAudio1,
          MostAwaitedVideo: MostAwaitedVideo1,
          KidsSection: KidsSection1,
          MusicTherapy: MusicTherapy1,
          AudioVideo: AudioVideo1,
          Snippets: Snippets1,
          Blogs: Blogs1,
          Podcast: Podcast1,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          getSubscribeplans();
          handleClose1();
          alert("Data updated successfully");
          // window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      getSubscribeplans();
      alert(error.response.data.error);
    }
  };

  const deleteSubscribeplans = async () => {
    try {
      const config = {
        url: "/deleteSubscribeplans/" + deletedata?._id,
        method: "delete",
        baseURL: "https://relentlessyou.live/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          handleClose3();
          getSubscribeplans();
          alert("Data deleted successfully");
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details" + error);
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Name",
      text: "Name",
      formatter: (value, row) => <p>{row?.Name ? row?.Name : "-"}</p>,
    },
    {
      dataField: "Type",
      text: "Type",
      formatter: (value, row) => <p>{row?.Type ? row?.Type : "-"}</p>,
    },

    {
      dataField: "Duration",
      text: "Duration",
      formatter: (value, row) => <p>{row?.Duration ? row?.Duration : "-"}</p>,
    },
    {
      dataField: "Amount",
      text: "Amount",
      formatter: (value, row) => <p>{row?.Amount ? row?.Amount : "-"}</p>,
    },
    // {
    //   dataField: "createdAt",
    //   text: "From Date",
    //   formatter: (value, row) => (
    //     <p>
    //       {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"}
    //     </p>
    //   ),
    // },
    // {
    //   dataField: "expiredate",
    //   text: "expiredate",
    //   formatter: (value, row) => (
    //     <p>
    //       {row?.expiredate ? moment(row?.expiredate).format("DD/MM/YYYY") : "-"}
    //     </p>
    //   ),
    // },

    {
      dataField: "-",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <button
            onClick={() => handleShow2(row)}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "#f37261" }}
            ></i>
          </button>
          /
          <span className="editdelete">
            <FaRegEdit onClick={() => handleShow1(row)} color="white" />
          </span>
          /
          <span className="editdelete">
            <RiDeleteBin2Fill onClick={() => handleShow3(row)} color="white" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
        }}
      >
        Subscribe-Plan
      </div>
      <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          // rowClasses={rowClasses}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ExportCSVButton
                {...props.csvProps}
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Download Excel
              </ExportCSVButton>
              <button
                style={{
                  backgroundColor: "#f37261",
                  color: "white",
                  marginLeft: "10px",
                  border: "none",
                  padding: "6px",
                  borderRadius: "5px",
                  marginLeft: "467px",
                }}
                onClick={handleShow}
              >
                +Add
              </button>
              <hr></hr>
              <br></br>
              <div style={{ overflowX: "scroll", width: "100%" }}>
                <BootstrapTable
                  striped
                  hover
                  condensed
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Category <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setName(e.target.value)}
                >
                  <option>Select</option>
                  {subscriptioncategory?.map((item) => (
                    <option value={item?.name}>{item?.name}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Type <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>Select</option>
                  <option value="Premium">Premium</option>
                  <option value="Basic">Basic</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Duration <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option>Select</option>
                  <option value="One Year">One Year(365 Days)</option>
                  <option value="One Month">One Month(30 Days)</option>
                  <option value="Three Months">Three Months(90 Days)</option>
                  <option value="Six Months">Six Months(180 Days) </option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>
                  Amount <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  placeholder="Amount"
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
            </Row>

            <div className="mt-5">
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="a"
                    onChange={() => setMostPopularAudio(!MostPopularAudio)}
                  />
                  &nbsp;
                  <label for="a">Most-Popular-Audio</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="b"
                    onChange={() => setMostPopularVideo(!MostPopularVideo)}
                  />
                  &nbsp;
                  <label for="b">Most-Popular-Video</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="c"
                    onChange={() => setMostAwaitedAudio(!MostAwaitedAudio)}
                  />
                  &nbsp;
                  <label for="c">Most-Awaited-Audio</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="d"
                    onChange={() => setMostAwaitedVideo(!MostAwaitedVideo)}
                  />
                  &nbsp;
                  <label for="d">Most-Awaited-Video</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="e"
                    onChange={() => setKidsSection(!KidsSection)}
                  />
                  &nbsp;
                  <label for="e">Kids-Section</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="f"
                    onChange={() => setMusicTherapy(!MusicTherapy)}
                  />
                  &nbsp;
                  <label for="f">Music-Therapy</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="g"
                    onChange={() => setAudioVideo(!AudioVideo)}
                  />
                  &nbsp;
                  <label for="g">Audio/Video</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="h"
                    onChange={() => setSnippets(!Snippets)}
                  />
                  &nbsp;
                  <label for="h">Snippets</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="g"
                    onChange={() => setBlogs(!Blogs)}
                  />
                  &nbsp;
                  <label for="g">Blogs</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="i"
                    onChange={() => setPodcast(!Podcast)}
                  />
                  &nbsp;
                  <label for="i">Podcast</label>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addSubscribeplans}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit-Subscribe-Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row className="mt-3">
              <Col md={6}>
                <label>Category</label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setName1(e.target.value)}
                >
                  <option>{editdata?.Name}</option>
                  {subscriptioncategory
                    ?.filter((subcat) => subcat?.name !== editdata?.Name)
                    .map((item) => (
                      <option value={item?.name}>{item?.name}</option>
                    ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Type</label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setType1(e.target.value)}
                >
                  <option>{editdata?.Type}</option>
                  <option value="Premium">Premium</option>
                  <option value="Basic">Basic</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Duration</label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => setDuration1(e.target.value)}
                >
                  <option>{editdata?.Duration}</option>
                  <option value="One Year">One Year(365 Days)</option>
                  <option value="One Month">One Month(30 Days)</option>
                  <option value="Three Months">Three Months(90 Days)</option>
                  <option value="Six Months">Six Months(180 Days) </option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <label>Amount</label>
              </Col>
              <Col md={6}>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  placeholder={editdata?.Amount}
                  onChange={(e) => setAmount1(e.target.value)}
                />
              </Col>
            </Row>

            <div className="mt-5">
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="a"
                    checked={MostPopularAudio1}
                    onChange={() => setMostPopularAudio1(!MostPopularAudio1)}
                  />
                  &nbsp;
                  <label for="a">Most-Popular-Audio</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="b"
                    checked={MostPopularVideo1}
                    onChange={() => setMostPopularVideo1(!MostPopularVideo1)}
                  />
                  &nbsp;
                  <label for="b">Most-Popular-Video</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="c"
                    checked={MostAwaitedAudio1}
                    onChange={() => setMostAwaitedAudio1(!MostAwaitedAudio1)}
                  />
                  &nbsp;
                  <label for="c">Most-Awaited-Audio</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="d"
                    checked={MostAwaitedVideo1}
                    onChange={() => setMostAwaitedVideo1(!MostAwaitedVideo1)}
                  />
                  &nbsp;
                  <label for="d">Most-Awaited-Video</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="e"
                    checked={KidsSection1}
                    onChange={() => setKidsSection1(!KidsSection1)}
                  />
                  &nbsp;
                  <label for="e">Kids-Section</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="f"
                    checked={MusicTherapy1}
                    onChange={() => setMusicTherapy1(!MusicTherapy1)}
                  />
                  &nbsp;
                  <label for="f">Music-Therapy</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="g"
                    checked={AudioVideo1}
                    onChange={() => setAudioVideo1(!AudioVideo1)}
                  />
                  &nbsp;
                  <label for="g">Audio/Video</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="h"
                    checked={Snippets1}
                    onChange={() => setSnippets1(!Snippets1)}
                  />
                  &nbsp;
                  <label for="h">Snippets</label>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="g"
                    checked={Blogs1}
                    onChange={() => setBlogs1(!Blogs1)}
                  />
                  &nbsp;
                  <label for="g">Blogs</label>
                </Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    id="i"
                    checked={Podcast1}
                    onChange={() => setPodcast1(!Podcast1)}
                  />
                  &nbsp;
                  <label for="i">Podcast</label>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editSubscribeplans}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div style={{ margin: 10 }}>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="a"
                  checked={selectedData?.MostPopularAudio}
                />
                &nbsp;
                <label for="a">Most-Popular-Audio</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="b"
                  checked={selectedData?.MostPopularVideo}
                />
                &nbsp;
                <label for="b">Most-Popular-Video</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="c"
                  checked={selectedData?.MostAwaitedAudio}
                />
                &nbsp;
                <label for="c">Most-Awaited-Audio</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="d"
                  checked={selectedData?.MostAwaitedVideo}
                />
                &nbsp;
                <label for="d">Most-Awaited-Video</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="e"
                  checked={selectedData?.KidsSection}
                />
                &nbsp;
                <label for="e">Kids-Section</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="f"
                  checked={selectedData?.MusicTherapy}
                />
                &nbsp;
                <label for="f">Music-Therapy</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="g"
                  checked={selectedData?.AudioVideo}
                />
                &nbsp;
                <label for="g">Audio/Video</label>
              </Col>
              <Col md={6}>
                <input
                  type="checkbox"
                  id="h"
                  checked={selectedData?.Snippets}
                />
                &nbsp;
                <label for="h">Snippets</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input type="checkbox" id="g" checked={selectedData?.Blogs} />
                &nbsp;
                <label for="g">Blogs</label>
              </Col>
              <Col md={6}>
                <input type="checkbox" id="i" checked={selectedData?.Podcast} />
                &nbsp;
                <label for="i">Podcast</label>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>Are you sure you want to delete</Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteSubscribeplans}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SubscribePlan;
