import React from "react";
import { FaFacebookF, FaPhoneAlt } from "react-icons/fa";
import { IoLogoGoogleplus, IoLogoYoutube, IoMdMail } from "react-icons/io";
import "../Footer/Footer.css";
import { TiArrowMove } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";

const Footerr = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(0 0 0 / 87%)",
        padding: "15px 18px  15px 18px",
        color: "white",
        backgroundImage: "url('../Img/63.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "400px",
        backgroundAttachment: "fixed",
      }}
      className="fcrtxrt"
    >
      <div className="container">
        <div className="row py-3">
          <div className="col-md-3">
            <img
              src="../Img/horizontal-logo.png"
              alt=""
              style={{ width: "210px", height: "70px" }}
            />
            {/* <p style={{ fontSize: "18px", marginTop: "10px" }}>
              Suzy Queue 4455 Landing Lange, Louisville, KY 40018-1234
            </p> */}
            <p style={{ fontSize: "18px" }}>
              <span>
                <IoMdMail style={{ color: "#f37261" }} />{" "}
                info@relentless-you.com
              </span>
            </p>
            {/* <p style={{ fontSize: "18px" }}>
              <span>
                <FaPhoneAlt style={{ color: "#f37261" }} /> +91-0000000000
              </span>
            </p> */}
          </div>
          <div className="col-md-3 hguyg_0000">
            <p>QUICK LINK</p>
            <p>
              {" "}
              <span>
                <TiArrowMove />
              </span>
              <a href="/">Home</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/aboutus">About Us</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/blog">Blog</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/contactus">Contact Us</a>
            </p>
          </div>
          <div className="col-md-3 hguyg_0000">
            <p>Meditations</p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/maditation1">Mindfulness for Children</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/helloholistic">Hello Holistic Happiness</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/chakras">Balance your Chakras</a>
            </p>
            <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="/maditationsoul">Meditation for the Soul</a>
            </p>
            {/* <p>
              <span>
                <TiArrowMove />
              </span>
              <a href="">Music Therapy</a>
            </p> */}
          </div>
          <div className="col-md-3 hguyg_00000">
            <p>Follow Us On</p>
            <p>
              <span>
                <FaFacebookF
                  style={{
                    fontSize: "40px",
                    border: "1px solid white",
                    padding: "5px ",
                    borderRadius: "50%",
                  }}
                />
              </span>
              <span>
                <FaXTwitter
                  style={{
                    fontSize: "40px",
                    border: "1px solid white",
                    padding: "5px ",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                />
              </span>
              <span>
                <IoLogoYoutube
                  style={{
                    fontSize: "40px",
                    border: "1px solid white",
                    padding: "5px ",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                />
              </span>
              <span>
                <IoLogoGoogleplus
                  style={{
                    fontSize: "40px",
                    border: "1px solid white",
                    padding: "5px ",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                />
              </span>
            </p>
            <p className="fs-5 mt-3 text-start">
              Download Our App For <br /> Better Results
            </p>
            <p>
              <span className="d-flex">
                <img
                  src="../Img/playstore.png"
                  alt=""
                  style={{ width: "50%" }}
                  onClick={() =>
                    window.location.assign(
                      "https://play.google.com/store/games?device=windows&pli=1"
                    )
                  }
                />

                <img
                  src="../Img/app stoor.png"
                  alt=""
                  style={{ width: "50%" }}
                  onClick={() =>
                    window.location.assign(
                      "https://www.apple.com/in/app-store/"
                    )
                  }
                />
              </span>
            </p>
          </div>
        </div>
        <p
          style={{
            fontWeight: "bold",
            marginBottom: "0px",
            textAlign: "right",
          }}
        >
          This Application is Managed by{" "}
          <span style={{ color: "#f37261" }}>"Behzad Histas Randeria"</span>
        </p>
        <hr />
      </div>

      <div className="container">
        <div className="row align-item-center">
          <div className="col-md-6 text-start">
            <p>Copyright © 2024-25 - Relentlessyou pvt ltd</p>
          </div>
          <div className="col-md-6 text-end huhgguhu">
            <p>
              <a href="/terms-condition">Terms And Conditions </a>|{" "}
              <a href="/privacy-policy"> Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footerr;
