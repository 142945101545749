import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

const BlogViewMore = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="container projectviewmore"
        style={{ padding: "3rem", marginTop: "-2rem" }}
      >
        <div className="row">
          <div
            className="col-md-12 iuio"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <p style={{ color: "#f37261", textTransform: "capitalize" }}>
              Finding your place in the professional world
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 services-details-content p-3">
            <p
              style={{
                color: "#000",
                lineHeight: "1.7rem",
                textAlign: "justify",
              }}
              className="fs-5"
            >
              Let’s face it, a lot of the career advice out there is generic,
              false or otherwise unhelpful. Early on in school, we’re told to
              pick something we’re ‘passionate about’ or ‘good at’ – but how can
              an 18-year-old or even a 25-year-old have any clue if things they
              seem to like are going to fit into a good career?
            </p>
            <p
              style={{
                color: "#000",
                lineHeight: "1.7rem",
                textAlign: "justify",
              }}
              className="fs-5"
            >
              The truth is that they don’t, and the result is that there are
              tons of people out there who actively dislike their jobs. This
              problem is particularly acute for lawyers because a legal
              education can put people on a specific, predefined path. Hence the
              legal industry has become somewhat notorious for dissatisfaction.
            </p>
            <p style={{ textAlign: "justify" }} className="fs-5">
              <strong>Don’t fully believe us? Consider the following:</strong>
              <ul>
                <li>
                  A study by Johns Hopkins found that of 100 occupations
                  studied, lawyers were the most likely to suffer depression –
                  3.6 times more likely than the surveyed average.
                </li>
                <li>
                  Seven in ten lawyers responding to a lawyer’s magazine poll
                  said they would change careers if the opportunity arose.
                </li>
                <li>
                  An ABA Young Lawyers Division survey indicated that 41 per
                  cent of female attorneys were unhappy with their jobs.
                </li>
              </ul>
            </p>
          </div>
          <div className="col-md-5 m-auto">
            <img
              src="/Img/blog3.png"
              alt=""
              style={{ width: "100%", height: "400px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              This data may be based on the US, but things are no better in
              Australia, and there is little reason to believe that these trends
              will change. In light of these statistics, the premise of this
              article takes on an even brighter shade of significance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogViewMore;
